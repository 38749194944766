import React, {useEffect, useState} from 'react';
import {BasePage} from './BasePage';
import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  UnorderedList
} from '@chakra-ui/react';
import {DatePicker} from './DatePicker';
import {InputForm} from './InputForm';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {State, useHookstate} from '@hookstate/core';
import {Clinic, HType, Institution, Position, Shift, ShiftPays, ShiftTypeName} from '../types';
import {DayShifts} from './DayShifts';
import {
  calculatePayForPositionAndClass,
  getDayNameShort, getTotalSupplementalPay,
  onSelect, withCheckboxState
} from '../heka-utils';
import {WorkerClass} from '../worker-class';
import {HR} from './HR';
import {OptionalInputCheckbox} from './OptionalInputCheckbox';
import {useTranslation} from "react-i18next";
import {addDays, differenceInDays, format, isAfter, isBefore} from "date-fns";
import {GoButton} from "./GoButton";
import {
  createPosition,
  createPositionTemplate, dateTimeReviver,
  deletePosition,
  deletePositionTemplate,
  getInstitutionById,
  getPositionById,
  getPositionTemplates,
  PositionTemplate,
  updatePosition
} from "../net-utils";
import {validateNotZeroOrNegative} from "../validations";
import {DefaultSpinner} from "./DefaultSpinner";
import {BiTrash} from "react-icons/bi";
import {ModalBase} from "./ModalBase";
import AlertDisplay from "./AlertDisplay";
import { ChevronDownIcon, CloseIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {clone, cloneDeep, isEmpty, isEqual, omit, sortBy, uniq} from "lodash";
import {ResidencyPaymentSupplements} from "./CreatePositionPage/ResidencyPaymentSupplements";
import {QuestionMarkInfoIcon} from "./QuestionMarkInfoIcon";
import getSpecializations = WorkerClass.getSpecializations;
import {HTypeInformational} from "./HomePage";
import _ from 'lodash';

const international = require('sort-international');


function hasShiftType(shifts: Shift[], shiftType: ShiftTypeName) {
  return shifts.some(shift => {
    return shift.types.includes(shiftType)
  })
}

// const clinics = ['Höfn í Hornafirði', 'Hveragerði', 'Kirkjubæjarklaustur', 'Laugarás', 'Rangárþing', 'Selfoss', 'Þorlákshöfn',
// 'Vestmannaeyjar', 'Vík']

function getAutosavedPosition(clinicId: string | undefined, institution: Institution) {
  const existingPositionsStr = localStorage.getItem('positions')
  const existingPositions = existingPositionsStr ? JSON.parse(existingPositionsStr, dateTimeReviver) : {}
  const assumedClinicId = clinicId ?? institution.clinics[0]?.clinicId;
  if (assumedClinicId) {
    if (assumedClinicId in existingPositions) {
      let autosavedPosition = existingPositions[assumedClinicId] as Position
      if (autosavedPosition?.beginDate && isBefore(autosavedPosition.beginDate, new Date())) {
        autosavedPosition = {...autosavedPosition, beginDate: null, endDate: null, shifts: []}
      }
      return {...createBlankPosition(institution, clinicId), ...autosavedPosition};
    }
  }
  return null;
}

export function CreatePositionPage() {
  const params = useParams();
  const institutionId = params.institutionId as string | undefined;
  const location = useLocation();
  const clinicId = (location.state as {clinicId: string})?.clinicId as string | undefined;
  if (!institutionId) throw new Error('Missing institutionId');
  const [institution, setInstitution] = useState(null as Institution | null);
  useEffect(() => {
    (async () => {
      const institution = await getInstitutionById(institutionId);
      setInstitution(institution)
    })()
  }, [institutionId]);
  if (!institution) return <DefaultSpinner/>
  const autosavedPosition = getAutosavedPosition(clinicId, institution);
  const position = autosavedPosition ?? createBlankPosition(institution, clinicId);
  const submit = async (position: Position) => {
    removeAutosavedPosition(position.clinicId);
    const response = await createPosition(position)
    return await response.text()
  }
  return <PositionInputPage position={position} submit={submit} institution={institution}
    inputType={'Create'} autosaved={autosavedPosition !== null}/>
}

function removeAutosavedPosition(clinicId: string) {
  let autosavedPositions = JSON.parse(localStorage.getItem('positions') ?? '{}');
  if (clinicId in autosavedPositions) {
    delete autosavedPositions[clinicId]
    localStorage.setItem('positions', JSON.stringify(autosavedPositions))
  }
}

export function UpdatePositionPage() {
  const params = useParams();
  const positionId = params.positionId as string | undefined;
  if (!positionId) throw new Error('Missing positionId');
  const institutionId = params.institutionId as string | undefined;
  if (!institutionId) throw new Error('Missing institutionId');
  const [position, setPosition] = useState(null as Position | null);
  const [institution, setInstitution] = useState(null as Institution | null);
  useEffect(() => {
    (async () => {
      const position = await getPositionById(positionId);
      setPosition(position)
    })();
    (async () => {
      const institution = await getInstitutionById(institutionId);
      setInstitution(institution)
    })();
  }, [institutionId]);
  if (!position || !institution) return <DefaultSpinner/>
  const submit = async (position: Position) => {
    const response = await updatePosition(position)
    return await response.text()
  }
  return <PositionInputPage position={position} submit={submit} institution={institution}
    inputType={'Update'}/>
}

type InputType = 'Create' | 'Update';

export const ClinicInput = (props: {
  position: State<Position>,
  institution: Institution
}) => {
  const position = useHookstate(props.position);
  const institution = props.institution;
  const clinicNames = institution.clinics.map(clinic => clinic.name)
  const clinicIds = institution.clinics.map(clinic => clinic.clinicId)
  return <FormControl>
    <FormLabel>Starfsstöð</FormLabel>
    <Select onChange={onSelect(position.clinicId)} value={position.clinicId.get()}>
      {[...clinicNames].sort(international()).map((clinic, index) =>
        <option value={clinicIds[clinicNames.indexOf(clinic)]}>{clinic}</option>)}
    </Select>
  </FormControl>
}

const PositionInputPage = (props: {
  position: Position,
  institution: Institution,
  submit: (position: Position) => Promise<string>,
  inputType: InputType,
  autosaved?: boolean
}) => {
  const institutionState = useHookstate(props.institution)
  const institution = institutionState.get()
  const position = useHookstate(props.position)
  const {t} = useTranslation()
  const errorState = useHookstate<string | null>(null)
  const navigate = useNavigate();
  const templatesUpdateCounter = useHookstate(0)
  const incrementTemplatesUpdateCounter = () => templatesUpdateCounter.set(templatesUpdateCounter.get() + 1)
  const [showAutosavedMessage, setShowAutosavedMessage] = useState(true);
  useEffect(() => {
    if (showAutosavedMessage && !_.isEqual(position.get(), props.position)) {
      setShowAutosavedMessage(false);
    }
  }, [position]);
  if (!institution) {
    throw new Error("Institution not found")
  }

  const clinic = institution.clinics.find(clinic => clinic.clinicId === position.clinicId.get())

  if (!clinic) throw new Error('Clinic not found');

  const templateModalOpen = useHookstate(false);
  const shifts = position.shifts.get()
  const collectiveAgreementBased = position.get().isCollectiveAgreementBased;

  //const [beginDate, setBeginDate] = [position.beginDate.get(), position.beginDate.set];
  const beginDate = position.beginDate.get();
  const endDate = position.endDate.get();

  const publishPosition = async (isPublic: boolean) => {
    const errors = validatePosition(position.get())
    if (errors.length) {
      errorState.set(errors.join("\r\n"))
      return
    }
    const dynamicShiftTypes = institution.dynamicShiftTypes.filter(
      s => position.shifts.get().flatMap(s => s.types).includes(s.shiftName)
    );
    // filter out unused shift type pays
    const newShiftTypePays = Object.keys(position.shiftTypePays.get()).reduce((acc, shiftType) => {
      if (dynamicShiftTypes.map(s => s.shiftName).includes(shiftType)) {
        return {...acc, [shiftType]: position.shiftTypePays.get()[shiftType]};
      }
      return acc;
    }, {});
    const finalPosition = {...position.get(), dynamicShiftTypes, shiftTypePays: newShiftTypePays}
    const id = await props.submit({...finalPosition, isPublic})
    navigate(`/positions/${id}`)
  };

  useEffect(() => {
    if (props.inputType === 'Create') {
      const existingPositionsStr = localStorage.getItem("positions")
      const existingPositions = existingPositionsStr ? JSON.parse(existingPositionsStr) : {};
      const autosaveToLocalStorageInterval = setInterval(() => {
        const clinicId = position.clinicId.get();
        localStorage.setItem('positions', JSON.stringify({
          ...existingPositions,
          [clinicId]: position.get()
        }))
      }, 5000);
      return () => clearInterval(autosaveToLocalStorageInterval)
    }
  }, []);

  return <BasePage>
    <BorderBox>
      <div css={css`display: flex;
        flex-direction: column;
        gap: 10px`}>
        <Heading fontSize={'2xl'}>Auglýsa stöðu</Heading>
        {props.autosaved && showAutosavedMessage && <div css={css`display: flex; gap: 8px; flex-direction: column; gap: 8px;`}>
          <AlertDisplay error={'Húrra! Þú getur haldið áfram að vinna á þessari stöðu.'} status={'info'}/>
          <div>
            <Button size={'sm'} leftIcon={<CloseIcon/>} onClick={() => position.set(createBlankPosition(institution))}>Ég vil byrja upp á nýtt</Button>
          </div>
        </div>}
        <PositionTemplateSelect templateUpdateCounter={templatesUpdateCounter} institution={institution} position={position}/>
        <ClinicInput position={position} institution={institution}/>
        <ProfessionTypeSelect position={position}/>
        <FormControl>
          <FormLabel>Afleysingastaða</FormLabel>
          <Select onChange={onSelect(position.specialization)} value={position.specialization.get()}>
            {[...WorkerClass.datas[position.professionType.get()].specializations]
              .sort(international('icelandic'))
              .map(specialization => <option value={specialization.icelandic}>
              {specialization.icelandic}
            </option>)}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Hæfniskröfur</FormLabel>
          <div css={css`display: flex;
            flex-direction: column;`}>
            {WorkerClass.classes.map(workerClass =>
              <Checkbox isChecked={position.workerClassRequirements.get().includes(workerClass)}
                onChange={e => {
                  if (e.target.checked) {
                    position.workerClassRequirements.merge([workerClass])
                  } else {
                    position.workerClassRequirements.set(position.workerClassRequirements.get().filter(c => c !== workerClass))
                  }
                }}>
                {t(`${position.professionType.get()}.${workerClass}`)}
            </Checkbox>)}
          </div>
        </FormControl>
        <FormControl>
          <FormLabel>Verklýsing</FormLabel>
          <Textarea placeholder={'Helstu verkefni og ábyrgð'} value={position.description.get()}
            onChange={e => position.description.set(e.target.value)}/>
        </FormControl>
        <FormControl>
          <RadioGroup onChange={e => position.htype.set(e as HType)} value={position.htype.get()}
            css={css`display: flex; gap: 8px`}>
            <Radio value={'H1'}>H1</Radio>
            <Radio value={'H2'}>H2</Radio>
            <Radio value={'H3'}>H3+</Radio>
            <HTypeInformational/>
          </RadioGroup>
        </FormControl>
        <HR/>
        <TimePeriodAndPaymentSelector position={position} institutionState={institutionState} clinic={clinic}/>
        <Checkbox {...withCheckboxState(position.sendNotificationsToSubscribers)}>
          Senda tilkynningu á notendur með óskatímabil innan tímaramma um auglýsingu
        </Checkbox>
        <HR/>

        <PositionCheckboxes position={position}/>
        {errorState.get() && <Alert status={'error'} css={css`white-space: pre-line`}>
          <AlertIcon/>
          <Text>{errorState.get()}</Text>
        </Alert>}
        <div css={css`display: flex; gap: 4px; margin-top: 8px; flex-wrap: wrap;`}>
          <GoButton onClick={async () => await publishPosition(true)} submitError={errorState}>
            {props.inputType === 'Create' ? 'Birta auglýsingu' : 'Vista breytingar'}
          </GoButton>
          {!position.isPublic.get() && <GoButton variant={'outline'} submitError={errorState} onClick={async () => {
            await publishPosition(false);
          }}>Vista án þess að birta</GoButton>}
          <SaveTemplateButton templateModalOpen={templateModalOpen}/>
          {props.inputType === 'Update' && <Button colorScheme={'red'} leftIcon={<BiTrash/>} onClick={async () => {
            if (position.isAccepted) {
              window.alert("Ekki er hægt að eyða stöðu sem búið er að samþykkja")
              return;
            }
            if (window.confirm('Ertu viss um að þú viljir eyða þessari auglýsingu?')) {
              await deletePosition(props.position._id!!)
              navigate(`/institutions/${props.position.institutionId}/clinics/${props.position.clinicId}`)
            }
          }}>Eyða auglýsingu</Button>}
        </div>
      </div>
    </BorderBox>
    <CreatePositionTemplateModal update={incrementTemplatesUpdateCounter}
      open={templateModalOpen} institutionId={institution.institutionId!!} position={position.get()}/>
    {/*<ShiftCreationModal isOpen={shiftCreationModalOpen.get()} onClose={() => shiftCreationModalOpen.set(false)}/>*/}
  </BasePage>;
};

export function SaveTemplateButton(props: {
  templateModalOpen: State<boolean>,
}) {
  const templateModalOpen = useHookstate(props.templateModalOpen);
  return <div><Button onClick={() => templateModalOpen.set(true)}>Vista sem flýtisnið</Button></div>
}

export function TimePeriodAndPaymentSelector(props: {
  position: State<Position>,
  institutionState: State<Institution>,
  clinic: Clinic,
}) {
  const position = useHookstate(props.position);
  const institutionState = useHookstate(props.institutionState);
  const institution = institutionState.get();
  const clinic = props.clinic;
  const beginDate = position.beginDate.get();
  const endDate = position.endDate.get();
  const collectiveAgreementBased = position.isCollectiveAgreementBased.get();
  const shifts = position.shifts.get();
  return <>
    <PositionDateRangePicker position={position}/>
    {beginDate && endDate && <>
        <FormLabel>Vaktir fyrir tímabil</FormLabel>
        <DayShifts institutionState={institutionState} clinic={clinic} position={position}/></>}
    {position.get().professionType !== 'Doctor' && <FormControl>
        <FormLabel css={css`display: flex; align-items: center; gap: 4px`}>
            Launategund
            <QuestionMarkInfoIcon
                text={'Tímabundin ráðning er greidd eftir kjarasamningum og því er ekki stimplað inn greiðslur fyrir hverja vakt.'}/></FormLabel>
        <RadioGroup css={css`display: flex; gap: 8px`} onChange={e => {
          position.isCollectiveAgreementBased.set(e === 'collectiveAgreement')
        }} value={collectiveAgreementBased ? 'collectiveAgreement' : 'contractorWork'}>
            <Radio value='collectiveAgreement'>Tímabundin ráðning</Radio>
            <Radio value='contractorWork'>Verktakavinna</Radio>
        </RadioGroup>
    </FormControl>}
    {!!shifts.flatMap(s => s.types).length && beginDate && endDate && !collectiveAgreementBased && <Heading fontSize={'md'}>Greiðslur á hverja vakt</Heading>}
    {!collectiveAgreementBased && <PositionPayments position={position} institution={institution}/>}

    {!collectiveAgreementBased && !isEmpty(position.shiftTypePays.get()) &&
        <>
          <ResidencyPaymentSupplements position={position}/>
          <PreviewCalculatedPayments position={position.get()}/>
        </>
    }

    {collectiveAgreementBased && <Text>Laun eru greidd samkvæmt launaflokki</Text>}
  </>
}

export const ProfessionTypeSelect = (props: {
  position: State<Position>
}) => {
  const position = useHookstate(props.position);
  return  <FormControl>
    <FormLabel>Heilbrigðisstétt</FormLabel>
    <Select onChange={(event) => {
      const professionType = event.target.value as WorkerClass.ProfessionType;
      if (professionType !== position.professionType.get()) {
        position.specialization.set(
          getSpecializations(position.professionType.get())[0].icelandic
        )
        if (professionType === 'Doctor' && position.isCollectiveAgreementBased.get()) {
          // Doctors are always contract based
          position.isCollectiveAgreementBased.set(false);
        }
        if (professionType !== 'Doctor' && isEmpty(position.shiftTypePays.get())) {
          position.isCollectiveAgreementBased.set(true);
        }
        position.professionType.set(professionType)
      }
    }} value={position.professionType.get()}>
      <option value={'Doctor'}>Læknir</option>
      <option value={'Nurse'}>Hjúkrunarfræðingur</option>
      <option value={'Midwife'}>Ljósmóðir</option>
    </Select>
  </FormControl>
}

export const PositionDateRangePicker = (props: {
  position: State<Position>,
}) => {
  const position = useHookstate(props.position);
  const beginDate = position.beginDate.get();
  const endDate = position.endDate.get();
  const setBeginDate = (newBeginDate: Date | null) => {
    const endDate = position.endDate.get();
    if (endDate && newBeginDate && isAfter(newBeginDate, endDate)) {
      // If the begin date is after the end date, clear the end date
      setEndDate(null);
    }
    if (!endDate && !newBeginDate) {
      position.shifts.set([]);
    }
    position.beginDate.set(newBeginDate)
  }
  const setEndDate = (newEndDate: Date | null) => {
    const beginDate = position.beginDate.get();
    // we do not implement the same logic as setBeginDate since UI does not allow to set end date before begin date
    if (!beginDate && !newEndDate) {
      position.shifts.set([]);
    }
    position.endDate.set(newEndDate)
  }
  return <FormControl>
    <FormLabel>Tímabil</FormLabel>
    <div css={css`display: flex;
            flex-direction: column;
            gap: 6px`}>
      <div css={css`display: flex;
              gap: 4px;
              align-items: center`}>
        <DatePicker placeholderText={'Byrjun'} onChange={setBeginDate} selected={beginDate} minDate={new Date()}/>
        <DatePicker placeholderText={'Lok'} onChange={setEndDate} selected={endDate} minDate={beginDate ?? new Date()}/>
      </div>
    </div>
  </FormControl>
}

export const PositionCheckboxes = (props: {
  position: State<Position>
}) => {
  const position = useHookstate(props.position);
  return <>
    <Checkbox {...withCheckboxState(position.travelCostPaid)}>
      Ferðakostnaður greiddur
    </Checkbox>
    <OptionalInputCheckbox label={'Ferðadagur greiddur'} open={position.travelDayCostPaid}
      onChange={e => {
        const checked = e.target.checked;
        if (!checked) {
          position.travelDayCost.set(0)
        }
      }}>
      <InputForm label={'Ferðadagur'} prefix={'kr.'} type={'number'}
        state={position.travelDayCost}/>
    </OptionalInputCheckbox>
    <Checkbox {...withCheckboxState(position.accommodationCostPaid)}>
      Húsnæðisleiga rukkuð
    </Checkbox>
    <FormControl>
      <Checkbox {...withCheckboxState(position.insurancePaid)}>Vinnuveitandi greiðir tryggingar</Checkbox>
    </FormControl>
  </>
}

function getSelectedWorkerClasses(position: Position) {
  const firstWorkerClassPays = Object.values(position.shiftTypePays)[0] ?? {};
  return Object.keys(
    firstWorkerClassPays
  ) as WorkerClass.ClassType[];
}

export function PreviewCalculatedPayments(props: {
  position: Position
}) {
  const {t} = useTranslation();
  const supplementalPay = props.position.residencySupplementPay;
  const workerClasses = getSelectedWorkerClasses(props.position);
  return <div css={css`display: flex; flex-direction: column; gap: 8px`}>
    <Heading size={'sm'}>
      {t('Samtalslaun')}
    </Heading>
    <div css={css`display: flex;
      flex-direction: column`}>
      {
        workerClasses.map(workerClass => {
          const pay = calculatePayForPositionAndClass(props.position, workerClass);
          return <div key={workerClass} css={css`display: flex;
            align-items: center;
            gap: 4px`}>
            <div css={css`font-weight: bold`}>{t(`${props.position.professionType}.${workerClass}`)}</div>
            <div>{pay.toLocaleString('de-DE')} kr.</div>
            {
              supplementalPay && workerClass === 'Standard' && <div>
                ({(pay + getTotalSupplementalPay(props.position)).toLocaleString('de-DE')} kr. fyrir lengra komna í sérnámi)
              </div>
            }
          </div>
        })
      }
    </div>
  </div>
}

export function PositionTemplateSelect(props: {
  templateUpdateCounter: State<number>,
  institution: Institution,
  position: State<Position>
}) {
  const position = useHookstate(props.position);
  const institution = props.institution;
  const templatesUpdateCounter = props.templateUpdateCounter;
  return <div><PositionTemplateList updateCount={templatesUpdateCounter.get()} institution={institution}
    onSelect={template => {
      // If the template is in the past, set the beginDate to today and the endDate to the same number of days
      const {beginDate: templateBeginDate, endDate: templateEndDate} = template;
      const newBeginDate = templateBeginDate && isBefore(templateBeginDate, new Date())
        ? new Date()
        : templateBeginDate;
      const newEndDate = newBeginDate
        ? addDays(newBeginDate, templateEndDate ? differenceInDays(templateEndDate, templateBeginDate!!) : 7)
        : templateEndDate;
      // Template may have shift types that have since been deleted, so we need to filter them out
      const institutionShiftTypes = institution.dynamicShiftTypes
        .filter(t => t.professionType === position.professionType.get())
        .map(t => t.shiftName)
      const newShifts = template.shifts
        .map(s => ({...s, types: s.types.filter(t => institutionShiftTypes.includes(t))}));
      const newShiftTypePays = Object.keys(template.shiftTypePays).reduce((acc, shiftType) => {
        if (institutionShiftTypes.includes(shiftType)) {
          return {...acc, [shiftType]: template.shiftTypePays[shiftType]};
        }
        return acc;
      }, {} as ShiftPays);
      position.set({
        ...template,
        _id: undefined,
        beginDate: newBeginDate,
        endDate: newEndDate,
        shifts: newShifts,
        shiftTypePays: newShiftTypePays
      });
    }}/></div>
}

function PositionTemplateList(props: { institution: Institution, onSelect: (template: Position) => void, updateCount: number }) {
  const {institution, onSelect, updateCount} = props
  const [templates, setTemplates] = useState(null as PositionTemplate[] | null);
  const refresh = async () => {
    const templates = await getPositionTemplates(institution.institutionId!!);
    setTemplates(templates)
  };
  useEffect(() => {
    void refresh();
  }, [institution.institutionId, updateCount]);
  if (!templates || !templates.length) {
    return null
  }
  return <Menu>
    <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>Flýtisnið</MenuButton>
    <MenuList>
      {templates.map(template => <MenuItem key={template._id} onClick={() => onSelect(template.position)}>
        <div css={css`display: flex; align-items: center; width: 100%`}>
          <div>{template.name}</div>
          <SmallCloseIcon css={css`display: block; margin-left: auto`} onClick={async e => {
            e.stopPropagation()
            if (window.confirm("Ertu viss um að þú viljir eyða þessu flýtisniði?")) {
              await deletePositionTemplate(template._id!!, institution.institutionId!!)
              await refresh();
            }
          }}/>
        </div>
      </MenuItem>)}
    </MenuList>
  </Menu>
}

export function CreatePositionTemplateModal(props: {
  open: State<boolean>,
  institutionId: string,
  position: Position,
  update: () => void
}) {
  const {open, institutionId, position, update} = props
  const name = useHookstate('')
  const submitError = useHookstate(null as string | null)
  const submit = async () => {
    await createPositionTemplate(name.get(), position, institutionId)
    open.set(false)
    update()
  }
  return <ModalBase open={open} title={'Stofna flýtisnið'}>
    <InputForm label={'Nafn flýtisniðs'} state={name}/>
    <AlertDisplay error={submitError}/>
    <GoButton onClick={submit} submitError={submitError}>Stofna flýtisnið</GoButton>
  </ModalBase>
}

export function createBlankPosition(institution: Institution, clinicId?: string): Position {
  return {
    description: '',
    institutionId: institution.institutionId ?? (() => {throw new Error('Institution has no id')})(),
    clinicId: clinicId ?? institution.clinics[0]?.clinicId ?? (() => {throw new Error('Institution has no clinics')})(),
    professionType: 'Doctor',
    specialization: 'Almennar lyflækningar',
    workerClassRequirements: [
      'Student', 'Standard', 'Specialist'
    ],
    created: new Date(),
    shifts: [],
    shiftTypePays: {},
    travelCostPaid: false,
    travelCost: 0,
    travelDayCostPaid: false,
    travelDayCost: 0,
    accommodationCostPaid: false,
    accommodationCost: 0,
    insurancePaid: false,
    isPublic: false,
    sendNotificationsToSubscribers: true,
    htype: 'H1',
    isAccepted: false,
    cancelled: false,
    hasContract: false,
    dynamicShiftTypes: [],
    beginDate: null,
    endDate: null,
  }
}

export function validatePosition(position: Position): string[] {
  const errors: string[] = [];
  if (position.workerClassRequirements.length === 0) errors.push('Veldu að minnsta kosti eina hæfniskröfu');
  if (position.shifts.length === 0) errors.push('Starfstímabil má ekki vera tómt');
  let shiftWithMissingTypes = position.shifts.filter(s => s.types.length === 0);
  if (shiftWithMissingTypes.length) {
    errors.push(`Veldu að minnsta kosti eina vaktategund fyrir ${shiftWithMissingTypes.map(s => `${getDayNameShort(s.date)} - ${format(s.date, "dd/MM")}`).join(', ')}`);
  }

  const shiftSet = Array.from(new Set(position.shifts.flatMap(s => s.types)));
  if (!position.isCollectiveAgreementBased) {
    const missingPays = shiftSet.filter(s => position.shiftTypePays[s] === undefined).map(s => `Greiðsla á hverja ${s.toLowerCase()} vantar`);
    errors.push(...missingPays);
  }

  if (position.travelDayCostPaid && !position.travelDayCost) {
    errors.push('Greiðsla fyrir ferðadag er merkt en greiðsla er ekki gefin');
  }
  return errors;
}

export function PositionPayments(props: {
  position: State<Position>,
  institution: Institution
}) {
  const position = props.position.get();
  const shiftTypePaysState = useHookstate(props.position.shiftTypePays)
  const shifts = position.shifts;
  const {t} = useTranslation();

  const selectedWorkerClasses = getSelectedWorkerClasses(position);

  useEffect(() => {
    // when the shifts change, make sure that the shiftTypePays object is synced with the shifts
    const allSelectedShiftTypes = uniq(position.shifts.flatMap(shift => shift.types))
    const shiftTypePays = position.shiftTypePays;

    if (allSelectedShiftTypes.length === 0) {
      // keep the pays so user doesn't have to re-enter them
      return;
    }

    for (const shiftType of allSelectedShiftTypes) {
      if (!(shiftType in shiftTypePays)) {
        shiftTypePaysState[shiftType].set({"Standard": 0});
      }
    }
  }, [props.position.shifts]);
  const isSelectedWorkerClass = (workerClass: WorkerClass.ClassType) => selectedWorkerClasses.includes(workerClass);

  const onSelectedWorkerClassChange = (workerClass: WorkerClass.ClassType, selected: boolean) => {
    const shiftTypePayKeys = Object.keys(position.shiftTypePays) as ShiftTypeName[];

    shiftTypePayKeys.forEach((shiftType) => {
      const workerClassPays = shiftTypePaysState[shiftType].ornull;

      if (workerClassPays) {
        const oldState = workerClassPays.get();
        const updatedWorkerClassPays = selected
          ? { ...oldState, [workerClass]: 0 }
          : omit(oldState, workerClass);

        shiftTypePaysState[shiftType].set(updatedWorkerClassPays);
      }
    });
  };
  if (!shifts.flatMap(s => s.types).length) return <></>;

  const workerClassOrder = ['Standard', 'Student', 'Specialist'] as WorkerClass.ClassType[];
  const shiftTypes = props.institution.dynamicShiftTypes.map(s => s.shiftName)
      .filter(type => position.shifts.flatMap(it => it.types).includes(type));
  return <div>
    <Heading fontSize={'sm'}>Sérlaun fyrir hæfniskröfur</Heading>
    <div css={css`display: flex; gap: 8px; margin: 4px`}>
      {workerClassOrder.map(workerClass => (
        <Checkbox key={workerClass}
          onChange={e => onSelectedWorkerClassChange(workerClass, e.target.checked)}
          isChecked={isSelectedWorkerClass(workerClass)}
          isDisabled={selectedWorkerClasses.length === 1 && isSelectedWorkerClass(workerClass)}
        >
          {t(`${position.professionType}.${workerClass}`)}
        </Checkbox>))}
    </div>
    {(shiftTypes as ShiftTypeName[]).filter(s => hasShiftType(shifts, s))
      .map((shiftType: ShiftTypeName) => <div key={shiftType} css={css`display: flex; flex-direction: column; gap: 8px`}><div>
        <Heading fontSize={'md'} css={css`margin-top: 8px`}>{t(shiftType)}</Heading>
        {(
          <div css={css`display: flex; gap: 8px`}>
            {
              sortBy(selectedWorkerClasses, e => workerClassOrder.indexOf(e))
                .map((workerClass: WorkerClass.ClassType) => {
                const pays = shiftTypePaysState[shiftType].ornull;
                if (!pays) return null;
                const pay = pays[workerClass].ornull;
                if (!pay) return null;
                return <InputForm label={`${t(`${position.professionType}.${workerClass}`)}`} prefix={'kr.'} type={'number'}
                  state={pay} validate={validateNotZeroOrNegative}/>
              })
            }
          </div>
        )}
      </div></div>)}
  </div>
}