import React from 'react';
import {FormControl, FormErrorMessage, FormHelperText, FormLabel} from "@chakra-ui/react";
import {css} from "@emotion/react";
import FilePicker from "./lib/FilePicker";
import {State, useHookstate} from "@hookstate/core";
import {FileAuthCredentials, uploadFile} from "../net-utils";
import {getUserJwt} from "../heka-utils";

export type FileUploadType = "image" | "pdf" | "image and pdf"

const FileUploadButton = (props: {
  type: FileUploadType,
  label: string,
  state: State<string | undefined>,
  credentials?: FileAuthCredentials,
  subtext?: string,
  isRequired?: boolean
}) => {
  const link = useHookstate(props.state)
  const isLoading = useHookstate(false)
  const errorMessage = useHookstate<string | undefined>(undefined)

  const credentials: FileAuthCredentials = props.credentials ?? (() => {
    const userJwt = getUserJwt();
    if (!userJwt) throw new Error("User not logged in");
    return {header: 'heka-user-jwt', value: userJwt}
  })()
  return (
    <FormControl isRequired={props.isRequired} css={css`display: flex;
      flex-direction: column`} isInvalid={errorMessage.get() !== undefined}>
      <FormLabel>{props.label}</FormLabel>
      <FilePicker
        onFileChange={(fileList) => {
          (async () => {
            try {
              errorMessage.set(undefined)
              isLoading.set(true)
              const response = await uploadFile(fileList[0], credentials)
              const url = await response.text()
              link.set(url)
            } catch (e) {
              if (e instanceof Error) {
                errorMessage.set(e.message)
              } else errorMessage.set("Óþekkt villa")
            } finally {
              isLoading.set(false)
            }
          })()
        }}
        placeholder={props.label}
        clearButtonLabel="Eyða"
        multipleFiles={false}
        accept={
          props.type === "image" ? "image/*" : props.type === "pdf" ? "application/pdf" : "application/pdf,image/*"
        }
        isLoading={isLoading.get()}
      />
      {props.subtext && <FormHelperText>{props.subtext}</FormHelperText>}
      {errorMessage.get() && <FormErrorMessage>{errorMessage.get()}</FormErrorMessage>}
    </FormControl>
  );
};

export default FileUploadButton;
