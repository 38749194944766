import {BorderBox} from "./BorderBox";
import {css} from "@emotion/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel, Alert, AlertIcon,
  Avatar,
  Badge,
  Button,
  Divider,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  ProgressLabel,
  Text,
  Textarea, Tooltip
} from '@chakra-ui/react';
import {
  AtSignIcon,
  AttachmentIcon,
  CalendarIcon,
  CloseIcon,
  EditIcon,
  PhoneIcon,
} from '@chakra-ui/icons';
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {BasePage} from './BasePage';
import {GiHealthNormal} from "react-icons/gi";
import {RiBillLine} from "react-icons/ri";
import {State, useHookstate} from '@hookstate/core';
import {Experiences, Institution, Position, PreviousEmployment, User, UserResponse} from "../types";
import {differenceInDays, differenceInYears} from "date-fns";
import {useTranslation} from "react-i18next";
import {
  formatDate,
  formatPhone,
  getUsername,
  getUserProfessionsFormatted,
  phoneToString,
  stringToColor
} from "../heka-utils";
import {
  cancelPosition,
  getInstitutionById,
  getPositionById,
  getPositionsForUser,
  getUser,
  verifyUser
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import {FaExclamation, FaFileInvoice} from "react-icons/fa";
import {LabelInfo} from "./LabelInfo";
import {globalUser} from "../App";

function PreviousBookings(props: { username: string }) {
  const [positions, setPositions] = useState(null as Position[] | null);
  const {username} = props;
  const refresh = async () => {
    const positions = await getPositionsForUser(props.username);
    setPositions(positions);
  };
  useEffect(() => {
    refresh().then();
  }, [username]);
  if (!positions) {
    return <DefaultSpinner/>;
  }
  const elements = positions.map((positionEmployment, index) => {
    return <PreviousBooking key={index}
      position={positionEmployment}
      username={username}
      refresh={refresh}
    />;
  });
  return <BorderBox>
    <Heading css={css`margin-bottom: 12px`}>Fyrri bókanir</Heading>
    <div>
      {
        elements.length ? elements : <Text>Engar bókanir</Text>
      }
    </div>
  </BorderBox>;
}

export function UserPage() {
  const username = useParams<string>().username;
  if (!username) throw new Error('Missing username');
  const userState = useHookstate<UserResponse | null>(null);
  const user = userState.get()?.user;
  const ourUser = useHookstate(globalUser).get()?.user;
  useEffect(() => {
    (async () => {
      userState.set(await getUser(username))
    })()
  }, [username]);
  const {t} = useTranslation();
  if (!user) {
    return <DefaultSpinner/>
  }
  const preferences = user.preferences;
  return <BasePage css={css`display: flex;
    flex-direction: column;
    gap: 4px`}>
    {user.certificationId.length === 0 &&
      <Alert status="warning">
        <AlertIcon/>
        <Text>
          Þessi notandi er ekki með starfsleyfi
        </Text>
      </Alert>
    }
    {
      ourUser?.isAdmin && user.unverified && <BorderBox>
        <Heading css={css`margin-bottom: 8px`} size={'sm'}>Þessi aðgangur er ekki virkur</Heading>
        <Button variant={'outline'} onClick={async () => {
          await verifyUser(username);
          window.location.reload();
        }}>Virkja aðgang</Button>
      </BorderBox>
    }
    <div css={css`display: flex;
      gap: 8px; flex-wrap: wrap`}>
      <BorderBox css={css`padding-top: 50px;
        padding-bottom: 50px;
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px`}>
        <Avatar name={user.electronicId.name} src={preferences.links.picture} size={'2xl'} css={css`width: 12rem; height: 12rem`}/>
        <Heading size={'md'} css={css`text-align: center`}>{user.electronicId.name}</Heading>
        <Text css={css`color: #818181;
          text-align: center`}>{getUserProfessionsFormatted(user, t)}</Text>
        <Text css={css`color: #818181;
          text-align: center`}>{preferences.professions.flatMap(p => p.specializations).join(", ")}</Text>
      </BorderBox>
      <div css={css`flex: 2;
        display: flex;
        flex-direction: column;
        gap: 8px`}>
        {/* Info */}
        <BorderBox>
          {preferences.professions.map(p =>
              <IconLabel key={p.type} icon={<Icon as={GiHealthNormal} color={'gray.600'}/>}>{t(p.type)}</IconLabel>
          )}
          {preferences.phoneNumbers.map(p => {
            const phone = phoneToString(p);
            return <IconLabel icon={<PhoneIcon color={'gray.600'}/>} key={p.phoneNumber}>
                <a href={`tel:${phone}`}>{phone}</a></IconLabel>;
            }
          )}
          <IconLabel icon={<AtSignIcon color={'gray.600'}/>}>
            <a href={`mailto:${preferences.email}`}>{preferences.email}</a>
          </IconLabel>
          {user.preferences.links.enrollmentVerification && <IconLabel icon={<AttachmentIcon color={'gray.600'}/>}>
              Staðfesting að skólavist
          </IconLabel>}
        </BorderBox>
        {/* Menu */}
        {(preferences.links.cv || getUsername() === username) && <BorderBox css={css`margin-bottom: 8px;`}>
          <div css={css`display: flex;
            flex-direction: column;
            gap: 4px;
            flex-wrap: wrap`}>
            {username === getUsername() && <Tooltip label={'Tímabil umsókna'}><Link to={'/my-calendar'}>
                <IconLabel icon={<CalendarIcon/>}>Dagatal</IconLabel>
            </Link></Tooltip>}
            <Divider/>
            {preferences.links.permit && <><a href={preferences.links.permit}>
                <IconLabel icon={<AttachmentIcon/>}>Starfsleyfi</IconLabel>
            </a>
                <Divider/>
            </>}
            {preferences.links.cv &&
                <><a href={preferences.links.cv}>
                    <IconLabel icon={<AttachmentIcon/>}>Ferilskrá</IconLabel>
                </a>
                    <Divider/>
                </>}
            {username === getUsername() && <>
                <Link to={`/my-contracts`}>
                    <IconLabel icon={<Icon as={RiBillLine}/>}>Samningar</IconLabel>
                </Link>
                <Divider/>
                <Link to={`/my-invoices`}>
                    <IconLabel icon={<Icon as={RiBillLine}/>}>Rafrænir reikningar</IconLabel>
                </Link>
                <Divider/>
                <Link to={`/my-applications`}>
                    <IconLabel icon={<Icon as={RiBillLine}/>}>Umsóknir</IconLabel>
                </Link>
                <Divider/>
                <Link to={`/users/${username}/edit`}><IconLabel icon={<EditIcon/>}>Breyta prófíl</IconLabel></Link>
                <Divider/>
                <Link to={`/users/${username}/bank/edit`}><IconLabel icon={<EditIcon/>}>Breyta
                    reikningsupplýsingum</IconLabel></Link>
            </>}
          </div>
        </BorderBox>}
      </div>
      <div css={css`display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 2`}>
        {/*<WorkHistory previousEmployments={preferences.previousEmployments}/>*/}
        <WorkExperiences experiences={preferences.experiences}/>
        <BorderBox css={css`display: flex; flex-direction: column; gap: 8px`}>
          <LabelInfo label={'Fjöldi afleysinga:'} value={user.positionCount ?? 0}/>
          <LabelInfo label={'Fjöldi hætt við:'} value={user.positionCancelledCount ?? 0}/>
          {user.preferences.professions.filter(p => p.isInResidency && p.residencyDate)
            .map(p => <>
              <LabelInfo label={`Sérnám:`}>
                {t(p.type)}
              </LabelInfo>
              <LabelInfo label={`Sérnám byrjað:`}>
                {formatDate(p.residencyDate!!)} ({differenceInYears(new Date(), p.residencyDate!!)} ár)
              </LabelInfo>
            </>)}
        </BorderBox>
      </div>
    </div>
    <PreviousBookings username={username}/>
  </BasePage>;
}

function WorkExperiences(props: { experiences: Experiences }) {
  const {experiences} = props;
  const {t} = useTranslation();
  const elements = Object.keys(experiences).map(name => {
    const experience = experiences[name as keyof Experiences];
    if (!experience) return null;
    const color = stringToColor(name);
    const fontColor = experience > 5 ? 'white' : 'black';
    return <ExperienceRange fontColor={fontColor} label={t(name)} years={experience} colorScheme={color} key={name}/>
  }).filter(e => e);
  if (!elements.length) {
    return null
  }

  return elements.length ? <BorderBox css={css`display: flex; flex-direction: column; gap: 4px`}>
    <Heading size={'md'}>Starfsreynsla</Heading>
    {elements}
  </BorderBox> : null
}

function PreviousBooking(props: { position: Position, username: string, refresh: () => void }) {
  const {position} = props;
  const flakeModalOpen = useHookstate(false);
  const now = new Date();
  const endDate = position.endDate!!;
  const beginDate = position.beginDate!!;
  const status = position.cancelled ? 'Cancelled' : now > beginDate && now < endDate ? 'Active' : now > endDate ? 'Completed' : 'Scheduled';
  const username = getUsername()
  const navigate = useNavigate();

  return <BorderBox css={css`margin-bottom: 8px; display: flex; flex-direction: column; gap: 8px`}>
    <div css={css`display: flex; flex-direction: column`}>
      <div css={css`display: flex; align-items: center; gap: 8px; flex-wrap: wrap;`}>
        <Heading size={'sm'}>{position.institutionName}</Heading>
        <Text color={'gray.500'}>{position.clinicName}</Text>
        <Text color={'gray.500'}>{position.specialization}</Text>
      </div>
      <p><Badge>{formatDate(beginDate)}</Badge> til <Badge>{formatDate(endDate)}</Badge></p>
      <div>
        {status === "Cancelled" ? <Badge colorScheme={'red'}>Hætti við</Badge> : status === "Completed" ? <Badge colorScheme={'green'}>Lokið</Badge> : <Badge colorScheme={'yellow'}>Ólokið</Badge>}
      </div>
    </div>
    {status === "Cancelled" && position.cancelledAtDate && <div>
        <Text color={'gray.500'}>Hætti við með {differenceInDays(beginDate, position.cancelledAtDate)} daga fyrirvara</Text>
    </div>}

    {props.username === username && <div css={css`display: flex; gap: 4px; flex-wrap: wrap;`}>
        <Button size={'sm'} variant={'outline'} onClick={() => navigate(`/positions/${position._id}`)}>
            Skoða stöðu
        </Button>
      <Link to={`/positions/${position._id}/invoice`}>
        {(status === "Completed") &&
          <Button size={'sm'} leftIcon={<Icon as={FaFileInvoice}/>} colorScheme={'green'}>
            {position.invoiceSent ? 'Uppfæra reikning' : 'Senda reikning'}
          </Button>}
      </Link>
      {position.hasContract && <Link to={`/contracts/${props.position.positionRequestId}/overview`}>
        <Button size={'sm'} leftIcon={<Icon as={RiBillLine}/>} colorScheme={'blue'}>
          Skoða samning
        </Button>
      </Link>}
      <div>
        {status === 'Scheduled' && <Button size={'sm'} leftIcon={<CloseIcon/>} colorScheme={'red'}
            onClick={() => flakeModalOpen.set(true)}>Hætta við</Button>}
      </div>
    </div>}
    {flakeModalOpen.get() && <FlakeModal open={flakeModalOpen} positionId={position._id!!} refresh={props.refresh}/>}
  </BorderBox>;
}

const IconLabel = (props: {icon: React.ReactNode, children?: React.ReactNode}) => {
  return <div css={css`display: flex; align-items: center`}>
    {props.icon}
    <Text css={css`margin-left: 8px`}>{props.children}</Text>
  </div>;
};

const WorkHistory = (props: { previousEmployments: PreviousEmployment[] }) => {
  const {t} = useTranslation();
  return <BorderBox>
    <Heading size={"md"} css={css`margin-bottom: 8px`}>Starfsferill</Heading>
    <Accordion>
      {
        props.previousEmployments.map((p, i) => {
          return <AccordionItem key={i}>
            <AccordionButton>
              {p.institution}
              <AccordionIcon/>
            </AccordionButton>
            <AccordionPanel>
              {t(`${p.professionType}.${p.workerClass}`)}
              {p.department && <p>{p.department}</p>}
              {p.specialization && <p>{p.specialization}</p>}
              {p.position && <p>{p.position}</p>}
              <div css={css`font-weight: bold`}>
                {p.years} ár
              </div>
            </AccordionPanel>
          </AccordionItem>
        })
      }
    </Accordion>
  </BorderBox>;
};

const ExperienceRange = (props: {label: string, years: number, fontColor: string, colorScheme?: string}) => {
  const colorScheme = props.colorScheme || 'blue'
  const years = props.years === 10 ? '10+' : props.years;
  return <div>
    <b>{props.label}</b>
    <Progress borderRadius={4} value={props.years * 10} height={"20px"} colorScheme={colorScheme}>
      <ProgressLabel color={props.fontColor} css={css`font-size: 12px;`}>{years} ár</ProgressLabel>
    </Progress>
  </div>;
};

const FlakeModal = (props: {
  open: State<boolean>,
  positionId: string,
  refresh: () => void
}) => {
  // const submitError = useHookstate(null as string | null);
  // const reason = useHookstate('')
  const [position, setPosition] = useState(null as Position | null);
  const [institution, setInstitution] = useState(null as Institution | null);
  const [positionCreator, setPositionCreator] = useState(null as UserResponse | null);
  useEffect(() => {
    (async () => {
      const position = await getPositionById(props.positionId);
      const institution = await getInstitutionById(position.institutionId);
      setPosition(position);
      setInstitution(institution);
      setPositionCreator(await getUser(position.createdBy!!));
    })();
  }, []);
  return <Modal isOpen={props.open.get()} onClose={() => props.open.set(false)}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Hætta við stöðu</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 16px; margin-bottom: 16px`}>
        <Alert status={'warning'} css={css`display: flex; flex-direction: column`}>
          <div css={css`display: flex; align-items: center`}>
            <AlertIcon/>
            <Heading fontSize={'2xl'}>ÚPS</Heading>
          </div>
          {/*<Text>Þegar hætt er við samþykkta stöðu með minna en 7 daga fyrirvara, skal bæði gera grein fyrir breytingunni hér í útskýringardálknum fyrir neðan sem og upplýsa heilbrigðisstofnun daginn sem hætt er við stöðu til að afbókun gangi í gegn. Erfitt getur reynst að finna starfsmann með stuttum fyrirvara en með þessum hætti hjálpumst við að í þeirri leit.*/}
          {/*</Text>*/}
          <Text>Þegar hætt er við samþykkta stöðu skal upplýsa heilbrigðisstofnun til að afbókun gangi í gegn. Erfitt getur reynst að finna starfsmann með stuttum fyrirvara en með þessum hætti hjálpumst við að í þeirri leit.</Text>
        </Alert>
        {institution ? <LabelInfo label={`Símanúmer ${institution.name}`}>
          <Text>{institution.phoneNumber}</Text>
        </LabelInfo> : 'Hleð...'}
        {positionCreator ? <LabelInfo label={`Símanúmer starfsmanns (${positionCreator.user.electronicId.name})`}>
          <Text>{phoneToString(positionCreator.user.preferences.phoneNumbers[0])}</Text>
        </LabelInfo> : 'Hleð...'}
        {/*<Textarea placeholder="Veikindi / sjúkdómur í fjölskyldu / ferðalag / annað" value={reason.get()} onChange={e => reason.set(e.target.value)}/>*/}
        {/*<ErrorDisplay error={submitError}/>*/}
        {/*<GoButton colorScheme={'red'} submitError={submitError}*/}
        {/*  leftIcon={<FaExclamation/>}*/}
        {/*  onClick={async () => {*/}
        {/*    await cancelPosition(props.positionId, reason.get());*/}
        {/*    props.open.set(false);*/}
        {/*    props.refresh();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Staðfesta*/}
        {/*</GoButton>*/}
      </ModalBody>
    </ModalContent>
  </Modal>
}

