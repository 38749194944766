import React, { useState } from 'react';
import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useHookstate } from '@hookstate/core';
import { ModalBase } from './ModalBase';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const modalOpen = useHookstate(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    modalOpen.set(true);
  };

  // Add this condition to check if there's only one image
  if (images.length === 1) {
    return (
      <div
        css={css`
          width: 100%;
          height: 300px; // Adjust this value as needed
          overflow: hidden;
          border-radius: 8px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `}
      >
        <img src={images[0]} alt="Clinic image" />
      </div>
    );
  }

  return (
    <>
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={4}
        css={css`
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            cursor: pointer;
            object-fit: cover;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.01);
            }
          }
        `}
      >
        {images.slice(0, 2).map((image, index) => (
          <Box key={index} onClick={() => handleImageClick(image)}>
            <img
              src={image}
              alt={`Clinic image ${index + 1}`}
            />
          </Box>
        ))}
      </SimpleGrid>

      {images.length > 2 && (
        <Flex mt={4} flexWrap="wrap">
          {images.slice(2).map((image, index) => (
            <Box
              key={index + 2}
              onClick={() => handleImageClick(image)}
              m={1}
              css={css`
                width: 100px;
                height: 70px;
                cursor: pointer;
                overflow: hidden;
                border-radius: 4px;
                transition: transform 0.3s ease;

                &:hover {
                  transform: scale(1.1);
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              `}
            >
              <img
                src={image}
                alt={`Clinic image ${index + 3}`}
              />
            </Box>
          ))}
        </Flex>
      )}

      <ModalBase open={modalOpen} size={'3xl'}>
        <img
          src={selectedImage}
          alt="Mynd af heilsugæslu"
          css={css`
            width: 100%;
            height: auto;
            border-radius: 8px;
          `}
        />
      </ModalBase>
    </>
  );
};

export default ImageGallery;