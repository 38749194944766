import React from 'react';
import {BasePage} from "./BasePage";
import {Heading} from "@chakra-ui/react";
import {BorderBox} from "./BorderBox";
import {css} from "@emotion/react";

const NotFoundPage = () => {
  return (
    <BasePage>
      <Heading>ÚPS - Þessi síða fannst ekki!</Heading>
      <div css={css`padding: 8px`}>
        Kannski var henni eytt, eða er hún ekki til.
        <br/>
        <br/>
        Ef þú heldur að þetta sé villa, vinsamlegast hafðu samband við okkur á <a href={'mailto:heka@heka.is'}>heka@heka.is</a>
      </div>
    </BasePage>
  );
};

export default NotFoundPage;
