import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {Alert, AlertIcon, Heading, Text} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {Institution, Position} from "../types";
import {DefaultSpinner} from "./DefaultSpinner";
import {getInstitutionById, getPositionsForClinic} from "../net-utils";
import {ClinicPosition} from "./ClinicPage";

const ClinicPositionHistoryPage = () => {
  const params = useParams();
  const institutionId = params.institutionId as string | undefined
  const clinicId = params.clinicId as string | undefined
  if (!institutionId || !clinicId) {
    throw new Error('Institution id or clinic id is undefined')
  }
  const [institution, setInstitution] = useState(null as Institution | null);
  const [positions, setPositions] = useState(null as Position[] | null);

  function refreshPositions() {
    getPositionsForClinic(institutionId!!, clinicId!!, false).then(setPositions)
  }

  useEffect(() => {
    getInstitutionById(institutionId).then(setInstitution)
    refreshPositions();
  }, []);
  if (!institution || !positions) {
    return <DefaultSpinner/>
  }

  const clinic = institution.clinics.find(c => c.clinicId === clinicId);
  if (!clinic) {
    throw new Error('Clinic not found')
  }
  return (
    <BasePage>
      <Heading>Stöðusaga fyrir starfsstöðina <Link to={`/institutions/${institutionId}/clinics/${clinicId}`}>{clinic.name}</Link>
      </Heading>
      <Alert status="info">
        <AlertIcon/>
        <Text>Stöðusaga inniheldur allar liðnar mannaðar stöður.</Text>
      </Alert>
      {positions.map(position => <ClinicPosition position={position} refresh={refreshPositions}
        showCreatedBy={true} showDateCreated={true} key={position._id}/>)}
    </BasePage>
  );
};

export default ClinicPositionHistoryPage;
