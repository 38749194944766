import React from "react";
import { css, SerializedStyles } from '@emotion/react';
import {Text} from "@chakra-ui/react";

export const LabelInfo = (props: { label: string, value?: string | React.ReactNode, color?:
    boolean, css?: SerializedStyles, className?: string, children?: React.ReactNode }) => {
  return <div className={props.className} css={css`display: flex; align-items: center; gap: 4px; flex-wrap: wrap`}>
    <Text css={css`font-weight: bold;`}>{props.label}</Text>
    {props.children ? props.children : <Text>{props.value}</Text>}
  </div>;
};