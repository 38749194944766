import React from "react";
import {css, SerializedStyles} from "@emotion/react";

export const SectionContainer = (props: { children?: React.ReactNode, className?: string, css?: SerializedStyles }) => {
  return <div css={css`display: flex; gap: 8px; flex-wrap: wrap`} className={props.className}>
    {props.children}
  </div>;
};

export const HalfSection = (props: { children?: React.ReactNode; css?: SerializedStyles; className?: string }) => {
  return <div className={props.className} css={css`display: flex; flex-direction: column; gap: 4px; flex: 1`}>{props.children}</div>;
};