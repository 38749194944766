import React, {useEffect, useState} from 'react';
import {Contract} from "../types";
import {DefaultSpinner} from "./DefaultSpinner";
import {ContractListItem, getContractsForUser} from "../net-utils";
import {BasePage} from "./BasePage";
import {Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {useHookstate} from "@hookstate/core";
import {globalUser} from "../App";
import {useNavigate} from "react-router-dom";
import {css} from "@emotion/react";
import {formatDate} from "../heka-utils";

const UserContractsPage = () => {
  const [contracts, setContracts] = useState(null as ContractListItem[] | null);
  const userState = useHookstate(globalUser)
  const user = userState.get()
  const navigate = useNavigate()
  const refresh = () => {
    (async () => {
      const contracts = await getContractsForUser();
      setContracts(contracts);
    })();
  }
  useEffect(() => {
    refresh()
  }, []);
  if (!contracts || !user) {
    return <DefaultSpinner/>
  }
  return (
    <BasePage>
      <Heading fontSize={'2xl'}>Samningar</Heading>
      <div css={css`display: flex; flex-direction: column; gap: 4px`}>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Samningsnr</Th>
                <Th>Starfsstöð</Th>
                <Th>Stofnun</Th>
                <Th>Byrjun</Th>
                <Th>Lok</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contracts.map(c => <Tr key={c.contract.positionRequestId}
                css={css`cursor: pointer`} onClick={() => {
                  navigate(`/contracts/${c.contract.positionRequestId}/overview`)
                }}>
                <Td>{c.contract.contractId}</Td>
                <Td>{c.clinicName}</Td>
                <Td>{c.institutionName}</Td>
                <Td>{formatDate(c.beginDate)}</Td>
                <Td>{formatDate(c.endDate)}</Td>
              </Tr>)}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </BasePage>
  );
};

export default UserContractsPage;
