import {State, useHookstate} from '@hookstate/core';
import { Select, SelectProps } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import { getClockHours } from '../heka-utils';
import React, { useCallback, ForwardedRef } from 'react';

// Extend SelectProps and make the time prop optional
interface TimeSelectProps extends SelectProps {
  time?: State<string | undefined>;
  includeHalfHours?: boolean;
}

const TimeSelect = React.forwardRef((
  { time, includeHalfHours, ...rest }: TimeSelectProps,
  ref: ForwardedRef<HTMLSelectElement>
) => {
  const internalState = useHookstate<string | undefined>(rest.value as string | undefined);
  const state = time ?? internalState;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      state.set(e.target.value);
      if (rest.onChange) {
        rest.onChange(e);
      }
    },
    [state, rest]
  );

  return (
    <Select
      {...rest}
      iconColor="gray.500"
      icon={<TimeIcon color="gray.300" />}
      value={state.get()}
      onChange={handleChange}
      ref={ref}
    >
      {getClockHours(includeHalfHours ?? true).map((value) => (
        <option value={value}>{value}</option>
      ))}
    </Select>
  );
});

export { TimeSelect };
