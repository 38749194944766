import React, {useEffect, useState} from 'react';
import {BasePage} from './BasePage';
import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {
  Alert, AlertIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Select,
  Text,
  Textarea
} from '@chakra-ui/react';
import {addDays, differenceInDays, format, formatDistanceToNow, isSameDay} from 'date-fns';
import {
  calculatePayForPosition,
  cloneState,
  DATE_FMT, DATE_FMT_FULL, findAppropriatePayment,
  formatCurrency, formatDate,
  formatDateFull,
  getDayNameShort, getHighestWorkerClass,
  getSupplementalPay,
  getUsername
} from '../heka-utils';
import {useNavigate, useParams} from 'react-router-dom';
import {LabelInfo} from './LabelInfo';
import {Clinic, CreatePositionRequest, Institution, PaymentInfo, Position, ShiftTypeName} from '../types';
import {FaFacebook, FaFacebookMessenger, FaTwitter, FaUserCheck} from 'react-icons/fa';
import {CheckIcon, EmailIcon} from '@chakra-ui/icons';
import {State, useHookstate} from '@hookstate/core';
import {FavoriteIcon} from './FavoriteIcon';
import {
  createPositionRequest,
  deletePositionRequest,
  getClinicById,
  getInstitutionAdminMembers,
  getInstitutionById,
  getPositionById,
  getUserPaymentInfos,
  updatePositionRequest
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from 'react-i18next';
import {BiTrash} from "react-icons/bi";
import {globalFavoritePositions} from "./NavigationBar";
import {globalUser} from "../App";
import {PositionAdminControls} from "./ClinicPage";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import _ from "lodash";
import {groupShiftTimes} from "../shift-time-range";
import {Helmet} from "react-helmet";
import ImageGallery from './ImageGallery';

// Get full URL of page with https, hostname, etc.
const getUrl = (positionId: string) => `${window.location.protocol}//${window.location.host}/positions/${positionId}`

function ShareButtons(props: {positionId: string, title: string}) {
  const url = encodeURIComponent(getUrl(props.positionId));
  return <>
    <Heading fontSize={"lg"}>Deila</Heading>
    <div css={css`display: inline-flex;
            gap: 8px;
            width: fit-content;
            flex-wrap: wrap`}>
      <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse`}>
        <Button colorScheme={"facebook"} leftIcon={<FaFacebook/>}>
          Facebook
        </Button>
      </a>
      <a href={`https://www.facebook.com/dialog/send?link=${url}&redirect_uri=${url}&app_id=1735066596909708`} target='_blank'>
        <Button colorScheme={"messenger"} leftIcon={<FaFacebookMessenger/>}>
          Messenger
        </Button>
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${url}`} target="_blank">
        <Button colorScheme={"twitter"} leftIcon={<FaTwitter/>}>
          Twitter
        </Button>
      </a>
      <a href={`mailto:?subject=${props.title}&body=${url}`}>
        <Button colorScheme={"gray"} leftIcon={<EmailIcon/>}>
          Senda í tölvupóst
        </Button>
      </a>
    </div></>;
}

export const PositionPage = () => {
  const params = useParams()
  const positionId = params['positionId']
  const [position, setPosition] = useState(null as Position | null);
  const [clinic, setClinic] = useState(null as Clinic | null);
  if (!positionId) throw new Error('Missing positionId')
  useEffect(() => {
    (async () => {
      const position = await getPositionById(positionId);
      setPosition(position)
      const clinic = await getClinicById(position.institutionId, position.clinicId);
      setClinic(clinic);
    })();
  }, [positionId]);
  const refreshPosition = async () => {
    const position = await getPositionById(positionId);
    setPosition(position)
  }
  if (!position || !clinic) return <DefaultSpinner/>
  return <BasePage><PositionDisplay position={position} clinic={clinic} refreshPosition={refreshPosition}/></BasePage>
}

export const PositionDisplay = (props: {position: Position, clinic: Clinic, refreshPosition: () => Promise<void>}) => {
  const navigate = useNavigate()
  const {position, clinic} = props;
  const positionId = position._id!!
  const favoritePositions = useHookstate(globalFavoritePositions).get()
  const isApplyModalOpen = useHookstate(false);
  const user = useHookstate(globalUser).get()
  const submitError = useHookstate(null as string | null);
  if (!position) return <DefaultSpinner/>
  const beginDate = position.beginDate!!
  const endDate = position.endDate!!
  const title = `Heka - ${clinic.name} - ${formatDate(position.beginDate!!)} - ${formatDate(position.endDate!!)}`;
  const askForDetailsFn = async () => {
    const members = await getInstitutionAdminMembers(position.institutionId);
    const createdBy = members.find(it => it.username === position.createdBy)?.preferences?.email ?? 'heka@heka.is';
    let ccMembers = members.map(it => it.preferences.email);
    ccMembers.push('heka@heka.is');
    ccMembers = ccMembers.filter(it => it !== createdBy)
    const mailTo = `mailto:${createdBy}?subject=${encodeURIComponent(`Fyrirspurn um ${title}`)}&body=${encodeURIComponent(getUrl(positionId))}&cc=${encodeURIComponent(ccMembers.join(','))}`;
    window.location.href = mailTo;
  };
  return <>

    <Helmet>
      <div id="fb-root"></div>
      <script async defer crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0&appId=1735066596909708&autoLogAppEvents=1"
        nonce="45IOuwDA"></script>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={position.description} />
      <meta property="og:image" content={position.clinicImagePath} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="400" />
    </Helmet>
    <div css={css`display: flex;
        flex-direction: column;
        gap: 8px`}>
      {user?.institutions?.map(i => i.id)?.includes(position.institutionId) && <div css={css`display: flex;
          flex-direction: column;
          gap: 8px`}>
        {position.cancelled && <Alert status={'error'}>
            <AlertIcon/>
            <Text>Verktakinn sem samþykkti þessa umsókn hefur merkt að hann hafi hætt við. <br/>
                Við ráðleggum þér að endurbirta stöðuna eða fara yfir hinar umsókirnar.
            </Text>
        </Alert>}
        {!position.isPublic && <AlertDisplay status={"warning"}
            error={'Þessi staða er ekki sýnileg notendum. Ýttu á "Birta auglýsingu" til þess að gera hana sýnilega.'}/>}
          <BorderBox>
              <PositionAdminControls hideShow={true} position={position}
                  onDelete={() => navigate(`/institutions/${position.institutionId}`)}
                  refresh={() => window.location.reload()}/>
          </BorderBox>
      </div>}
      <FavoriteIcon css={css`margin-left: auto`} showText={true} positionId={positionId} isEnabled={
        favoritePositions?.some(favoritePosition => favoritePosition.positionId === positionId) ?? false
      }/>
      <div>
        {clinic && clinic.images && clinic.images.length > 0 ? (
          <ImageGallery images={clinic.images}/>
        ) : null}
      </div>
      <BorderBox css={css`display: flex;
          flex-wrap: wrap;
          gap: 16px; border: none; margin-bottom: 16px;`}>
        <div css={css`flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px`}>
          <div css={css`display: flex; gap: 8px; flex-wrap: wrap;`}>
            {user?.user && <Badge colorScheme={'green'}>{formatCurrency(calculatePayForPosition(position, user.user))}</Badge>}
            <RequirementsBadges position={position}/>
            <Badge colorScheme={'purple'}>{position.htype}</Badge>
          </div>
          <Heading size={'xl'}
            css={css`margin-bottom: 8px`}>{clinic.name}</Heading>
          <div css={css`display: flex;
              flex-direction: column;
              gap: 8px`}>
            <LabelInfo label={'Stofnun'} value={position.institutionName}/>
            <Divider/>
            <LabelInfo label={'Heilbrigðisstétt'} value={'Læknir'}/>
            <Divider/>
            <LabelInfo label={'Sérgrein'} value={position.specialization}/>
            <Divider/>
            <LabelInfo label={'Tímabil'}>
              {formatDateFull(beginDate)}
              {' til '}
              {formatDateFull(endDate)}
              {' '}
              ({formatDistanceToNow(beginDate)
              .replace('days', 'dagar').replace('day', 'dagur')
              .replace('months', 'mánuðir').replace('month', 'mánuður')} í byrjun)
            </LabelInfo>
            <Divider/>
          </div>
          <div css={css`margin-top: auto`}>
          </div>
          <div css={css`margin-top: auto;
              display: flex;
              gap: 8px; flex-wrap: wrap;`}>
            <Button leftIcon={<FaUserCheck/>} css={css`flex: 6`} colorScheme={'green'} onClick={() => isApplyModalOpen.set(true)}>{
              !position.userRequest ? 'Sækja um' : 'Breyta umsókn'
            }</Button>
            <Button onClick={askForDetailsFn} colorScheme={'gray'}
              leftIcon={<EmailIcon/>}
              css={css`flex: 4; min-width: inherit;`}>Senda fyrirspurn</Button>
          </div>
        </div>
      </BorderBox>
      <div css={css`display: flex;
          gap: 8px;
          flex-wrap: wrap;`}>
        <LeftSection position={position} clinic={clinic}/>
        <RightSection position={position}/>
      </div>
      <ShareButtons positionId={position._id!!} title={title}/>
    </div>
    <ApplyModal open={isApplyModalOpen} position={position} refreshPosition={props.refreshPosition} />
  </>;
};

function RequirementsBadges(props: { position: Position }) {
  const {t} = useTranslation();
  return <>
    {props.position.workerClassRequirements.map(workerClass => <Badge key={workerClass}>
      {t(`${props.position.professionType}.${workerClass}`)}
    </Badge>)}
  </>;
}

const LeftSection = (props: { position: Position, clinic: Clinic | null }) => {
  const position = props.position;
  const shifts = position.shifts
  const {t} = useTranslation()
  const dynamicShiftTypes = props.position.dynamicShiftTypes
  const getDynamicShiftByName = (name: string) => dynamicShiftTypes.find(dynamicShiftType => dynamicShiftType.shiftName === name)
  const dateComponents = shifts.map(shift => <div key={shift.date.toISOString()}>
    <div css={css`display: flex;
      gap: 4px;
      align-items: center; `}>
      <Heading fontSize={'md'}>{getDayNameShort(shift.date)}</Heading>
      <Text fontSize={'md'}>{format(shift.date, DATE_FMT)}</Text>
    </div>
  </div>)
  return <div css={css`flex: 2;
    display: flex;
    flex-direction: column;
    gap: 8px`}>
    {position.description.length ? <><Heading fontSize={'lg'}>Lýsing</Heading>
      <BorderBox>
        <Text css={css`white-space: pre-wrap;`}>{position.description}</Text>
      </BorderBox>
      </> : null}
    {props.clinic && props.clinic.description && (
      <>
        <Heading fontSize={'lg'}>Um okkur</Heading>
        <BorderBox>
          <Text css={css`white-space: pre-wrap;`}>{props.clinic.description}</Text>
        </BorderBox>
      </>
    )}
    <Heading fontSize={'lg'}>Vaktir í boði</Heading>
    <BorderBox>
      <div css={css`display: flex;
        gap: 8px;
        flex-wrap: wrap`}>
        {dateComponents}
      </div>
      <Divider css={css`margin-bottom: 12px;
        margin-top: 12px`}/>
      <div css={css`display: flex;
        gap: 4px;
        flex-direction: column;
        flex-wrap: wrap`}>
        {
          dynamicShiftTypes.map(shiftType => {
            return <div key={shiftType.shiftName}><Badge colorScheme={shiftType.color}>
              {shiftType.shiftName} {groupShiftTimes(shiftType.shiftTimes, t)}
            </Badge></div>;
          })
        }
      </div>
    </BorderBox>
  </div>;
};

const RightSection = (props: {position: Position}) => {
  const {position} = props;
  const {t} = useTranslation();
  const userState = useHookstate(globalUser);
  const user = userState.get()?.user;
  if (!user) return <DefaultSpinner/>
  const workerClass = getHighestWorkerClass(user, position.professionType);
  const totalPay = calculatePayForPosition(position, user);
  return <div css={css`flex: 1; display: flex; flex-direction: column; gap: 8px`}>
    <Heading fontSize={'lg'}>Greiðsla</Heading>
    <BorderBox css={css`display: flex; flex-direction: column; gap: 8px`}>
      {(Object.keys(position.shiftTypePays) as ShiftTypeName[]).map(shiftType => {
        const workerClassPays = position.shiftTypePays[shiftType] ?? {};
        const pay = findAppropriatePayment(workerClassPays, workerClass) + getSupplementalPay(user, position, shiftType);
        return <LabelInfo label={`${t(shiftType)}`} key={shiftType}>
          {formatCurrency(pay)}
        </LabelInfo>;
      })}
      {!position.isCollectiveAgreementBased &&
          <><LabelInfo label={'Samtals'}>{formatCurrency(totalPay)}</LabelInfo>
              <Divider/>
          </>}
      <LabelInfo label={'Vinnuveitandi greiðir tryggingar'}>
        {position.insurancePaid ? 'Já' : 'Nei'}
      </LabelInfo>
      <LabelInfo label={'Ferðakostnaður greiddur'}>
        {position.travelCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.travelCostPaid && position.travelCost ? <LabelInfo label={'Ferðakostnaður'}>{formatCurrency(position.travelCost)}</LabelInfo> : null}
      <LabelInfo label={'Ferðadagur greiddur'}>
        {position.travelDayCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.travelDayCostPaid ? <LabelInfo label={'Ferðadagur'}>{formatCurrency(position.travelDayCost)}</LabelInfo> : null}
      <LabelInfo label={'Húsnæðisleiga greidd'}>
        {position.accommodationCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.accommodationCostPaid && position.accommodationCost ? <LabelInfo label={'Húsnæðisleiga'}>{formatCurrency(position.accommodationCost)}</LabelInfo> : null}
      <Divider/>
      {!props.position.isCollectiveAgreementBased ? <LabelInfo label={'Heildargreiðsla'}>
        {formatCurrency(totalPay + position.travelCost + position.travelDayCost - position.accommodationCost * position.shifts.length)}
      </LabelInfo> : <LabelInfo label={'Greiðsla'}>Samkvæmt launaflokki</LabelInfo>}
    </BorderBox>
  </div>;
};

function ApplyModal(props: { open: State<boolean>, position: Position, refreshPosition: () => Promise<void> }) {
  const {open, position} = props;
  const onClose = () => open.set(false);
  const positionId = position._id!!;
  const begin = position.beginDate!!;
  const end = position.endDate!!;
  const username = getUsername();
  const existingPositionRequest = position.userRequest;
  const positionRequestState = useHookstate(existingPositionRequest ?
    existingPositionRequest.request : createBlankPositionRequest(username, positionId, begin, end))
  const positionRequest = positionRequestState.get();

  const positionAccepted = position.acceptedBy === getUsername();
  const submitError = useHookstate(null as string | null);
  return <Modal isOpen={open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>{existingPositionRequest ? 'Breyta umsókn' : 'Sækja um'}</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Textarea placeholder={'Auka skilaboð'} value={positionRequest.message} onChange={
          e => positionRequestState.message.set(e.target.value)
        }/>
      </ModalBody>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 8px`}>
        {!position.isCollectiveAgreementBased && <><Heading fontSize={'lg'}>Vaktir</Heading>
            <div css={css`display: flex;
              gap: 8px;
              flex-wrap: wrap`}>
              {_.range(0, differenceInDays(end, begin) + 1)
                .map((i) => addDays(begin, i))
                .map((date) => <Checkbox isChecked={
                  positionRequest.shifts.find(shift => isSameDay(shift, date)) !== undefined
                } key={date.toDateString()}
                  onChange={(e) => {
                    if (e.target.checked) {
                      positionRequestState.shifts.merge([date]);
                    } else {
                      positionRequestState.shifts.set(positionRequest.shifts.filter(d => !isSameDay(d, date)));
                    }
                  }}
                >
                  {format(date, DATE_FMT)}
                </Checkbox>)}
            </div>
        </>
        }
      </ModalBody>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <AlertDisplay error={submitError}/>
        {!existingPositionRequest ? <GoButton colorScheme={'green'} onClick={async () => {
          await createPositionRequest(positionRequest);
          await props.refreshPosition();
          onClose()
        }} submitError={submitError}>Sækja um</GoButton> : <GoButton colorScheme={'green'} leftIcon={<CheckIcon/>} onClick={async () => {
          await updatePositionRequest(existingPositionRequest._id, positionRequest);
          await props.refreshPosition();
          onClose()
        }} isDisabled={positionAccepted} submitError={submitError}>
          Breyta umsókn
        </GoButton>}
        {existingPositionRequest && <Button leftIcon={<BiTrash/>} colorScheme={'red'} onClick={async () => {
          if (window.confirm("Ertu viss um að þú viljir eyða umsókninni?")) {
            await deletePositionRequest(existingPositionRequest._id);
            await props.refreshPosition();
            onClose()
          }
        }} isDisabled={positionAccepted}>Eyða umsókn</Button>}
      </ModalFooter>
    </ModalContent>
  </Modal>
}

function createBlankPositionRequest(
  username: string,
  positionId: string,
  beginDate: Date,
  endDate: Date,
): CreatePositionRequest {
  const dates = _.range(0, differenceInDays(endDate, beginDate) + 1)
    .map((i) => addDays(beginDate, i));
  return {
    positionId: positionId,
    shifts: dates,
    message: '',
    accountNumber: ''
  }
}