import {State, useHookstate} from "@hookstate/core";
import React, {useEffect} from "react";
import {CloseButton, Select} from "@chakra-ui/react";
import {onSelect} from "../heka-utils";
import {css} from "@emotion/react";

export function CollectibleList(props: {
    options: string[],
    selectedItems: State<string[]>,
    onChange?: (selectedItems: string[]) => void,
    isDisabled?: boolean,
}) {
    const selectedItems = useHookstate(props.selectedItems)
    const lastElem = useHookstate('')
    useEffect(() => {
        const lastElemVal = lastElem.get();
        if (lastElemVal !== '') {
            selectedItems.merge([lastElemVal])
            lastElem.set('')
            props.onChange?.(selectedItems.get())
        }
    }, [lastElem]);
    const isNotSelected = (item: string) => !selectedItems.map(stateOption => stateOption.get()).includes(item);
    const allOptionsSelected = () => props.options.every(option => !isNotSelected(option));

    function Sublist(innerProps: { selected: State<string> }) {
        return <Select onChange={onSelect(innerProps.selected)} value={innerProps.selected.get()} isDisabled={props.isDisabled}>
            <option value={''} disabled hidden>Veldu möguleika</option>
            {props.options.filter(option => isNotSelected(option) || option === innerProps.selected.get())
                .map(option => <option key={option} value={option}>{option}</option>)}
        </Select>
    }

    return <div css={css`display: flex; flex-direction: column; gap: 4px`}>
        {selectedItems.map((option, index) => <div css={css`display: flex; gap: 4px; align-items: center`}
                                                   key={option.get()}>
            <Sublist selected={option}/>
            {index !== 0 && <CloseButton onClick={() => {
                // remove the option from the list
                selectedItems.set(selectedItems.get().filter((_, i) => i !== index))
            }}/>}
        </div>)}
        {!allOptionsSelected() && <Sublist selected={lastElem}/>}
    </div>
}