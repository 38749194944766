import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import remarkGfm from "remark-gfm";

const TermsPage = () => {
  const [md, setMd] = useState('');
  useEffect(() => {
    fetchFile('/terms.md').then(setMd);
  }, []);
  return (
    <BasePage>
      <ReactMarkdown components={ChakraUIRenderer()} remarkPlugins={[remarkGfm]}>
        {md}
      </ReactMarkdown>
    </BasePage>
  );
};

function fetchFile(url: string) {
  return fetch(url).then((res) => res.text());
}

export default TermsPage;
