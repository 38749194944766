import React, {Ref, RefObject, useEffect, useRef, useState} from 'react';
import {NavigationBar} from "./NavigationBar";
import {css} from "@emotion/react";
import {Button, Checkbox, CheckboxGroup, Input, Modal, ModalBody, ModalHeader} from "@chakra-ui/react";
import {State, useHookstate} from "@hookstate/core";
import {globalUser} from "../App";
import {DefaultSpinner} from "./DefaultSpinner";
import {Editor, EditorRef, EmailEditor} from "react-email-editor";
import {ExportHtmlResult, ExportPlainTextResult} from "embed/Config";
import {ModalBase} from "./ModalBase";
import {withCheckboxState} from "../heka-utils";
import {InputForm} from "./InputForm";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import {RecipientFilters, sendBroadcastEmail, sendTestEmail} from "../net-utils";
import {validateEmail} from "../validations";

const loadFromLocalStorage = () => {
  const design = localStorage.getItem('email-design');
  if (design) {
    return JSON.parse(design);
  }
  return null;
}

const saveToLocalStorage = (data: Record<string, any>) => {
  localStorage.setItem('email-design', JSON.stringify(data));
}

const EmailEditorPage = () => {
  const recipientsModalOpen = useHookstate(false);
  const sendTestEmailModalOpen = useHookstate(false);
  const user = useHookstate(globalUser);
  const [subject, setSubject] = useState('');
  const html = useRef('');
  const plainText = useRef('');
  useEffect(() => {
    const userRef = user.get();
    if (!userRef) return;
    if (!userRef.user.isAdmin) {
      window.location.href = '/';
      return;
    }
  }, [user]);
  if (!user.get()) return <DefaultSpinner/>;

  const onChange = async (editor: Editor) => {
    const data = await exportHtml(editor);
    const plainTextResult = await exportPlainText(editor);
    if (data?.design) {
      saveToLocalStorage(data?.design);
    }
    if (data?.html) {
      html.current = data?.html;
    }
    if (plainTextResult) {
      plainText.current = plainTextResult.text;
    }
  }

  const onReady = async (e: Editor) => {
    const design = loadFromLocalStorage();
    if (design) {
      e.loadDesign(design);
      const data = await exportHtml(e);
      if (data?.html) {
        html.current = data.html;
      }
      const plainTextResult = await exportPlainText(e);
      if (plainTextResult) {
        plainText.current = plainTextResult.text;
      }
    }
    e.addEventListener('design:updated', () => onChange(e));
  };
  return (
    <div css={css`display: flex; flex-direction: column; width: 100%; gap: 8px`}>
      <div css={css`display: flex; width: 50%; margin: 0 auto; flex-direction: column; gap: 8px`}>
        <NavigationBar/>
        <div css={css`display: flex; gap: 8px;`}>
          <Button colorScheme={'blue'} onClick={() => recipientsModalOpen.set(true)}
            isDisabled={
              subject.length === 0
            }
          >Velja viðtakendur og senda</Button>
          <Button isDisabled={
            subject.length === 0
          } onClick={
            () => sendTestEmailModalOpen.set(true)
          }>Senda prufupóst</Button>
        </div>
        <div>
          <Input placeholder={'Titill tölvupósts'}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
          />
        </div>
      </div>
      <div css={css`display: flex; flex-direction: column; width: 90%; margin: 0 auto; gap: 8px`}>
        <EmailEditor minHeight={'80vh'}
          onReady={onReady}
        />
      </div>
      {recipientsModalOpen.get() && <RecipientListModal open={recipientsModalOpen}
        onClose={() => recipientsModalOpen.set(false)}
        html={html} plainText={plainText} subject={subject}
      />}
      {sendTestEmailModalOpen.get() && <SendTestEmailModal open={sendTestEmailModalOpen} html={html} plainText={plainText} subject={subject}
        onClose={() => sendTestEmailModalOpen.set(false)}/>}
    </div>);
};

const SendTestEmailModal = (props: {
  open: State<boolean>,
  html: RefObject<string>,
  plainText: RefObject<string>,
  subject: string,
  onClose: () => void
}) => {
  const email = useHookstate('');
  const error = useHookstate(null as string | null);
  return <ModalBase open={props.open} title={'Senda prufupóst'}>
    <InputForm label={'Netfang'} state={email} validate={validateEmail}/>
    <GoButton submitError={error} colorScheme={'green'} onClick={async () => {
      error.set(null);
      if (!email.get() || email.get().length === 0) {
        error.set('Netfang vantar');
        return;
      }
      const html = props.html.current;
      if (!html || html.length === 0) {
        error.set('Villa við að sækja HTML');
        return;
      }
      const plainText = props.plainText.current;
      if (!plainText || plainText.length === 0) {
        error.set('Villa við að sækja PlainText');
        return;
      }

      await sendTestEmail({
        email: email.get(),
        template: {
          html: html,
          plainText: plainText,
          subject: props.subject
        }
      });
      props.onClose();
    }} isDisabled={
      email.get().length === 0 || validateEmail(email.get()) !== null
    }>Senda</GoButton>
    {error.get() && <AlertDisplay error={error}/>}
  </ModalBase>
}

const RecipientListModal = (props: {
  open: State<boolean>,
  onClose: () => void,
  html: RefObject<string>,
  plainText: RefObject<string>,
  subject: string
}) => {
  const selected = useHookstate<RecipientFilters>({
    doctors: false,
    nurses: false,
    midwives: false,
    managers: false
  });
  const error = useHookstate(null as string | null);
  return <ModalBase open={props.open} title={'Velja viðtakendur'}>
    <div css={css`display: flex; gap: 8px; flex-wrap: wrap`}>
      <Checkbox
        {...withCheckboxState(selected.doctors)}
      >
        Læknar
      </Checkbox>
      <Checkbox
        {...withCheckboxState(selected.nurses)}
      >
        Hjúkrunarfræðingar
      </Checkbox>
      <Checkbox
        {...withCheckboxState(selected.midwives)}
      >
        Ljósmæður
      </Checkbox>
      <Checkbox
        {...withCheckboxState(selected.managers)}
      >
        Stjórnendur heilbrigðisstofnana
      </Checkbox>
    </div>
    <GoButton
      submitError={error}
      disabled={Object.values(selected.get()).every(k => !k)}
      onClick={async () => {
        const html = props.html.current;
        const plainText = props.plainText.current;
        if (!html || html.length === 0) {
          error.set('Villa við að sækja HTML');
          return;
        }
        if (!plainText || plainText.length === 0) {
          error.set('Villa við að sækja PlainText');
          return;
        }
        await sendBroadcastEmail({
          template: {
            html: html,
            plainText: plainText,
            subject: props.subject
          },
          filters: selected.get()
        })
      }}
    >Senda</GoButton>
    {
      error.get() && <AlertDisplay error={error}/>
    }
  </ModalBase>
}

const exportHtml = async (editor: Editor): Promise<ExportHtmlResult | null> => {
  return new Promise((resolve) => {
    editor.exportHtml((data: ExportHtmlResult) => {
      resolve(data);
    });
  })
}

const exportPlainText = async (editor: Editor): Promise<ExportPlainTextResult | null> => {
  return new Promise((resolve) => {
    editor.exportPlainText((data: ExportPlainTextResult) => {
      resolve(data);
    });
  })
}

export default EmailEditorPage;