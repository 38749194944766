import React from 'react';
import { State, useHookstate } from '@hookstate/core';
import { css } from '@emotion/react';
import { Checkbox, CheckboxProps, Fade } from '@chakra-ui/react';

export const OptionalInputCheckbox = (p: {
  label: string | React.ReactNode,
  children: React.ReactNode,
  open?: State<boolean>
} & CheckboxProps) => {
  const {label, children, open, onChange, ...rest} = p;
  const defaultState = useHookstate(p.defaultChecked ?? false);
  const checkedState = useHookstate(p.open ?? defaultState);
  const checked = checkedState.get() || !!p.isChecked;
  return <div css={css`display: flex; flex-direction: column; gap: 4px;`}>
    <Checkbox isChecked={checked} onChange={event => {
      checkedState.set(event.target.checked);
      onChange?.(event);
    }} {...rest}>{label}</Checkbox>
    {!p.isDisabled && <Fade in={checked} css={css`display: flex; flex-direction: column; gap: 4px;`}>
      {checked && children}
    </Fade>}
  </div>;
};