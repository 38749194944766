/**
 * Samningsvidmot skal hanna med tilliti til utlits her a thessu spjaldi. Þau atriði sem eru breytileg hverju sinni eru :
 *
 * A) Heilbrigðisstofnun (Breytilegt)
 * B) kt stofnunar
 * C) Skammstöfun stofnunar
 * D) Header á skjali (Logo og nafn)
 *
 * Inn í samningi:
 *
 * 1) Nafn seljanda
 * 2) Læknanúmer seljanda / Hjúkrunarnúmer seljanda
 * 3) Sími seljanda
 * 4) Netfang seljanda
 * 5) Tímabil
 * 6) Reikningsupplýsingar seljanda
 */

import React, {useEffect, useState} from 'react';
import {BasePage} from './BasePage';
import {Button, Editable, EditableInput, EditablePreview, FormLabel, Heading, Select, Text} from '@chakra-ui/react';
import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {State, useHookstate} from '@hookstate/core';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import reactStringReplace from 'react-string-replace';
import {
  calculatePayForPosition, findAppropriatePayment,
  formatPhone,
  getHighestWorkerClass,
  getUsername,
  onSelect,
  phoneToString
} from '../heka-utils';
import {Contract, ContractInformation, PaymentInfo, Position, User} from "../types";
import {useNavigate, useParams} from "react-router-dom";
import {
  ExportContract,
  createContract,
  downloadContractPdf,
  getContractInformation,
  acceptPositionRequest, getContractById
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {format} from "date-fns";
import {DownloadIcon} from "@chakra-ui/icons";
import {GoButton} from "./GoButton";
import {InputForm} from "./InputForm";
import {globalUser} from "../App";
import {validateEmail, validateSSN} from "../validations";
import {OptionalInputCheckbox} from "./OptionalInputCheckbox";
import {formatShiftTimesToString, groupAdjacentShiftTimes, groupShiftTimes} from "../shift-time-range";
import {useTranslation} from "react-i18next";
import {createContractText, createContractTextHVE, createContractTextHveOlafsvik} from "../contract-text";

interface ContractData {
  institutionName: string, // Heilbrigðisstofnun
  institutionNameEf: string,
  institutionSSN: string, // Kennitala stofnunar
  institutionAbbr: string,
  clinicName: string, // Heilsugæsla
  clinicAddress: string,
  employeeName: string, // Nafn umsækjanda
  employeeSSN: string, // Kennitala umsækjanda
  employeePhone: string, // Sími umsækjanda
  employeeEmail: string, // Netfang umsækjanda
  employeeProfession: string, // Starfsgrein umsækjanda
  employeeBankNumber: string, // Banki umsækjanda (4 stafir)
  employeeHBNumber: string, // Höfuðbók umsækjanda (2 stafir)
  employeeCertificationNumber: string, // Læknanúmer
  employeeAccountNumber: string, // Reikningsnúmer umsækjanda
  positionBeginDate: string, // YYYY-MM-DD
  positionEndDate: string, // YYYY-MM-DD
  totalPayment: number, // Heildarlaun
  dateSigned: string,
  positionId: string,
  accommodationCost: number,
  travelCost: number,
  shiftPays: Partial<Record<string, string>>
}

export const ContractPageEx = () => {
  const contractText = useHookstate<string | undefined>(undefined)
  const positionRequestId = useParams().requestId as string | undefined;
  const contractMap = useHookstate(new Map<string, string>())
  const submitError = useHookstate<string | null>(null)
  const user = useHookstate(globalUser)
  const institutionSignerSSN = useHookstate<string>('')
  const institutionSignerEmail = useHookstate<string>('')
  const navigate = useNavigate();
  const {t} = useTranslation()
  if (!positionRequestId) {
    throw new Error('Position request id is undefined')
  }

  useEffect(() => {
    const localUser = user.get();
    if (localUser) {
      institutionSignerSSN.set(localUser.user.electronicId.ssn)
      institutionSignerEmail.set(localUser.user.preferences.email)
    }
  }, [user]);

  const [contractInfo, setContractInfo] = useState(null as ContractInformation | null);
  const contractData = contractInfo ? createContractData(contractInfo, t) : null;

  useEffect(() => {
    contractMap.set(new Map<string, string>());

    (async () => {
      const contractInformation = await getContractInformation(positionRequestId);
      if (contractInformation.position.hasContract && contractInformation.position.positionRequestId) {
        try {
          const contract = await getContractById(positionRequestId, false)
          if (contract && !contract.isInvalid) {
            navigate(`/contracts/${contractInformation.position.positionRequestId}/overview`)
            return
          }
        } catch (e) {
          console.error('Failed to get contract yet position hasContract=true', e)
        }
      }
      setContractInfo(contractInformation)
      const {position, request, user} = contractInformation;
      let contractTextTemplate: string
      if (position.institutionId === 'heilbrigdisstofnun.vesturlands') {
        if (position.clinicId === 'hve.-.olafsvik') {
          contractTextTemplate = createContractTextHveOlafsvik(position, request, user, t)
        }
        else {
          contractTextTemplate = createContractTextHVE(position, request, user, t)
        }
      } else {
        contractTextTemplate = createContractText(position, request, user, t)
      }
      contractText.set(contractTextTemplate)
    })()
  }, [positionRequestId]);


  const markdown = contractText.get() && ReactMarkdown({
    children: contractText.get()!!,
    components: ChakraUIRenderer()
  });
  if (!contractInfo) {
    return <DefaultSpinner/>
  }
  const markdownWithTemplates = markdown && contractData ? replaceTemplates(markdown, contractData, '0', contractMap) : null

  function exportContract(): ExportContract {
    return {
      contractTemplate: contractText.get()!!,
      contractValues: Array.from(contractMap.get().values()),
      positionRequestId: positionRequestId!!
    };
  }


  return <BasePage css={css`display: flex;
    flex-direction: column;
    gap: 8px`}>
    <div>
      {contractInfo.position.createdBy == getUsername() && <Text fontSize={'sm'} color={'gray.600'}>Skref 2/2</Text>}
      <Heading>Samningur</Heading>
    </div>
    <BorderBox>
      {markdownWithTemplates}
      <p><b>Samningsnúmer:</b> {contractInfo.contractId}</p>
    </BorderBox>
    {
      submitError.get() && <Text color={'red'}>{submitError.get()}</Text>
    }
    <BorderBox css={css`display: flex; gap: 8px; flex-direction: column`}>
      <InputForm label={`Kennitala undirskrifanda ${contractInfo.institution.nameAbbr}`} state={institutionSignerSSN}
        validate={validateSSN}/>
      <InputForm label={`Netfang undirskrifanda ${contractInfo.institution.nameAbbr}`} state={institutionSignerEmail}
        validate={validateEmail}/>
    </BorderBox>
    <div css={css`display: flex; gap: 8px`}>
      <GoButton onClick={async () => {
        await createContract({
          ...exportContract(),
          institutionSignerSSN: institutionSignerSSN.get(),
          institutionSignerEmail: institutionSignerEmail.get()
        })
        if (!contractInfo.position.isAccepted) {
          await acceptPositionRequest(positionRequestId)
        }
        navigate(`/contracts/${positionRequestId}/overview`)
      }} submitError={submitError}>Samþykkja umsókn</GoButton>
      <Button leftIcon={<DownloadIcon/>} isDisabled={!contractText.get() || !contractInfo} onClick={async () => {
        await downloadContractPdf(exportContract());
      }}>Sækja PDF</Button>
    </div>
  </BasePage>;
};

const EditableText = (props: { label: string, textMap: State<Map<string, string>>, uniqueKey: string }) => {
  useEffect(() => {
    if (props.textMap.get().get(props.uniqueKey) === undefined) {
      const newMap = new Map(props.textMap.get())
      newMap.set(props.uniqueKey, props.label)
      props.textMap.set(newMap)
    }
  }, [])
  return <Editable defaultValue={props.label} css={css`display: inline-block; `} border={1}>
    <EditablePreview borderWidth="1px" borderRadius="lg" paddingLeft={1} paddingRight={1}/>
    <EditableInput onChange={e => {
      const newMap = new Map(props.textMap.get())
      newMap.set(props.uniqueKey, e.target.value)
      props.textMap.set(newMap)
    }}/>
  </Editable>;
};

function replaceMacros(markdownText: string, contractData: Map<string, string>): string {
  const regex = /\[\[(.*?)]]/g;
  return markdownText.replace(regex, (match, key) => {
    return String(contractData.get(key) ?? '');
  });
}

// replace templates in react element with EditableText
const replaceTemplates = (element: React.ReactElement, contractData: ContractData, keyBase: string, stateMap: State<Map<string, string>>): React.ReactNode => {
  const newChildrenEx = element.props.children?.map((child: React.ReactElement | string, mapIdx: number) => {
    if (typeof child === 'string' && /{{.*}}/.test(child)) {
      const newChild = reactStringReplace(child, /{{(.*?)}}/, (match, index) => {
        const uniqueKey = `map-${keyBase}-${mapIdx}-${index}`;
        if (match.includes(":")) {
          const split = match.split(":");
          const key = split[0];
          if (key in contractData) {
            return <EditableText label={String(contractData[key as keyof ContractData])} uniqueKey={uniqueKey} textMap={stateMap} key={uniqueKey}/>
          }
          return <EditableText label={split[1] ?? ''} uniqueKey={uniqueKey} textMap={stateMap} key={uniqueKey}/>;
        }
        return <EditableText label={match} uniqueKey={uniqueKey} textMap={stateMap} key={uniqueKey}/>;
      });
      return React.cloneElement(element, { children: newChild });
    } else if (React.isValidElement(child)) {
      return replaceTemplates(child, contractData, `${keyBase}-${mapIdx}`, stateMap);
    }
    return child;
  });
  return React.cloneElement(element, {}, newChildrenEx);
}

function createContractData(contractInfo: ContractInformation, t: (_: string | undefined) => string): ContractData {
  const {institution, position, user} = contractInfo
  const institutionAbbr = institution.nameAbbr;
  const clinic = institution.clinics.find(c => c.clinicId === position.clinicId);
  const dateFmt = (date: Date) => format(date, 'dd/MM/yyyy');
  return {
    institutionName: institution.name,
    institutionNameEf: institution.name.replace("Heilbrigðisstofnun", "Heilbrigðisstofnunar"),
    institutionSSN: institution.ssn,
    institutionAbbr: institutionAbbr,
    clinicName: clinic?.name ?? '',
    clinicAddress: clinic?.address ?? '',
    employeeName: user.electronicId.name,
    employeeSSN: user.electronicId.ssn,
    employeePhone: phoneToString(user.preferences.phoneNumbers[0]),
    employeeEmail: user.preferences.email,
    employeeProfession: user.preferences.professions[0].type,
    employeeBankNumber: '',
    employeeHBNumber: '',
    employeeAccountNumber: '',
    employeeCertificationNumber: user.certificationId,
    positionBeginDate: dateFmt(position.beginDate!!),
    positionEndDate: dateFmt(position.endDate!!),
    totalPayment: calculatePayForPosition(contractInfo.position, user),
    dateSigned: dateFmt(new Date()),
    positionId: position._id!!,
    accommodationCost: position.accommodationCost ?? 0,
    travelCost: position.travelCost ?? 0,
    shiftPays: {}
  };
}