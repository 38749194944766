import React from 'react';
import {Text, TextProps} from "@chakra-ui/react";

const GrayText = (props: {children: React.ReactNode } & TextProps) => {
  return (
    <Text color={'gray'} fontStyle={'italic'} {...props}>{props.children}</Text>
  );
};

export default GrayText;
