import { useHookstate } from '@hookstate/core';
import { css, SerializedStyles } from '@emotion/react';
import { Tooltip } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import React, {useEffect} from 'react';
import {getUsername} from "../heka-utils";
import {addFavoritePosition, getFavoritePositionsForUser, removeFavoritePosition} from "../net-utils";
import {globalFavoritePositions} from "./NavigationBar";

export const FavoriteIcon = (props: {
  positionId: string,
  css?: SerializedStyles,
  className?: string,
  showText?: boolean,
  isEnabled: boolean
}) => {
  const favorite = useHookstate(props.isEnabled)
  const color = favorite.get() ? 'yellow.400' : 'gray.400'
  const username = getUsername()
  const favoritePositions = useHookstate(globalFavoritePositions)
  async function refreshFavoritePositions() {
    favoritePositions.set(
      await getFavoritePositionsForUser(username)
    )
  }
  useEffect(() => {
    if (props.isEnabled !== favorite.get()) {
      favorite.set(props.isEnabled)
    }
  }, [props.isEnabled]);
  return <div className={props.className} css={css`margin-left: auto; &:hover {cursor: pointer; }`} onClick={async e => {
    const newValue = !favorite.get();
    favorite.set(newValue);
    if (newValue) {
      await addFavoritePosition(username, props.positionId)
    } else {
      await removeFavoritePosition(username, props.positionId)
    }
    await refreshFavoritePositions()
  }}>
    <Tooltip label={'Vista fyrir seinna'} isDisabled={props.showText === true}>
      <div css={css`display: flex; gap: 8px; align-items: center; color: #626c81; &:hover {opacity: 0.8}`}><StarIcon color={color}
      />
        {
          props.showText === true && <span css={css`user-select: none; color: #333333; font-weight: 500`}>Vista fyrir seinna</span>
        }</div>
    </Tooltip>
  </div>;
};