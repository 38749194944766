import React, {useEffect, useState} from 'react';
import {BasePage} from "./components/BasePage";
import {Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {useHookstate} from "@hookstate/core";
import {globalUser} from "./App";
import {DefaultSpinner} from "./components/DefaultSpinner";
import {Invoices} from "./components/InvoicePage";
import {getInvoicesForUser, getPositionsForUser} from "./net-utils";
import {calculatePayForPosition, formatCurrency, formatDate, getUsername} from "./heka-utils";
import {Position} from "./types";
import { useNavigate } from 'react-router-dom';
import {css} from "@emotion/react";
import AlertDisplay from "./components/AlertDisplay";

const UserInvoicesPage = () => {
  const user = useHookstate(globalUser).get()?.user;
  const [invoices, setInvoices] = useState(null as Invoices.Invoice[] | null);
  const [positions, setPositions] = useState(null as Position[] | null);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => setInvoices(await getInvoicesForUser(getUsername())))();
    (async () => setPositions(await getPositionsForUser(getUsername())))();
  }, [user?.username]);
  if (!user || !invoices || !positions) {
    return <DefaultSpinner/>
  }
  const positionToInvoiceMap = new Map<string, Invoices.Invoice>(
    invoices.map(invoice => [invoice.positionId, invoice] as [string, Invoices.Invoice])
  );
  const anyInvalidInvoices = invoices.some(invoice => invoice.invalid);
  return (
    <BasePage>
      <Heading>Rafrænir reikningar</Heading>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Reikningsnr</Th>
              <Th>Starfsstöð</Th>
              <Th>Stofnun</Th>
              <Th>Byrjun</Th>
              <Th>Lok</Th>
              <Th>Greiðsla</Th>
            </Tr>
          </Thead>
          <Tbody>
            {positions.map(position => {
              const invoice = positionToInvoiceMap.get(position._id!!);
              if (!invoice) return null;
              const opacity = invoice.invalid ? 0.5 : 1;
              return <Tr key={position.positionRequestId} css={css`cursor: pointer; opacity: ${opacity};`}
                onClick={() => navigate(`/positions/${position._id}/invoice`)}>
                <Td>{invoice.contractId}</Td>
                <Td>{position.clinicName}</Td>
                <Td>{position.institutionName}</Td>
                <Td>{formatDate(position.beginDate!!)}</Td>
                <Td>{formatDate(position.endDate!!)}</Td>
                <Td>{formatCurrency(calculatePayForPosition(position, user))}</Td>
              </Tr>
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {anyInvalidInvoices && <AlertDisplay status={'info'} error={"Gráir reikningar eru þeir sem þú hefur ógilt"}/>}
    </BasePage>
  );
};

export default UserInvoicesPage;
