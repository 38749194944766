import {WorkerClass} from "./worker-class";
import {UserFreeDate} from "./net-utils";
import {DynamicShiftType} from "./components/CreatePositionPage/ShiftCreationModal";
import {addDays} from "date-fns";

export interface ShiftType {
  checked: boolean
}

export const shiftTypeNames = ['Solar', 'Day', 'Night'] as const
//export type ShiftTypeName = typeof shiftTypeNames[number]
export type ShiftTypeName = string;
export type ShiftTypes = Record<ShiftTypeName, ShiftType>;

export interface Shift {
  date: Date
  // Solar?: ShiftType // sólarhringsvakt
  // Day?: ShiftType // dagvakt
  // Night?: ShiftType // kvöldvakt
  // Call?: ShiftType // bakvakt
  types: string[];
}

export type WorkerClassPays = Partial<Record<WorkerClass.ClassType, number>>;
export type ShiftPays = Partial<Record<ShiftTypeName, WorkerClassPays>>;

export const institutionNames = ["Heilbrigðisstofnun Suðurlands",
    "Heilbrigðisstofnun Norðurlands",
    "Heilbrigðisstofnun Vesturlands",
] as const
export type InstitutionName = typeof institutionNames[number]


export interface ShiftTypeTime {
  begin: string;
  end: string;
}

export type PositionLocationState = {
  from: Date, to: Date, clinic: string, location: string, specialization: string, pay: number, hType: string,
  img: string
} | undefined

export interface PreviousEmployment {
    professionType: WorkerClass.ProfessionType;
    workerClass: WorkerClass.ClassType;
    institution: string;
    position: string;
    department?: string;
    specialization?: string;
    years: number
}

export interface Profession {
    type: WorkerClass.ProfessionType;
    isStudent: boolean;
    isSpecialist: boolean;
    specializations: string[];
    certificationId: string;
    isInResidency: boolean;
    residencyDate?: Date;
}

export interface UserLinks {
    permit: string | undefined
    cv: string | undefined
    picture: string | undefined,
    enrollmentVerification: string | undefined
}

export interface Experiences {
    nursingHomes: number;
    hospitals: number;
    clinics: number;
    specialistReceptions: number;
    homeService: number;
}

export interface PhoneNumber {
    country: string;
    phoneNumber: string;
}

export interface ElectronicID {
    name: string;
    ssn: string;
    phoneNumber: string;
}

export interface UserPreferences {
    email: string;
    phoneNumbers: PhoneNumber[];
    professions: Profession[];
    previousEmployments: PreviousEmployment[];
    links: UserLinks;
    experiences: Experiences;
    emailNotifications: boolean;
    favoriteInstitutions: InstitutionName[];
}

export interface User {
    unverified: boolean;
    preferences: UserPreferences;
    electronicId: ElectronicID;
    isAdmin: boolean;
    username: string;
    createdAt: Date;
    updatedAt: Date;
    certificationId: string,
    freeDates: UserFreeDate[],
    lastCheckedNotifications: Date,

    positionCount?: number,
    positionCancelledCount?: number,
}

interface InstitutionNameAndId {
    name: string;
    id: string;
}

export interface UserResponse {
    user: User;
    institutions: InstitutionNameAndId[];
}


export interface NewUserElectronicId {
    professions: Profession[]
    electronicId: string
    phoneNumber: string
    certificationJwt: string
}

export interface ExistingUserSignIn {
    jwt: string
}

export interface SignupUserResponse {
    jwt: string
}

export interface ExternalError {
    error?: string
}

export interface PaymentInfo {
    ssn: string,
    homeAddress: string,
    name: string,
    postalNumber: string,
    isCompany: boolean,
    salaryClass: string, // launaflokkur
    bankId?: string, // banki
    hb?: string, // höfuðbók
    account?: string, // reikningsnúmer
    type?: 'International' | 'Domestic',
    bicCode?: string,
    iban?: string
}

export interface PositionEmployment {
    positionId: string,
    startDate: Date,
    endDate: Date,
    institutionName: string,
    clinicName: string,
    employmentStatus: 'Scheduled' | 'Active' | 'Completed' | 'Cancelled',
    workerClass: WorkerClass.ClassType[],
    professionType: WorkerClass.ProfessionType,
    specialization: string,
    cancelledAtDate?: Date,
    invoiceId?: string,
    contractId?: string,
}

export interface JwtData {
    unverified: boolean;
    username: string
}

export interface Institution {
    name: string,
    ssn: string,
    nameAbbr: string,
    memberUsers: string[],
    clinics: Clinic[],
    email: string,
    institutionId?: string,
    address: string,
    postalCode: string,
    dynamicShiftTypes: DynamicShiftType[],
    phoneNumber?: string,
    petrolPayment?: number,
    hideOtherInstitutions?: boolean,
}

export interface ClinicMapCoordinates {
    x: number,
    y: number
}

export interface Clinic {
    name: string,
    address: string,
    primaryImagePath: string | undefined,
    images?: string[],
    mapCoordinates: ClinicMapCoordinates | undefined,
    clinicId?: string,
    openPositionCount?: number,
    requestCount?: number,
    description?: string
}

export type HType = 'H1' | 'H2' | 'H3'

export interface ResidencySupplementPay {
    years: number,
    shiftTypePays: Partial<Record<ShiftTypeName, number>>
}

export interface Position {
    description: string,
    institutionId: string,
    clinicId: string,
    professionType: WorkerClass.ProfessionType,
    specialization: string,
    workerClassRequirements: WorkerClass.ClassType[],
    pathId?: string,
    created: Date,
    shifts: Shift[],
    beginDate: Date | null,
    endDate: Date | null,
    shiftTypePays: ShiftPays,
    //totalPay?: number,
    travelCostPaid: boolean,
    travelCost: number,
    travelDayCostPaid: boolean,
    travelDayCost: number,
    accommodationCostPaid: boolean,
    accommodationCost: number,
    insurancePaid: boolean,
    createdBy?: string,
    isPublic: boolean,
    customFirstShiftBeginTime?: string,
    customLastShiftEndTime?: string,
    sendNotificationsToSubscribers: boolean,
    htype: HType,
    isAccepted: boolean,
    _id?: string,
    clinicName?: string,
    clinicImagePath?: string;
    institutionName?: string,
    userRequest?: PositionRequest,
    requestCount?: number,
    positionRequestId?: string,
    cancelled: boolean,
    cancelledAtDate?: Date,
    cancelledReason?: string,
    invoiceSent?: boolean,
    acceptedBy?: string,
    acceptedAt?: Date,
    hasContract: boolean,
    isDeleted?: boolean,
    duplicatedPositionId?: string;
    residencySupplementPay?: ResidencySupplementPay;
    dynamicShiftTypes: DynamicShiftType[];
    travelComment?: string;
    isCollectiveAgreementBased?: boolean; // nurses and midwives
}

export function createDummyPosition(clinicName: string, institutionName: string): Position {
    return {
        description: "Dummy position",
        institutionId: "dummy-institution-id",
        clinicId: "dummy-clinic-id",
        professionType: 'Doctor', // Assuming this enum exists
        specialization: "Almennur læknir",
        workerClassRequirements: ['Student', 'Standard', 'Specialist'], // Assuming this enum exists
        created: new Date(),
        shifts: [],
        beginDate: addDays(new Date(), 7),
        endDate: addDays(new Date(), 14),
        shiftTypePays: {},
        travelCostPaid: false,
        travelCost: 0,
        travelDayCostPaid: false,
        travelDayCost: 0,
        accommodationCostPaid: false,
        accommodationCost: 0,
        insurancePaid: false,
        isPublic: false,
        sendNotificationsToSubscribers: false,
        htype: 'H1',
        isAccepted: false,
        cancelled: false,
        hasContract: false,
        dynamicShiftTypes: [],
        isCollectiveAgreementBased: false,
        clinicName: clinicName,
        institutionName: institutionName
    };
}

export interface ClinicMapMarker {
    positionCount: number,
    data: {
        clinicName: string,
        clinicId: string,
        coordinates: ClinicMapCoordinates
    }
}

// data class CreatePositionRequest(
//     val positionId: String,
//     val shifts: List<Shift>,
//     val message: String
// )
export interface CreatePositionRequest {
    positionId: string,
    shifts: Date[],
    message: string,
    accountNumber: string,
}

export interface PositionRequest {
    request: CreatePositionRequest,
    username: string,
    institutionId: string,
    clinicId: string,
    _id: string,
    userData?: UserPositionRequestData,
    status?: 'Pending' | 'Accepted' | 'Rejected',
    rejectionMessage?: string,
}

export interface UserPositionRequestData {
    username: string,
    name: string,
    specialization: string,
    imagePath: string,
    numCompletions: number,
    workerClasses: string[]
}

export interface ContractInformation {
    institution: Institution,
    position: Position,
    request: PositionRequest,
    user: User,
    paymentInfos: PaymentInfo[],
    contractId: string
}

export interface ContractSigner {
    name: string,
    ssn: string,
    email: string,
    username: string,
}

export interface SignetAddDocumentResult {
    GroupID: string,
    DocIDs: string[]
}

export interface Contract {
    contractId: string,
    institutionId: string,
    positionId: string,
    positionRequestId: string,
    institutionSigner: ContractSigner,
    employeeSigner: ContractSigner,
    markdown: string,
    createdAt: Date,
    updatedAt: Date,
    signetDocumentData: SignetAddDocumentResult,
    contractName: string,
    clinicId: string,
    isInvalid?: boolean
}

export interface NotificationMessage {
    _id: string,
    createdAt: Date,
    username: string,
    eventType: 'PositionAccepted' | 'PositionCancelled' | 'NewPositionAlert' | 'PositionUpdated' | 'PositionCancelledByInstitution'
    event: NotificationMessageEvent,
}

export interface PositionAccepted {
    positionId: string,
    positionRequestId: string,
    institutionId: string,
    clinicId: string,
    institutionName: string,
    clinicName: string,
    beginDate: Date,
    endDate: Date,
}

export interface PositionCancelled {
    employeeUsername: string,
    employeeName: string,
    positionId: string,
    clinicName: string,
    clinicId: string,
    institutionName: string,
    institutionId: string,
    beginDate: Date,
    endDate: Date,
}

export interface NewPositionAlert {
    positionId: string,
    institutionName: string,
    clinicName: string,
    beginDate: Date,
    endDate: Date,
}

export type PositionUpdated = NewPositionAlert
export type PositionCancelledByInstitution = NewPositionAlert

export type NotificationMessageEvent = PositionAccepted | PositionCancelled | NewPositionAlert | PositionUpdated | PositionCancelledByInstitution