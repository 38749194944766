import {groupBy, partition} from 'lodash';
import {DayOfWeek, daysOfWeek} from "./heka-utils";
import {ShiftTime} from "./components/CreatePositionPage/ShiftCreationModal";
import {v4 as uuidv4} from "uuid";
import _ from 'lodash';
import {deepEqual} from "assert";

const shiftTimes: ShiftTime[] = [
  {
    id: uuidv4(),
    day: "Sunday",
    startTime: "14:00",
    endTime: "20:00",
  },
  {
    id: uuidv4(),
    day: "Monday",
    startTime: "14:00",
    endTime: "20:00",
  },
  {
    id: uuidv4(),
    day: "Tuesday",
    startTime: "08:00",
    endTime: "16:00",
  },
  {
    id: uuidv4(),
    day: "Wednesday",
    startTime: "08:00",
    endTime: "16:00",
  },
  {
    id: uuidv4(),
    day: "Thursday",
    startTime: "08:00",
    endTime: "16:00",
  },
  {
    id: uuidv4(),
    day: "Friday",
    startTime: "10:00",
    endTime: "16:00",
  },
  {
    id: uuidv4(),
    day: "Saturday",
    startTime: "14:00",
    endTime: "20:00",
  }
]

// Group shift times by startTime and endTime
function groupByTime(shiftTimes: ShiftTime[]): _.Dictionary<ShiftTime[]> {
  return _.groupBy(shiftTimes, (shiftTime) => `${shiftTime.startTime}-${shiftTime.endTime}`);
}

// Compare adjacent shift times
function areAdjacentDays(day1: DayOfWeek, day2: DayOfWeek): boolean {
  const dayIndex1 = daysOfWeek.indexOf(day1);
  const dayIndex2 = daysOfWeek.indexOf(day2);

  return Math.abs(dayIndex1 - dayIndex2) === 1 || Math.abs(dayIndex1 - dayIndex2) === 6;
}

// Group together adjacent shift times with the same startTime and endTime
export function groupAdjacentShiftTimes(shiftTimes: ShiftTime[]): ShiftTime[][] {
  const groupedByTime = groupByTime(shiftTimes);

  const value = _.flatMap(_.values(groupedByTime), (group) => {
    const sortedGroup = _.sortBy(group, (shiftTime) => daysOfWeek.indexOf(shiftTime.day));
    const adjacentShiftTimesGroups: ShiftTime[][] = [];

    let currentGroup: ShiftTime[] = [sortedGroup[0]];

    for (let i = 1; i < sortedGroup.length; i++) {
      if (areAdjacentDays(sortedGroup[i - 1].day, sortedGroup[i].day) ||
        i === sortedGroup.length - 1 && areAdjacentDays(sortedGroup[i - 1].day, sortedGroup[0].day)) {
        currentGroup.push(sortedGroup[i]);
      } else {
        adjacentShiftTimesGroups.push(currentGroup);
        currentGroup = [sortedGroup[i]];
      }
    }

    adjacentShiftTimesGroups.push(currentGroup);
    return adjacentShiftTimesGroups;
  });
  return value;
}

export function formatShiftTimesToString(group: ShiftTime[], t2: (s: (string | undefined)) => string) {
  const firstShift = _.head(group);
  const lastShift = _.last(group);

  const firstDay = firstShift?.day;
  const lastDay = lastShift?.day;
  const shiftRange = group.length > 1
    ? `${t2(firstDay)}-${t2(lastDay)}`
    : `${t2(firstDay)}`;

  return `${shiftRange}: ${firstShift?.startTime}-${lastShift?.endTime}`;
}

function formatShifts(shifts: ShiftTime[][], t: (s: string) => string): string {
  const t2 = (s: string | undefined) => t(s!!).substring(0,3)
  return shifts.map((group) => formatShiftTimesToString(group, t2)).join(' ');
}

export function groupShiftTimes(shiftTimes: ShiftTime[], t: (s: string) => string): string {
  const shifts = groupAdjacentShiftTimes(shiftTimes);
  if (shifts.length === 1 && shifts[0].length === 7) {
    // All days
    return `${shifts[0][0].startTime}-${shifts[0][0].endTime} (Alla vinnudaga)`;
  }
  return formatShifts(shifts, t);
}
