import {State} from "@hookstate/core";
import {Alert, AlertIcon, AlertStatus, Text} from "@chakra-ui/react";
import React from "react";

function AlertDisplay(props: { error?: State<string | null> | string, status?: AlertStatus, children?: React.ReactNode }) {
  const error = typeof props.error === 'string' ?
    props.error : props.error?.get()
  if (!error && !props.children) {
    return null;
  }
  return <Alert status={props.status ?? "error"}>
    <AlertIcon/>
    <Text>{error ?? props.children}</Text>
  </Alert>
}

export default AlertDisplay