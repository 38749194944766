import React, {useEffect} from 'react';
import {ElectronicIdForm} from "./SignupElectronicIDPage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createInstitutionAdminUser} from "../net-utils";
import {parseInstitutionInviteJwt, setUserJwt} from "../heka-utils";

const InviteAdminPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, []);
  if (!token) {
    return null;
  }
  async function submit(phoneNumber: string) {
    if (!token) {
      return;
    }
    const jwt = await createInstitutionAdminUser(phoneNumber, token);

    setUserJwt(jwt);
    const inviteData = parseInstitutionInviteJwt(token)
    navigate(`/institutions/${inviteData.institutionId}`);
  }
  return (
    <ElectronicIdForm submit={submit}/>
  );
};

export default InviteAdminPage;
