import React, {useEffect, useState} from 'react';
import { BasePage } from './BasePage';
import {
  Button,
  Heading,
  Modal,
  ModalBody, ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, Tooltip, useToken
} from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import isLocale from '@fullcalendar/core/locales/is';
import {getDayNameShort, getUsername, translateFullCalendarMonth} from '../heka-utils';
import { css } from '@emotion/react';
import { AddIcon } from '@chakra-ui/icons';
import { State, useHookstate } from '@hookstate/core';
import { DatePicker } from './DatePicker';
import {Position, PositionRequest} from "../types";
import {DefaultSpinner} from "./DefaultSpinner";
import {
  addUserFreeDate,
  deleteUserFreeDate,
  getPositionsAndRequestsForUser,
  getPositionsForUser, PositionAndRequest,
  UserFreeDate
} from "../net-utils";
import {EventInput} from "@fullcalendar/core";
import {addDays, format} from "date-fns";
import {useNavigate} from "react-router-dom";
import {globalUser, refreshUser} from "../App";
import {useTranslation} from "react-i18next";


export const UserCalendarPage = () => {
  const addFreeDateModalOpen = useHookstate(false);
  const deleteFreeDateModalOpen = useHookstate(false);
  const deleteFreeDateId = useHookstate<string | null>(null);
  const [positionsAndRequests, setPositionsAndRequests] = useState(null as PositionAndRequest[] | null);
  const [pink, green, yellow] = useToken('colors', ['pink.700', 'green.400', 'orange.500']);
  const navigate = useNavigate();
  const user = useHookstate(globalUser).get();
  const {t} = useTranslation();
  useEffect(() => {
    (async () => {
      setPositionsAndRequests(
        await getPositionsAndRequestsForUser(getUsername())
      )
    })();
  }, []);
  useEffect(() => {
    translateFullCalendarMonth(t);
  }, [positionsAndRequests]);
  if (!positionsAndRequests) {
    return <DefaultSpinner/>
  }
  const positions = positionsAndRequests.filter(pr => pr.request.status === 'Accepted').map(p => p.position);
  const requests = positionsAndRequests.filter(pr => pr.request.status === 'Pending').map(p => p.position);
  const fmtDate = (date: Date) => format(date, 'yyyy-MM-dd')
  const positionEvents: EventInput[] = positions.map(p => {
    return {
      events: [
        {
          id: p._id!!,
          title: `${p.clinicName!!}, ${p.institutionName!!}`,
          start: fmtDate(p.beginDate!!),
          end: fmtDate(addDays(p.endDate!!, 1))
        }
      ],
      color: pink
    };
  });
  const freeDateEvents: EventInput[] | undefined = user?.user.freeDates.map((fd, i) => {
    return {
      events: [
        {
          id: 'FreeDate.' + fd._id!!,
          title: 'Óskatímabil',
          start: fmtDate(fd.fromDate!!),
          end: fmtDate(addDays(fd.toDate!!, 1))
        }
      ],
      color: green
    };
  });
  const requestsEvents: EventInput[] = requests.map(r => {
    return {
      events: [
        {
          id: r._id!!,
          title: `Umsókn, ${r.clinicName}`,
          start: fmtDate(r.beginDate!!),
          end: fmtDate(addDays(r.endDate!!, 1))
        }
      ],
      color: yellow
    };
  });
  const events = freeDateEvents ? [...positionEvents, ...freeDateEvents, ...requestsEvents] : positionEvents;
  return <BasePage>
    <div css={css`display: flex; align-items: center; gap: 8px; margin-bottom: 8px`}>
      <Heading>Dagatal</Heading>
      <div css={css`margin-left: auto`}>
        <Tooltip
          label={'Hér getur þú valið tímabil sem þú óskar eftir að vinna. Þú færð tilkynningu þegar auglýsing birtist á því tímabili'}>
          <Button colorScheme={'green'} leftIcon={<AddIcon/>} onClick={() => addFreeDateModalOpen.set(true)}>
            Óskatímabil
          </Button>
        </Tooltip>
      </div>
    </div>
    <div css={css`display: flex; gap: 16px; flex-wrap: wrap;`}>
      <LegendItem color={green} text={'Óskatímabil'}/>
      <LegendItem color={pink} text={'Samþykkt staða'}/>
      <LegendItem color={yellow} text={'Staða sótt um'}/>
    </div>
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      locale={isLocale}
      dayHeaderContent={hookProps => {
        return getDayNameShort(hookProps.date)
      }}
      eventSources={events}
      eventClick={event => {
        if (event.event.id.startsWith('FreeDate')) {
          const id = event.event.id.split('.')[1];
          deleteFreeDateId.set(id);
          deleteFreeDateModalOpen.set(true);
          return
        }
        navigate(`/positions/${event.event.id}`)
      }}
      datesSet={() => translateFullCalendarMonth(t)}
    />
    <AddFreeDateRangeModal open={addFreeDateModalOpen}/>
    <DeleteFreeDateRangeModal open={deleteFreeDateModalOpen} freeDateId={deleteFreeDateId.get()}/>
  </BasePage>;
};

export function LegendItem(props: { color: string, text: string }) {
  return <div css={css`display: flex; align-items: center; gap: 8px; margin-bottom: 8px`}>
    <div css={css`width: 16px; height: 16px; background-color: ${props.color}; border-radius: 4px`}/>
    <Text>{props.text}</Text>
  </div>
}

function AddFreeDateRangeModal(props: { open: State<boolean> }) {
  const onClose = () => props.open.set(false);
  const fromDate = useHookstate<Date | null>(null);
  const toDate = useHookstate<Date | null>(null);
  return <Modal isOpen={props.open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Bæta við óskatímabili</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Text>
          Hér getur þú valið tímabil sem þú óskar eftir að vinna. Þú færð tilkynningu þegar auglýsing birtist á því tímabili.
        </Text>
      </ModalBody>
      <ModalBody css={css`display: flex; gap: 8px`}>
        <DatePicker onChange={
          date => {fromDate.set(date);}
        } selected={fromDate.get()} placeholderText={'Frá'} minDate={new Date()}/>
        <DatePicker onChange={date => toDate.set(date)} selected={toDate.get()} placeholderText={'Til'}
          minDate={fromDate.get() ?? new Date()}/>
      </ModalBody>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <Button colorScheme={'green'} isDisabled={
          !fromDate.get() || !toDate.get()
        } onClick={async () => {
          if (!fromDate.get() || !toDate.get()) {
            return;
          }
          await addUserFreeDate({
            fromDate: fromDate.get()!!,
            toDate: toDate.get()!!,
            username: getUsername()!!
          })
          onClose();
          await refreshUser();
        }}>Bæta við</Button>
        <Button onClick={onClose}>Sleppa</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

function DeleteFreeDateRangeModal(props: { open: State<boolean>, freeDateId: string | null }) {
  const onClose = () => props.open.set(false);
  return <Modal isOpen={props.open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Ertu viss um að þú viljir eyða þessu óskatímabili?</ModalHeader>
      <ModalCloseButton/>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <Button colorScheme={'red'} onClick={async () => {
          if (!props.freeDateId) {
            return;
          }
          await deleteUserFreeDate(getUsername(), props.freeDateId)
          onClose();
          await refreshUser();
        }}>Eyða</Button>
        <Button onClick={onClose}>Sleppa</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}