import {Position, PositionRequest, User} from "./types";
import {findAppropriatePayment, formatDate, getHighestWorkerClass} from "./heka-utils";
import {groupShiftTimes} from "./shift-time-range";
import {isSameDay} from "date-fns";

export function createContractText(
  position: Position,
  request: PositionRequest,
  user: User,
  t: (key: string | undefined) => string
) {
  return `#### Verktakasamningur um læknisstörf

* **Samningsaðilar**

{{institutionName:}}, kennitala: {{institutionSSN:}}, sem verkkaupi, hér eftir nefnd {{institutionAbbr:}} og {{employeeName:}}, kennitala: {{employeeSSN:}}, læknanúmer: {{employeeCertificationNumber:}} sem verksali, gera með sér samning um að verksali tekur að sér að útvega sinn starfsmann, lækni, með fullnægjandi menntun og hæfni að mati verkkaupanda og hann samþykkir hverju sinni til starfa fyrir stofnunina hér eftir nefndur, læknir.

* **Verkkaup**
    * Undirritaður læknir tekur að sér að sinna læknisstörfum hjá {{institutionName:}} og verður aðalstarfsstöð {{clinicName:}} tímabilið {{positionBeginDate:}} – {{positionEndDate:}} samkvæmt neðangreindu skipulagi:
${position.shifts.filter(shift => request.request.shifts.some(day => isSameDay(day, shift.date))).map(shift => 
    `      * ${formatDate(shift.date)} - ${shift.types.join(', ')}`).join('\n')}
    * Undirritaður læknir hefur kynnt sér gildandi vaktskrá lækna hjá viðkomandi heilsugæslu og þær skyldur sem starfinu fylgja og starfar samkvæmt því. Vaktskráin og verklýsing fylgja samningi þessum og teljast hluti af honum.
    * Læknir sinnir læknisstörfum á sama hátt og um almenna ráðningu væri að ræða, að því frátöldu að í stað launagreiðslna samkvæmt kjarasamningi Læknafélags Íslands skal taka mið af föstum greiðslum án orlofs, hvíldarákvæðis, veikindaréttar, réttar til námsfría og annarra þeirra réttinda sem kjarasamningur gerir ráð fyrir.
    ${position.insurancePaid ? `* Við undirritun samnings tekur {{institutionName:}} húsbóndaábyrgð á lækninum, þ.e. ábyrgð gagnvart notendnum þjónustu, á sama hátt og um almennan launamann sé að ræða. Það felur í sér að læknir þarf ekki að kaupa sérstaka starfsábyrgðartryggingu vegna starfa við {{institutionName:}}.` : ``}
    * Vinnan getur falið í sér tímabundnar bakvaktir fyrir önnur starfssvæði {{institutionName:}} og/eða að sinna bráðatilfellum utan starfssvæðis tilgreindrar aðalstarfsstöðvar.
    * Læknir skal virða þagnarskyldu líkt og kemur fram í 18. og 21. gr. laga um réttindi og skyldur starfsmanna ríkisins nr. 70/1996. Brot gegn persónuverndarhagsmunum einstaklinga skal kæra til lögreglu samkvæmt 22. gr laga um sjúkraskrár nr. 55/2009.
* **Kjör**
    * Fyrir hverja staðna vakt/vinnudag læknis eru kjör sem hér segir:
${generateShiftContractText(position, user, t)}
    * Greiðslan er fullnaðargreiðsla launa, þar með talin greiðsla vegna gjaldskrárverka og vottorða, launatengdra gjalda, annarra launaliða, námsleyfi, orlof, orlofsuppbót og annars þess er tilheyrir störfum læknis fyrir stofnunina og greiðist samkvæmt áritaðri vinnuskýrslu og framlögðum reikningi.
    * Allar greiðslur sjúklinga vegna læknisverka skulu samkvæmt þessum samningi renna til {{institutionName:}}, er þá átt við greiðslur vegna stofuviðtala, hverskyns aðgerða, vitjana og vottorða af öllum gerðum. Læknir greiðir sjálfur öll opinber gjöld s.s. skatta, launatengd gjöld, þ.m.t. tryggingagjald, mótframlag í lífeyrissjóð, orlof nema slysatryggingar í starfi læknis.
    ${position.travelDayCostPaid ? `* Fyrir komu- og brottfarardag greiðist samtals {{1 dagur}} í ígildi dagvinnutaxta ef lokist a.m.k. sólarhringur, þ.e. læknir kemur að kvöldi komudags og fer að kvöldi brottfarardags.` : ''}
    * {{institutionName:}} greiðir ferðakostnað læknis til og frá starfsstöð og er miðað við flugfargjald á næsta flugvöll hverjum tíma, samkvæmt samningi við flugfélag hverju sinni. Ef læknir sér sjálfur fyrir fari, fær hann samsvarandi upphæð greidda sem ferðastyrk, {{travelCost:}} kr.
    * Í þeim tilvikum þegar stofnun útvegar lækni húsnæði, ef um skemmri afleysingu er að ræða ({{accommodationDayCount:0}}) dagar er gjald fyrir hverja nótt {{accommodationCost:0}} krónur samkvæmt útgefnum reikningi frá stofnunni. Ef um lengri dvöl er að ræða er gerður húsaleigusamningur er miðast við þarfir verksala um húsnæði og starfstíma.
    * Komi upp á samningstíma læknis að verkkaupandi telji lækni sýna af sér faglega vanhæfni eða óásættanlega framkomu getur verkkaupandi rift samningi þessum fyrirvaralaust.
    * Sé vinnuaðstaða og/eða aðbúnaður hjá verkkaupanda í óásættanlegu ástandi og ekki í samræmi við almennar venjur getur læknir/verksali rift samningi án fyrirvara.
    * Ef verktaki er í húsnæði á vegum {{institutionAbbr:}} skal viðkomandi skila húsnæðinu í því ástandi sem hann tók við því í. Ellegar áskilur {{institutionAbbr:}} sér rétt til að senda rukkun til verktaka fyrir þrifum að upphæð {{20.000 kr.}} 
* **Uppgjör**
    * Verkgreiðslur greiðast einungis samkvæmt framlögðum rafrænum reikningi verksala sem samþykktur skal af næsta yfirmanni og er þetta í samræmi við skilmála Fjársýslu ríkisins. Gjaldfrestur er að lágmarki 25 dagar frá afhendingardegi reiknings hjá skeytamiðlara og skal eindagi vera sá sami og gjalddagi.

`
}

export function createContractTextHVE(
  position: Position,
  request: PositionRequest,
  user: User,
  t: (key: string | undefined) => string
) {
  return `
#### Samningur um læknisþjónustu á heilsugæslustöð Heilbrigðisstofnunar Vesturlands {{clinicName:}}.

Heilbrigðisstofnun Vesturlands, kt. 630909-0740, hér eftir nefnt HVE og {{employeeName:}}, kt. {{employeeSSN:}}, læknanúmer {{employeeCertificationNumber:}}, hér eftir nefndur læknir gera með sér eftirfarandi samning um heilbrigðisþjónustu á heilsugæslustöðinni í {{clinicName:}}. 

* Læknir verður í {{:100}}% stöðugildi í samkvæmt neðangreindu skipulagi.
* Tímabilið frá {{positionBeginDate:}} til {{positionEndDate:}}
* Vinnutími er samkvæmt almennum skilgreindum dagvinnutíma, jafnan unnin á bilinu 08.00 til 16.00 eða 09.00 til 17.00 samkvæmt samráði við yfirmenn og yfirstjórn HVE.  Það á við um þá daga sem við á samkvæmt vaktskema frá yfirlækni hverju sinni.  Læknir á vakt þarf alltaf að vera mættur kl. 08.00 að morgni nema um annað sé samið hverju sinni.  Almennt viðmið á móttöku hvers læknis er um {{:14}} sjúklingar og um {{:12}} símtöl á dag.

Læknir munu taka upp rafræna viðveruskráningu þegar þess verður óskað.

Læknir á heilsugæslustöðinni sinnir {{:Fellaskjóli, hjúkrunar- og dvalarheimili}} í samráði við yfirstjórn HVE hverju sinni og ef þurfa þykir.

Læknir (vakthafandi læknir) er á útkallsvakt alla daga sem vaktskema gerir ráð fyrir.  Vaktin felur í sér að fylgja sjúkraflutningamönnum í útköll vegna sjúkdóma eða slysa á starfssvæði heilsugæslunnar ef þörf krefur, vitjanir í heimahús eða heilsugæslustöð, á {{:Fellaskjól}} og á lögreglustöð vegna blóðsýna og þess háttar,  ásamt þeim verkefnum sem jafnan heyra undir bæjarvaktir heilsugæslulækna á landsbyggðinni.  Vakhafandi læknir í Ólafssvík sinnir heilsugæslunni á {{clinicName:}} um helgar, frá kl. 16 á föstudegi til kl. 8:00 á mánudag. Vakthafandi læknir hefur aðgang að bíl í eigu HVE á vaktinni.

Greiðslur samkvæmt grein 2 í samningi þessa eru eftirfarandi:

* Fyrir móttöku almennan virkan vinnudag nemur greiðslan kr. {{:105.000}},- almennur lækni.
* Fyrir móttöku almennan virkan vinnudag nemur greiðslan kr. {{:115.000}},- sérfræðilæknir.
* Fyrir vakt utan dagvinnutíma á virkum dögum  nemur greiðslan kr. {{:100.000}},- almennur læknir.
* Fyrir vakt utan dagvinnutíma á virkum dögum  nemur greiðslan kr. {{:105.000}},- sérfræðilæknir.
* Fyrir stórhátíðir eru greiddar aukalega kr. {{:45.000}},- pr. sólarhring. 
* Greitt fyrir akstur, kr. {{:25.000}},-

* Innifalið í ofangreindum greiðslum, sem læknir er við verktakastörf er:  Almennar daggreiðslur, vaktagreiðslur, námsleyfi, orlof, orlofsuppbót, veikindaréttur og tryggingagjald, lífeyrissjóðsgjöld og opinber gjöld.  Allar greiðslur sjúklinga vegna læknisverka skulu samkvæmt þessum samningi renna óskipt til HVE.  Þar er átt við greiðslu fyrir stofuviðtöl, hvers kyns aðgerðir, vitjanir og vottorð af öllum gerðum.   Undanskilin eru vottorð til lögmanna, sýslumanna og tryggingafélaga. Þau vottorð rukkar læknirinn sjálfur til viðkomandi aðila.
* Greiðslur vegna samnings þessa skulu inntar af hendi í fyrstu viku hvers mánaðar inn á reikninga þeirra lækna, sem starfað hafa í verktöku mánuðinn áður.  Einungis skal senda reikning fyrir daga í þeim mánuði sem unnið er í verktöku.  Ef vinnuvika skarast milli mánaða verður reikningur að vera í tvennu lagi – einn reikningur fyrir hvorn mánuðinn.  Reikningur skal vera rafrænn fyrir bókhald HVE.
* Læknir sem sinnir verktöku fyrir HVE á þessum samningi þarf ekki að kaupa sér sérstaka sjúklingatryggingu vegna starfa sinna.  HVE ber ábyrgð gagnvart sjúklingi á sama hátt og ef verktaki væri launþegi, og á enga endurkröfu á verktaka vegna hugsanlegra bótagreiðslna til sjúklings.
* Vakthafandi læknir hefur til umráða íbúð hjá HVE endurgjaldslaust með venjulegu innbúi, nettengingu og sjónvarpi.  Læknir hefur aðgang að mötuneyti greiðir sama verð og starfsmenn HVE fyrir fæði hverju sinni.
* Gildistími samnings þessa er í samræmi við tilgreind tímabil í grein 1. 
`;
}

export function createContractTextHveOlafsvik(
  position: Position,
  request: PositionRequest,
  user: User,
  t: (key: string | undefined) => string
) {
  return `
## Samningur um læknisþjónustu á heilsugæslustöð Heilbrigðisstofnunar Vesturlands Ólafsvík.

Heilbrigðisstofnun Vesturlands, kt. {{institutionSSN:}}, hér eftir nefnt HVE og nafn læknis, kt. {{employeeSSN:}}, læknanúmer {{employeeCertificationNumber:}}, hér eftir nefndur læknir gera með sér eftirfarandi samning um heilbrigðisþjónustu á heilsugæslustöðinni í Ólafsvík.

- Læknir verður í 100% stöðugildi í samkvæmt neðangreindu skipulagi.
- Tímabilið frá {{positionBeginDate:}} til {{positionEndDate:}}
- Vinnutími er samkvæmt almennum skilgreindum dagvinnutíma, jafnan unnin á bilinu 08.00 til 16.00 eða 09.00 til 17.00 samkvæmt samráði við yfirmenn og yfirstjórn HVE.  Það á við um þá daga sem við á samkvæmt vaktskema frá yfirlækni hverju sinni.  Læknir á vakt þarf alltaf að vera mættur kl. 08.00 að morgni nema um annað sé samið hverju sinni.  Almennt viðmið á móttöku hvers læknis er um 14 sjúklingar og um 12 símtöl á dag.

Læknir munu taka upp rafræna viðveruskráningu þegar þess verður óskað.

Einn læknir á heilsugæslustöðinni sinnir Jaðar hjúkrunar- og dvalarheimili í samráði við yfirlækni ef þurfa þykir.

Læknir (vakthafandi læknir) er á útkallsvakt alla daga sem vaktskema gerir ráð fyrir.  Vakhafandi læknir sinnir heilsugæslunni á Grundarfirði um helgar. Vaktin felur í sér að fylgja sjúkraflutningamönnum í útköll vegna sjúkdóma eða slysa á starfssvæði heilsugæslunnar ef þörf krefur, vitjanir í heimahús eða heilsugæslustöð, á Jaðar (Fellaskjól um helgar) og á lögreglustöð vegna blóðsýna og þess háttar,  ásamt þeim verkefnum sem jafnan heyra undir bæjarvaktir heilsugæslulækna á landsbyggðinni.  Vakthafandi læknir hefur aðgang að bíl í eigu HVE á vaktinni.

- Greiðslur samkvæmt grein 2 í samningi þessa eru eftirfarandi:

- Fyrir móttöku almennan virkan vinnudag nemur greiðslan kr. {{:115.000}},- almennur lækni.
- Fyrir móttöku almennan virkan vinnudag nemur greiðslan kr. {{:125.000}},- sérfræðilæknir.
- Fyrir vakt utan dagvinnutíma á virkum dögum nemur greiðslan kr. {{:105.000}},- almennur læknir.
- Fyrir vakt utan dagvinnutíma á virkum dögum nemur greiðslan kr. {{:110.000}},- sérfræðilæknir.
- Fyrir helgarvakt nemur greiðslan {{:235.000}},- pr. sólarhring.
- Fyrir stórhátíðir eru greiddar aukalega kr. {{:50.000}},- pr. sólarhring.
- Greitt fyrir akstur, kr. {{:25.000}},-
- Innifalið í ofangreindum greiðslum, sem læknir er við verktakastörf er: Almennar daggreiðslur, vaktagreiðslur, námsleyfi, orlof, orlofsuppbót, veikindaréttur og tryggingagjald, lífeyrissjóðsgjöld og opinber gjöld. Allar greiðslur sjúklinga vegna læknisverka skulu samkvæmt þessum samningi renna óskipt til HVE. Þar er átt við greiðslu fyrir stofuviðtöl, hvers kyns aðgerðir, vitjanir og vottorð af öllum gerðum. Undanskilin eru vottorð til lögmanna, sýslumanna og tryggingafélaga. Þau vottorð rukkar læknirinn sjálfur til viðkomandi aðila.
- Greiðslur vegna samnings þessa skulu inntar af hendi í fyrstu viku hvers mánaðar inn á reikninga þeirra lækna, sem starfað hafa í verktöku mánuðinn áður. Einungis skal senda reikning fyrir daga í þeim mánuði sem unnið er í verktöku. Ef vinnuvika skarast milli mánaða verður reikningur að vera í tvennu lagi – einn reikningur fyrir hvorn mánuðinn. Reikningur skal vera rafrænn fyrir bókhald HVE.
- Læknir sem sinnir verktöku fyrir HVE á þessum samningi þarf ekki að kaupa sér sérstaka sjúklingatryggingu vegna starfa sinna. HVE ber ábyrgð gagnvart sjúklingi á sama hátt og ef verktaki væri launþegi, og á enga endurkröfu á verktaka vegna hugsanlegra bótagreiðslna til sjúklings.
- Vakthafandi læknir hefur til umráða íbúð hjá HVE endurgjaldslaust með venjulegu innbúi, nettengingu og sjónvarpi. Læknir hefur aðgang að mötuneyti greiðir sama verð og starfsmenn HVE fyrir fæði hverju sinni.
- Gildistími samnings þessa er í samræmi við tilgreind tímabil í grein 1.
`
}

function generateShiftContractText(position: Position, user: User, t: (s: string | undefined) => string): string {
  const highestWorkerClass = getHighestWorkerClass(user, position.professionType)
  return position.dynamicShiftTypes.map((shiftType) => {
    const pays = position.shiftTypePays[shiftType.shiftName];
    if (!pays) {
      throw new Error(`No pays for shift type ${shiftType.shiftName}`);
    }
    const payment = findAppropriatePayment(pays, highestWorkerClass);
    const timesFormatted = groupShiftTimes(shiftType.shiftTimes, t);
    return `      * Fyrir hverja vakt af taginu ${shiftType.shiftName} (${timesFormatted}): {{shiftPays.${shiftType.shiftName}:${payment}}} kr.`;
  }).join("\n");
}