import {State, useHookstate} from "@hookstate/core";
import {Clinic, Institution, Position, Shift, ShiftTypeName} from '../types';
import {css} from "@emotion/react";
import {Badge, Button, Checkbox, Heading, Menu, MenuButton, MenuItem, MenuList, position, Text} from "@chakra-ui/react";
import {
  DATE_FMT, daysOfWeek,
  getDayNameShort,
  getDaysInRange,
} from '../heka-utils';
import {format, isSameDay} from "date-fns";
import React, {useEffect, useState} from "react";
import {BorderBox} from "./BorderBox";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {clone, pull, uniq, without} from "lodash";
import {DynamicShiftType} from "./CreatePositionPage/ShiftCreationModal";
import {DynamicShiftsModal} from "./CreatePositionPage/DynamicShiftsModal";
import { WorkerClass } from "../worker-class";

const DayShiftCheckbox = (props: {label: string, colorScheme: string,
  shifts: State<ShiftTypeName[]>, disabled?: boolean, value: ShiftTypeName
}) => {
  const state = useHookstate(props.shifts)
  return <div css={css`display: flex; gap: 2px; flex-direction: column`}>
    <Checkbox size={'md'} colorScheme={props.colorScheme} onChange={e => {
      const checked = e.target.checked;
      if (checked) {
        state.set(Array.from(new Set([...state.get(), props.value])))
      } else {
        state.set(state.get().filter(v => v !== props.value))
      }
    }} disabled={props.disabled} isChecked={
        state.get().includes(props.value)
    }>
      <Badge colorScheme={props.colorScheme} size={'xs'}>{props.label}</Badge>
    </Checkbox>

  </div>;
};

function isDateWeekend(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

function isDateInDynamicShiftType(date: Date, dynamicShiftType: DynamicShiftType) {
  const dayOfWeek = date.getDay();
  return dynamicShiftType.shiftTimes.flatMap(d => d.day).includes(daysOfWeek[dayOfWeek]);
}

const DayShiftOptions = (props: {shift: State<Shift>, institution: Institution, clinic: Clinic, position: Position}) => {
  const date = props.shift.date.get()
  const state = useHookstate<Shift>(props.shift)
  const shifts = state.types
  return <div css={css`display: flex;
    flex-direction: column`}>
    <div css={css`display: flex; align-items: center; gap: 8px`}>
      <Heading fontSize={'md'} css={css`text-transform: uppercase`}>{getDayNameShort(date)}</Heading>
      <Text css={css`font-style: italic`} color={'gray'}>{format(date, "dd/MM")}</Text>
    </div>
    {/*{isDateWeekend(date) ? <DayShiftCheckbox colorScheme={'red'} value={'Night'} label={'Gæsluvakt (helgi)'} shifts={shifts}*/}
    {/*/> : <>*/}
    {/*  <DayShiftCheckbox colorScheme={'yellow'} value={'Day'} label={'Dagvinna'} shifts={shifts}/>*/}
    {/*  <DayShiftCheckbox colorScheme={'green'} value={'Solar'} label={'Gæsluvakt (virkur dagur)'}*/}
    {/*  shifts={shifts}/>*/}
    {/*</>}*/}
    {getDynamicShiftsForClinic(props.institution, props.clinic, props.position.professionType).filter(type => isDateInDynamicShiftType(date, type))
      .map(type => <DayShiftCheckbox label={type.shiftName} colorScheme={type.color} shifts={shifts}
      value={type.shiftName}/>)}
  </div>;
};

function getDynamicShiftsForClinic(institution: Institution, clinic: Clinic, professionType: WorkerClass.ProfessionType) {
  return institution.dynamicShiftTypes.filter(
    type => (type.clinicIds === null || type.clinicIds.includes(clinic.clinicId!!)) && type.professionType === professionType
  )
}

const QuickActions = (props: {shifts: State<Shift[]>, institution: Institution, clinic: Clinic, position: Position}) => {
  function toggleAllShiftsByType(type: DynamicShiftType) {
    if (props.shifts.get().every(shift => shift.types.includes(type.shiftName))) {
      // Remove all shifts of this type
      props.shifts.forEach(shift => {
        shift.types.set(without(shift.types.get(), type.shiftName))
      })
      return;
    }
    props.shifts.forEach(s => {
      if (isDateInDynamicShiftType(s.date.get(), type)) {
        s.types.set(uniq([...s.types.get(), type.shiftName]))
      }
    });
  }
  return <div>
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
        Velja fyrir allt
      </MenuButton>
      <MenuList>
        {getDynamicShiftsForClinic(props.institution, props.clinic, props.position.professionType).map(type => <MenuItem
          onClick={() => toggleAllShiftsByType(type)}>{type.shiftName}</MenuItem>)}
      </MenuList>
    </Menu>
  </div>;
};

export const DayShifts = (props: {
  institutionState: State<Institution>,
  clinic: Clinic,
  position: State<Position>
}) => {
  const institution = props.institutionState.get()
  const state = useHookstate(props.position.shifts)
  const [beginDate, setBeginDate] = [props.position.beginDate.get(), props.position.endDate.set]
  const [endDate, setEndDate] = [props.position.endDate.get(), props.position.endDate.set]
  const daysInRange = beginDate && endDate && getDaysInRange(beginDate, endDate);
  const shiftCreationModalOpen = useHookstate(false);
  useEffect(() => {
    if (daysInRange && state.length !== daysInRange.length) {
      state.set(daysInRange.map(date => {
        const existing = state.get().find(s => isSameDay(s.date, date));
        const types = existing ? clone(existing.types) : [];
        return {date, types} as Shift
      }))
    }
  }, [beginDate, endDate]);

  return <div css={css`display: flex; flex-direction: column; gap: 8px`}>
    <div css={css`display: flex; gap: 4px`}>
      <QuickActions shifts={state} institution={institution} clinic={props.clinic} position={props.position.get()}/>
      <Button onClick={() => shiftCreationModalOpen.set(true)}>Breyta vaktategundum</Button>
    </div>
    <BorderBox css={css`display: flex;
      gap: 6px;
      flex-wrap: wrap;`}>
      {state.map(day => {
        return <DayShiftOptions shift={day} key={format(day.date.get(), DATE_FMT)}
          institution={institution} clinic={props.clinic} position={props.position.get()}/>
      })}
    </BorderBox>
    {shiftCreationModalOpen.get() && <DynamicShiftsModal
        isOpen={shiftCreationModalOpen.get()} onClose={() => shiftCreationModalOpen.set(false)}
        institution={props.institutionState} clinic={props.clinic} professionType={props.position.get().professionType}/>}
  </div>;
};