// DynamicShiftsModal.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useDisclosure, Badge,
} from '@chakra-ui/react';
import {AddIcon, EditIcon, SmallCloseIcon} from '@chakra-ui/icons';
import { DynamicShiftType, ShiftCreationModal } from './ShiftCreationModal';
import {css} from "@emotion/react";
import {Clinic, Institution} from "../../types";
import {State, useHookstate} from '@hookstate/core';
import { createDynamicShiftType, deleteDynamicShiftType, updateDynamicShiftType } from '../../net-utils';
import {WorkerClass} from "../../worker-class";
import {useTranslation} from "react-i18next";

interface DynamicShiftsModalProps {
  isOpen: boolean;
  onClose: () => void;
  institution: State<Institution>;
  clinic: Clinic;
  professionType: WorkerClass.ProfessionType;
}

export const DynamicShiftsModal: React.FC<DynamicShiftsModalProps> = ({ isOpen, onClose, institution, clinic, professionType }) => {
  const { isOpen: isCreationModalOpen, onOpen: onCreationModalOpen, onClose: onCreationModalClose } = useDisclosure();
  const dynamicShiftTypesState = useHookstate(institution.dynamicShiftTypes);
  const [editingShift, setEditingShift] = useState<DynamicShiftType | undefined>(undefined);
  const dynamicShiftTypes = dynamicShiftTypesState.get();
  const setDynamicShiftTypes = dynamicShiftTypesState.set;
  const {t} = useTranslation();
  const handleSave = async (dynamicShiftType: DynamicShiftType) => {
    const institutionId = institution.institutionId.get()!!;
    if (editingShift) {
      await updateDynamicShiftType(institutionId, dynamicShiftType);
    } else {
      await createDynamicShiftType(institutionId, dynamicShiftType);
    }

    setDynamicShiftTypes((prevState) => {
      const newShiftTypes = [...prevState];
      const index = newShiftTypes.findIndex((shift) => shift.shiftName === dynamicShiftType.shiftName);

      if (index === -1) {
        newShiftTypes.push(dynamicShiftType);
      } else {
        newShiftTypes[index] = dynamicShiftType;
      }

      return newShiftTypes;
    });

    onCreationModalClose();
    setEditingShift(undefined);
  };

  const handleEdit = (dynamicShiftType: DynamicShiftType) => {
    setEditingShift(dynamicShiftType);
    onCreationModalOpen();
  };

  const handleDelete = async (id: string) => {
    await deleteDynamicShiftType(institution.institutionId.get()!!, id);
    setDynamicShiftTypes((prevState) => prevState.filter((shift) => shift.id !== id));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mínar vaktategundir ({t(professionType)})</ModalHeader>
        <ModalCloseButton />
        <ModalBody css={css`margin-bottom: 20px; display: flex; flex-direction: column; gap: 8px`}>
          <Box mb={4}>
            <Button colorScheme="green" leftIcon={<AddIcon />} onClick={() => {
              setEditingShift(undefined);
              onCreationModalOpen();
            }}>
              Bæta við vaktategund
            </Button>
          </Box>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nafn</Th>
                <Th>Breyta</Th>
                <Th>Eyða</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicShiftTypes.filter(type => type.professionType === professionType).map((shift) => (
                <Tr key={shift.shiftName}>
                  <Td><Badge colorScheme={shift.color}>{shift.shiftName}</Badge></Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      variant={'ghost'}
                      aria-label="Edit Shift"
                      onClick={() => handleEdit(shift)}
                    />
                  </Td>
                  <Td>
                    <IconButton
                      icon={<SmallCloseIcon />}
                      colorScheme="red"
                      variant={'ghost'}
                      aria-label="Delete Shift"
                      onClick={() => {
                        if (window.confirm(`Ertu viss um að þú viljir eyða vaktategundinni ${shift.shiftName}?`)) {
                          return handleDelete(shift.id);
                        }
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
      {isCreationModalOpen && <ShiftCreationModal
        isOpen={isCreationModalOpen}
        onClose={() => {
          onCreationModalClose();
          setEditingShift(undefined);
        }}
        initialShift={editingShift}
        onSave={handleSave}
        institution={institution.get()}
        clinic={clinic}
      />}
    </Modal>
  );
};
