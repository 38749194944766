import React, {useEffect, useState} from 'react';
import { BasePage } from './BasePage';
import { BorderBox } from './BorderBox';
import { css, Global } from '@emotion/react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
  Badge,
  Button,
  Checkbox,
  CloseButton,
  Heading,
  Menu,
  MenuButton,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import {AddIcon, CheckIcon, ChevronDownIcon, SmallCloseIcon, TimeIcon} from '@chakra-ui/icons';
import { HalfSection, SectionContainer } from './SectionContainer';
import {addDays, format, isAfter, subDays} from 'date-fns';
import {
  calculatePayForPosition,
  cloneState,
  DATE_FMT,
  formatCurrency,
  formatDate,
  getDayNameShort,
  translateFullCalendarMonth
} from '../heka-utils';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import isLocale from '@fullcalendar/core/locales/is';
import {SelectableMenuList} from "./SelectableMenuList";
import {State, useHookstate} from "@hookstate/core";
import {Clinic, Position} from "../types";
import {
  deleteClinic,
  deletePosition,
  duplicatePosition, getCalendarItems,
  getClinicById,
  getPositionsForClinic, PositionCalendarItem, reopenAcceptedPosition,
  updateClinic, updatePosition
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from "react-i18next";
import {EventInput} from "@fullcalendar/core";
import {BiCog, BiTrash, BiUser} from "react-icons/bi";
import {ClinicInputForm} from "./AdminPage";
import {RiBillLine} from "react-icons/ri";
import {LabelInfo} from "./LabelInfo";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import {globalUser} from "../App";
import {LegendItem} from "./UserCalendarPage";
import {NewRequestsAlert} from "./InstitutionPage";
import {HiOutlineDocumentAdd} from "react-icons/hi";


export function ClinicPage() {
  const params = useParams();
  const clinicId = params.clinicId as string | undefined
  const institutionId = params.institutionId as string | undefined
  if (!clinicId || !institutionId) {
    throw new Error('Clinic or institution id is undefined')
  }
  const clinicState = useHookstate<Clinic | null>(null)
  const [positions, setPositions] = useState<Position[] | null>(null)

  const refresh = async () => clinicState.set(await getClinicById(institutionId, clinicId))
  useEffect(() => {
    (async () => {
      await refresh()
    })()
  }, [clinicId, institutionId]);
  const clinic = clinicState.get();
  if (!clinic) {
    return <DefaultSpinner/>
  }

  return <BasePage>
    <div css={css`display: flex;
    flex-direction: column;
    gap: 8px`}>
      <NewRequestsAlert positions={positions ?? []}/>
      <AdPanel institutionId={institutionId} clinic={cloneState(clinicState)!!} refresh={refresh}/>
      <BorderBox>
        <Heading fontSize={'3xl'} css={css`text-align: center;
          margin-bottom: 8px`}>{clinic.name}</Heading>
        <PositionOverview clinicId={clinicId} institutionId={institutionId} positions={positions} setPositions={setPositions}/>
      </BorderBox>
    </div>
  </BasePage>;
}

function AdPanel(props: {institutionId: string, clinic: Clinic, refresh: () => void}) {
  const editClinicModalOpen = useHookstate(false)
  const navigate = useNavigate()
  return <BorderBox>
    <div css={css`display: flex;
          gap: 8px; flex-wrap: wrap`}>
      <Link to={`/institutions/${props.institutionId}/positions/create`} state={{clinicId: props.clinic.clinicId!!}}>
        <Button colorScheme={'green'} leftIcon={<AddIcon/>}>Auglýsa stöðu</Button>
      </Link>
      <Link to={`/institutions/${props.institutionId}/outsider-contract?clinic=${props.clinic.clinicId}`}><Button leftIcon={<HiOutlineDocumentAdd/>}>Nýr samningur</Button></Link>
      <Button leftIcon={<BiCog/>} onClick={() => editClinicModalOpen.set(true)}>
        Breyta starfsstöð
      </Button>
      <Button onClick={() => navigate(`/institutions/${props.institutionId}/clinics/${props.clinic.clinicId}/position-history`)}
        leftIcon={<TimeIcon/>}>
        Stöðusaga
      </Button>
    </div>
    <EditClinicModal clinic={props.clinic} institutionId={props.institutionId} open={editClinicModalOpen} refresh={props.refresh}/>
  </BorderBox>;
}

export function ClinicPosition(props: {
  position: Position,
  refresh: () => void,
  showCreatedBy?: boolean,
  showDateCreated?: boolean,
  showClinicName?: boolean,
}) {
  const {t} = useTranslation();
  const user = useHookstate(globalUser).get()?.user
  const { position } = props;
  const firstDay = position.beginDate!!
  const lastDay = position.endDate!!
  const shiftTypes = Array.from(new Set(position.shifts.flatMap(s => s.types)))
  const workerClasses = position.workerClassRequirements.map(wc => t(`${position.professionType}.${wc}`))
  const accepted = position.isAccepted && !position.cancelled;
  if (!user) return <DefaultSpinner/>
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 6px;`}>
    {props.showClinicName && <Heading size={'sm'}>{position.clinicName}</Heading>}
    <div css={css`display: flex; gap: 4px; align-items: center; margin-bottom: 2px`}>
      {accepted ? <CheckIcon color={'green.600'}/> :
        (new Date() > firstDay) || position.cancelled ? <SmallCloseIcon color={'red.600'}/> :
        <TimeIcon color={'gray'}/>}
      <Badge>{format(firstDay, DATE_FMT)}</Badge> - <Badge>{format(lastDay, DATE_FMT)}</Badge>
      {/*<CloseButton css={css`margin-left: auto`} onClick={() => confirmDeletePosition(position, props.refresh)}/>*/}
    </div>
    <div css={css`display: flex; gap: 4px; flex-wrap: wrap`}>
      <Badge colorScheme={'green'}>{t(position.professionType)}</Badge>
      {shiftTypes.map(type => <Badge colorScheme={'teal'} key={type}>{t(type)}</Badge>)}
      {workerClasses.map(wc => <Badge colorScheme={'blue'} key={wc}>{wc}</Badge>)}
      <Badge colorScheme={'yellow'}>{formatCurrency(calculatePayForPosition(position, user))}</Badge>
      <Badge colorScheme={accepted ? 'green' : 'red'}>{accepted ? t('manned') : t('unmanned')}</Badge>
      {position.cancelled && <Badge colorScheme={'red'}>Hætt við</Badge>}
    </div>
    <div css={css`display: flex; gap: 4px; margin-bottom: 4px`}>
      <Badge colorScheme={'purple'}>{position.requestCount!!} {position.requestCount === 1 ? 'umsókn' : 'umsóknir'}</Badge>
    </div>
    <div>
      {props.showCreatedBy && <LabelInfo label={'Stofnuð af:'}>
          <Link to={`/users/${position.createdBy!!}`}>{position.createdBy!!}</Link>
      </LabelInfo>}
      {props.showDateCreated && <LabelInfo label={'Stofnuð:'}>
        {formatDate(position.created!!)}
      </LabelInfo>}
    </div>
    <PositionAdminControls position={position} onDelete={props.refresh} refresh={props.refresh}/>
  </BorderBox>;
}

export function PositionAdminControls(props: {
  position: Position,
  onDelete: () => void,
  hideShow?: boolean,
  refresh: () => void
}) {
  const {position, onDelete} = props;
  const submitError = useHookstate(null as string | null)
  const navigate = useNavigate()
  const confirmDeletePosition = async () => {
    if (window.confirm('Ertu viss um að þú viljir eyða þessari stöðu?')) {
      await deletePosition(position._id!!)
      onDelete()
    }
  };
  const canDuplicatePosition = position.cancelled && !position.duplicatedPositionId && position.beginDate!! > new Date()
  return <div>
    <div css={css`display: flex;
      gap: 4px;
      flex-wrap: wrap`}>
      <GoButton submitError={submitError} size={'sm'} variant={'outline'} colorScheme={'pink'}
        onClick={async () => {
          await updatePosition({...position, isPublic: !position.isPublic});
          props.refresh();
        }}
      >
        {position.isPublic ? 'Fela auglýsingu' : 'Birta auglýsingu'}
      </GoButton>
      {!props.hideShow && <Link to={`/positions/${position._id}`}>
          <Button size={'sm'} colorScheme={'gray'} variant={'outline'}>Staða</Button>
      </Link>}
      <Link to={`/positions/${position._id}/manage`}><Button size={'sm'} colorScheme={'gray'}
        variant={'outline'}>Umsóknir {Number(position.requestCount) > 0 && `(${position.requestCount})`}</Button></Link>
      {position.positionRequestId && <Link to={`/contracts/${position.positionRequestId}/overview`}>
          <Button variant={'outline'} size={'sm'} colorScheme={'gray'} leftIcon={<RiBillLine/>}>Samningur</Button>
      </Link>}
      {!position.isAccepted && <Link to={`/institutions/${position.institutionId}/positions/${position._id}/update`}>
          <Button variant={'outline'} size={'sm'} leftIcon={<BiCog/>}>Breyta</Button>
      </Link>}
      {position.isAccepted && <Link to={`/users/${position.acceptedBy}`}>
          <Button variant={'outline'} size={'sm'} leftIcon={<BiUser/>}>Verktaki</Button>
      </Link>}
      {canDuplicatePosition && <GoButton size={'sm'} variant={'outline'} onClick={async () => {
        const positionId = await duplicatePosition(position._id!!)
        navigate(`/positions/${positionId}`)
      }} submitError={submitError}>Endurbirta</GoButton>}
      {position.isAccepted && isAfter(position.beginDate!!, new Date()) && <GoButton size={'sm'} variant={'outline'} colorScheme={'red'} onClick={async () => {
        if (window.confirm("Ertu viss um að þú viljir afbóka þessa stöðu? Í kjölfarið verður staðan sýnileg notendum aftur. " +
          "Þessi valmöguleiki ætti aðeins að vera notaður eftir samtal á milli verktaka og stofnunar.")) {
          await reopenAcceptedPosition(position.institutionId, position._id!!)
          props.refresh();
        }
      }} submitError={submitError}>Afbóka</GoButton>}
      {!position.isAccepted &&
          <Button size={'sm'} colorScheme={'red'} variant={'outline'} onClick={confirmDeletePosition}
              leftIcon={<BiTrash/>}>Eyða</Button>}
    </div>
    <AlertDisplay error={submitError}/>
  </div>
}

function PositionOverview(props: { clinicId: string, institutionId: string, positions: Position[] | null, setPositions: (p: Position[]) => void}) {
  const selectedWorkTypes = useHookstate<string[]>(['Doctor', 'Nurse', 'Midwife']);
  const {clinicId, institutionId, positions, setPositions} = props;
  const {t} = useTranslation();
  const [calendarItems, setCalendarItems] = useState<PositionCalendarItem[] | null>(null)

  function refreshPositions() {
    (async () => {
      setPositions(await getPositionsForClinic(institutionId, clinicId, true))
    })();
    (async () => {
      setCalendarItems(await getCalendarItems(institutionId, clinicId))
    })();
  }
  useEffect(() => {
    refreshPositions()
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    translateFullCalendarMonth(t);
  }, [positions]);

  if (!positions) {
    return <DefaultSpinner/>
  }

  const fmtDate = (date: Date) => format(date, 'yyyy-MM-dd')
  const [acceptedColor, unacceptedColor] = ['#48BB78', '#ED8936']
  const events: EventInput[] | undefined = calendarItems?.map(p => {
    const accepted = p.accepted;
    const color = accepted ? acceptedColor : unacceptedColor
    const manned = accepted ? t('manned') : t('unmanned')
    return {
      events: [
        {
          id: p.positionId!!,
          title: `${t(p.professionType)} - ${manned}` + (!accepted ? ` (${p.requestCount})` : ``),
          start: fmtDate(p.beginDate),
          end: fmtDate(addDays(p.endDate, 1)),
        }
      ],
      color
    };
  })

  return <SectionContainer>
    <HalfSection>
      <Heading fontSize={'2xl'} css={css`margin-bottom: 8px`}>Auglýstar stöður</Heading>
      <div>
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
            Starfsgreinar
          </MenuButton>
          <SelectableMenuList items={['Doctor', 'Nurse', 'Midwife']}
            selected={selectedWorkTypes}
            translateFn={i => t(i)}
          />
        </Menu>
      </div>
      {positions.filter(p => selectedWorkTypes.get().includes(p.professionType)).map(p => <ClinicPosition key={p._id} position={p} refresh={refreshPositions}/>)}
    </HalfSection>
    <HalfSection>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale={isLocale}
        dayHeaderContent={hookProps => {
          return getDayNameShort(hookProps.date)
        }}
        eventSources={events ?? []}
        eventClick={event => {
          window.open(`/positions/${event.event.id}`, '_blank')
        }}
        datesSet={() => {
          translateFullCalendarMonth(t);
        }}
      />
      <div css={css`display: flex;
        gap: 16px`}>
        <LegendItem color={acceptedColor} text={'Mönnuð staða'}/>
        <LegendItem color={unacceptedColor} text={'Ómönnuð staða'}/>
      </div>
    </HalfSection>
  </SectionContainer>
}

function EditClinicModal(props: {
  clinic: Clinic,
  institutionId: string,
  open: State<boolean>,
  refresh: () => void
}) {
  const clinic = useHookstate(props.clinic)
  const navigate = useNavigate()
  return <Modal isOpen={props.open.get()} onClose={() => props.open.set(false)}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Breyta starfsstöð</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`margin-bottom: 16px; display: flex; flex-direction: column; gap: 8px`}>
        <ClinicInputForm clinic={clinic} institutionId={props.institutionId}/>
        <div css={css`display: flex; gap: 4px`}>
          <Button colorScheme={'green'} onClick={async() => {
            await updateClinic(props.institutionId, clinic.clinicId.get()!!, clinic.get())
            props.refresh()
            props.open.set(false)
          }}>
            Vista
          </Button>
          <Button colorScheme={'red'} onClick={async () => {
            if (window.confirm('Ertu viss um að þú viljir eyða þessari starfsstöð?')) {
              await deleteClinic(props.institutionId, clinic.clinicId.get()!!)
              navigate(`/institutions/${props.institutionId}`)
            }
          }} leftIcon={<BiTrash/>}>
            Eyða starfsstöð
          </Button>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
}