import { Input, InputGroup, InputGroupProps } from "@chakra-ui/input"
import {Button, ButtonProps, InputLeftElement, InputRightAddon, Spinner} from "@chakra-ui/react"
import React from "react"
import PropTypes from "prop-types"
import {AttachmentIcon} from "@chakra-ui/icons";

interface FilePickerProps {
  onFileChange: (fileList: Array<File>) => void
  placeholder: string
  clearButtonLabel?: string
  hideClearButton?: boolean
  multipleFiles?: boolean
  accept?: string
  inputProps?: InputGroupProps
  inputGroupProps?: InputGroupProps
  buttonProps?: ButtonProps
  isLoading?: boolean
}

interface FilePickerState {
  files: FileList | null
  fileName: string
}

class FilePicker extends React.Component<FilePickerProps, FilePickerState> {
  static defaultProps = {
    clearButtonLabel: "Clear",
    multipleFiles: false,
    accept: undefined,
    hideClearButton: false,
    inputProps: undefined,
    inputGroupProps: undefined,
    buttonProps: undefined,
    isLoading: false
  }

  private inputRef = React.createRef<HTMLInputElement>()

  constructor(props: FilePickerProps) {
    super(props)
    this.state = {
      files: null,
      fileName: ""
    }
  }

  public reset = (): void => this.handleOnClearClick()

  private handleOnFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const targetFiles = ev.target.files;
    if (targetFiles !== this.state.files) {
      const fileArray = new Array<File>()
      if (targetFiles) {
        for (const file of Array.from(targetFiles)) {
          fileArray.push(file)
        }
      }
      this.setState({ ...this.state, fileName: fileArray.map(f => f.name).join(", "), files: targetFiles })
      this.props.onFileChange(fileArray)
    }
    this.clearInnerInput()
  }


  private handleOnClearClick = () => {
    this.setState({ ...this.state, files: null, fileName: "" })
    this.clearInnerInput()
  }

  private clearInnerInput() {
    if (this.inputRef?.current) {
      this.inputRef.current.value = ""
    }
  }

  private handleOnInputClick = () => {
    if (this.inputRef?.current) {
      this.inputRef.current.value = ""
      this.inputRef.current.click()
    }
  }

  render = (): JSX.Element => {
    const {
      placeholder,
      clearButtonLabel,
      hideClearButton,
      multipleFiles,
      accept,
      inputProps,
      inputGroupProps
    } = this.props

    return (
      <InputGroup  {...inputGroupProps} maxWidth={'350px'}>
        <InputLeftElement>
          {this.props.isLoading ? <Spinner/> : <AttachmentIcon/>}
        </InputLeftElement>
        <input
          type="file"
          ref={this.inputRef}
          accept={accept}
          style={{ display: "none" }}
          multiple={multipleFiles}
          onChange={this.handleOnFileChange}
          data-testid={inputProps?.placeholder ?? placeholder}
          disabled={this.props.isLoading}
        />
        <Input
          placeholder={placeholder}
          {
            ...{
              ...inputProps,
              readOnly: true,
              isReadOnly: true,
              value: this.state.fileName,
              onClick: this.handleOnInputClick,
              isDisabled: this.props.isLoading
            }
          }
        />
        {
          !hideClearButton && this.state.files &&
            <ClearButton
                clearButtonLabel={clearButtonLabel ?? "Clear"}
                onButtonClick={this.handleOnClearClick} />
        }
      </InputGroup>
    )
  }
}

type ClearButtonProps = Pick<FilePickerProps, "clearButtonLabel" | "buttonProps"> & {
  onButtonClick: () => void
}

const ClearButton: React.FC<ClearButtonProps> = ({
                                                   clearButtonLabel,
                                                   onButtonClick,
                                                   buttonProps
                                                 }) => (
  <InputRightAddon>
    <Button {...buttonProps} onClick={onButtonClick}>{clearButtonLabel ?? "Clear"}</Button>
  </InputRightAddon>
)

ClearButton.propTypes = {
  clearButtonLabel: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
}


export default FilePicker