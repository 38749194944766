import {State, useHookstate} from "@hookstate/core";
import {css} from "@emotion/react";
import {
  Alert, AlertIcon,
  Avatar,
  Badge,
  Button, Divider,
  Heading, ListItem,
  Modal,
  ModalBody, ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, position,
  Table,
  TableContainer,
  Tbody,
  Td, Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr, UnorderedList
} from "@chakra-ui/react";
import {CheckIcon, CloseIcon, ExternalLinkIcon, ViewIcon} from "@chakra-ui/icons";
import {BorderBox} from "./BorderBox";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import doctorImage from '../resources/doctor.jpg';
import doctorImage2 from '../resources/doctor2.jpg';
import {format, isSameDay, subDays} from 'date-fns';
import {DATE_FMT, formatDate, getDayNameShort, getUserProfessionsFormatted, phoneToString} from '../heka-utils';
import {Position, PositionRequest, User, UserPositionRequestData} from "../types";
import {
  acceptPositionRequest,
  getPositionById,
  getPositionRequestsByPositionId,
  getUser,
  rejectPositionRequest
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from "react-i18next";
import {LabelInfo} from "./LabelInfo";

export const SpecialistRequestList = (props: {position: Position}) => {
  const [requests, setRequests] = useState(null as PositionRequest[] | null);
  const {t} = useTranslation();
  const refresh = () => {
    (async () => setRequests(await getPositionRequestsByPositionId(props.position._id!!)))()
  }
  useEffect(() => {
    refresh()
  }, []);
  if (!requests) {
    return <DefaultSpinner/>
  }

  const acceptedBy = requests.find(r => r.userData?.username === props.position.acceptedBy)?.userData;

  return <>
    {props.position.isAccepted && acceptedBy && <Alert status={'info'}>
        <AlertIcon/>
        <Text>Búið er að samþykkja fyrir þessa stöðu (samþykkt {formatDate(props.position.acceptedAt!!)},
            verktaki: <Link to={`/users/${acceptedBy.username}`}>{acceptedBy.name}</Link>)</Text>
    </Alert>}
    <BorderBox>
      <Heading fontSize={'2xl'} css={css`margin-bottom: 6px`}>Umsóknir</Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Umsækjandi</Th>
              <Th>Starfstétt</Th>
              <Th></Th>
            </Tr>
          </Thead>
          {requests.map(request => {
            const userData = request.userData!!;
            const opacity = request.status === 'Rejected' ? 0.5 : 1;
            return <Tbody opacity={opacity} key={request._id}>
              <Tr>
                <Td>
                  <a target={'_blank'} href={`/users/${request.username}`}>
                    <Avatar name={userData.name} size={'sm'} src={userData.imagePath}/>
                  </a>
                </Td>
                <Td><a target={'_blank'} href={`/users/${request.username}`}>{userData.name}</a></Td>
                <Td>{userData.workerClasses.map(t).join(", ")}</Td>
                <Td>
                  <ApproveButtons positionRequest={request} position={props.position} refresh={refresh} acceptedBy={acceptedBy}/>
                </Td>
              </Tr>
            </Tbody>;
          })}
        </Table>
      </TableContainer>
    </BorderBox></>;
};

const ApproveButtons = (props: {
  positionRequest: PositionRequest,
  position: Position,
  refresh: () => void,
  acceptedBy?: UserPositionRequestData
}) => {
  const modalOpen = useHookstate(false);
  const {positionRequest, position, acceptedBy} = props;
  const rejectModalOpen = useHookstate(false);
  return <div css={css`display: flex; gap: 4px`}>
    {!position.isAccepted && <Button colorScheme={'green'} size={'sm'} onClick={() => modalOpen.set(true)}>
      Yfirfara
    </Button>}
    {/*{position.isAccepted && acceptedBy?.username !== positionRequest.username &&*/}
    {/*    <>*/}
    {/*      <Button colorScheme={'red'} size={'sm'} onClick={() => rejectModalOpen.set(true)}>*/}
    {/*        Hafna*/}
    {/*      </Button>*/}
    {/*      <RejectionExplainMessageModal open={rejectModalOpen} positionRequest={positionRequest} refresh={props.refresh}/>*/}
    {/*    </>}*/}
    <ReviewRequestModal open={modalOpen} request={props.positionRequest} position={props.position} refresh={props.refresh}/>
  </div>;
};

function ReviewRequestModal(props: {
  open: State<boolean>,
  request: PositionRequest,
  position: Position,
  refresh: () => void,
}) {
  const onClose = () => props.open.set(false);
  const navigate = useNavigate()
  const {t} = useTranslation();
  function ReviewModalContent() {
    const rejectModalOpen = useHookstate(false);
    const [user, setUser] = useState(null as User | null);
    useEffect(() => {
      (async () => {
        setUser((await getUser(props.request.username)).user);
      })()
    }, []);
    if (!user) return <DefaultSpinner/>;
    const specializations = user.preferences.professions.flatMap(p => p.specializations).join(", ");
    const unselectedShifts = props.position.shifts.map(s => s.date)
      .filter(d => !props.request.request.shifts.some(s => isSameDay(s, d)));
    const numShifts = props.position.shifts.length;
    return <ModalBody css={css`display: flex; flex-direction: column; gap: 8px; margin-bottom: 16px;`}>
      {!user.certificationId.length && <Alert status={'warning'}>
        <AlertIcon/>
        <Text>Umsækjandi er ekki með starfsleyfi</Text>
      </Alert>}
      <div css={css`display: flex; gap: 4px; flex-direction: column`}>
        <Heading size={'md'}>Umsækjandi {props.request.status === 'Rejected' && '(Hafnað)'}</Heading>
        <div>
          <Button size={'xs'} leftIcon={<ExternalLinkIcon/>}
            onClick={() => window.open(`/users/${user.username}`, '_blank')?.focus()}>
            Skoða prófíl
          </Button>
        </div>
      </div>
      <LabelInfo label={'Nafn'} value={user.electronicId.name}/>
      <LabelInfo label={'Kennitala'} value={user.electronicId.ssn}/>
      <LabelInfo label={'Netfang'} value={user.preferences.email}/>
      <LabelInfo label={'Símanúmer'} value={user.preferences.phoneNumbers.map(p => phoneToString(p)).join(", ")}/>
      <Divider/>
      <LabelInfo label={'Starfstétt'} value={getUserProfessionsFormatted(user, t)}/>
      {specializations.length !== 0 && <LabelInfo label={'Sérgrein'} value={specializations}/>}
      <LabelInfo label={'Fjöldi fyrri afleysinga'} value={props.request.userData!!.numCompletions}/>
      <Divider/>
      <div css={css`margin-top: 8px; margin-bottom: 8px; display: flex; flex-direction: column; gap: 4px`}>
        <Heading size={'md'}>Skilaboð frá umsækjanda</Heading>
        <Textarea value={props.request.request.message} isReadOnly/>
      </div>
      <Divider/>
      <Heading size={'md'}>Vaktir</Heading>
      {unselectedShifts.length === 0 ?
        <Text>Umsækjandi kemst á allar vaktir skráðar í auglýsingu.</Text> :
        <>
          <Text>Umsækjandi kemst á {numShifts - unselectedShifts.length} af {numShifts} vöktum</Text>
          <Text>Vaktir sem umsækjandi kemst ekki á:
          <UnorderedList>
            {unselectedShifts.map(shift => <ListItem
              key={shift.toDateString()}>{format(shift, 'dd.MM.yyyy')} ({getDayNameShort(shift)})</ListItem>)}
          </UnorderedList>
        </Text></>}
      <div css={css`display: flex; gap: 8px; margin-top: 8px`}>
        <Button colorScheme={'green'} leftIcon={<CheckIcon/>} onClick={async () => {
          if (props.position.professionType !== 'Doctor') {
            // Position is accepted through contract page for doctors
            await acceptPositionRequest(props.request._id);
          }
          navigate(`/contracts/${props.request._id}`);
        }}>
          Samþykkja
        </Button>
        {/*<Button colorScheme={'red'} leftIcon={<CloseIcon/>} onClick={() => rejectModalOpen.set(true)}*/}
        {/*  isDisabled={props.request.status !== 'Pending'}>*/}
        {/*  Hafna*/}
        {/*</Button>*/}
      </div>
      <RejectionExplainMessageModal open={rejectModalOpen} positionRequest={props.request}
        refresh={() => {
          props.refresh();
          onClose();
        }}/>
    </ModalBody>
  }
  return <Modal isOpen={props.open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Yfirfara umsókn</ModalHeader>
      <ModalCloseButton/>
      <ReviewModalContent/>
    </ModalContent>
  </Modal>
}

function RejectionExplainMessageModal(props: { open: State<boolean>, positionRequest: PositionRequest, refresh: () => void }) {
  const onClose = () => props.open.set(false);
  const message = useHookstate('');
  return <Modal isOpen={props.open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Útskýring fyrir höfnun</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Textarea placeholder={'Útskýring fyrir höfnun'} value={message.get()} onChange={e => message.set(e.target.value)}/>
      </ModalBody>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <Button colorScheme={'red'} onClick={async () => {
          await rejectPositionRequest(props.positionRequest._id, message.get());
          onClose();
          props.refresh()
        }}>Hafna</Button>
        <Button onClick={onClose}>Sleppa</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}
