import React from "react";
import { css, SerializedStyles } from '@emotion/react';
import { NavigationBar } from './NavigationBar';
import { useWindowDimensions } from '../heka-utils';
import {Link} from "react-router-dom";

export function BasePage(props: { children: React.ReactNode, css?: SerializedStyles, className?: string }) {
  const { height, width } = useWindowDimensions()
  let widthPercent = '50%'
  if (width < 1715) {
    widthPercent = '70%'
  }
  if (width < 1200) {
    widthPercent = '95%'
  }
  return <div className={props.className} css={css`width: ${widthPercent}; margin-bottom: 16px; 
      display: flex; flex-direction: column; gap: 8px`}>
    <NavigationBar/>
    {props.children}
    <div css={css`display: flex; flex-direction: column; gap: 4px; margin-top: 20px`}>
      <Link to={'/terms'} target={'_blank'}>Skilmálar Heka</Link>
      <span>Höfundarréttur © Heka {new Date().getFullYear()}</span>
    </div>
  </div>
}