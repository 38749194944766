import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {BorderBox} from "./BorderBox";
import {css, SerializedStyles} from "@emotion/react";
import {DateInputForm, InputForm} from "./InputForm";
import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement, Radio, RadioGroup,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useToken
} from '@chakra-ui/react';
import {DatePicker} from "./DatePicker";
import {PhoneIcon, StarIcon} from '@chakra-ui/icons';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {State, useHookstate} from '@hookstate/core';
import {WorkerClass} from '../worker-class';
import {
  getHighestWorkerClassForProfessions,
  getProfessionWorkerClass, getWorkerClassFormatted,
  initRecord,
  onSelect,
  parseUserJwt,
  setUserJwt
} from '../heka-utils';
import {OptionalInputCheckbox} from './OptionalInputCheckbox';
import phoneCodes from '../resources/phone-codes.json'
import {CollectibleComponent} from "./CollectibleComponent";
import {HalfSection, SectionContainer} from "./SectionContainer";
import {
  ExternalError,
  InstitutionName,
  institutionNames,
  NewUserElectronicId,
  PhoneNumber,
  PreviousEmployment,
  Profession,
  User, UserPreferences
} from "../types";
import {CollectibleList} from "./CollectibleList";
import {createUser, FileAuthCredentials, getUser, updateUser} from "../net-utils";
import {validateEmail, validatePhone} from "../validations";
import {GoButton} from "./GoButton";
import FileUploadButton from "./FileUploadButton";
import {DefaultSpinner} from "./DefaultSpinner";
import TypeName = WorkerClass.ProfessionType;
import {globalUser} from "../App";
import {useTranslation} from "react-i18next";
import {QuestionMarkInfoIcon} from "./QuestionMarkInfoIcon";
import {cloneDeep} from "lodash";

// const defaultSpecialist = {
//   professions: [
//     {
//       type: 'Doctor',
//       isStudent: false,
//       isSpecialist: true,
//       specializations: ['lyflækningar og nýrnalækningar', 'Almennar lyflækningar'],
//       certificationId: '0100049'
//     }
//   ] as Profession[],
//   certificationJwt: '',
//   electronicId: '',
//   phoneNumber: '8998437'
// }
//
// const defaultStudent = {
//   professions: [
//     {
//       type: 'Doctor',
//       isStudent: true,
//       isSpecialist: false,
//       specializations: [] as string[],
//       certificationId: ''
//     }
//   ] as Profession[],
//   certificationJwt: '',
//   electronicId: '',
//   phoneNumber: '8998437'
// }
//
// const defaultDoctor = {
//   professions: [
//     {
//       type: 'Doctor',
//       isStudent: false,
//       isSpecialist: false,
//       specializations: [] as string[],
//       certificationId: '',
//     }
//   ] as Profession[],
//   certificationJwt: '',
//   electronicId: '',
//   phoneNumber: '8998437'
// }

const defaultUnregistered = {
  professions: [] as Profession[],
  certificationJwt: '',
  electronicId: '',
  phoneNumber: '8998437'
}

export function SignupResumePage() {
  const location = useLocation();
  // NewUser(professions=[Profession(type=Doctor, isStudent=false, isSpecialist=true, specializations=[lyflækningar og nýrnalækningar, Almennar lyflækningar], certificationId=0100049)
  const locationState: NewUserElectronicId = (location.state as NewUserElectronicId) ?? defaultUnregistered;
  if (!locationState) throw new Error('location state is undefined');
  const electronicIDPhone: PhoneNumber = {country: 'Iceland', phoneNumber: locationState?.phoneNumber};
  const apiProfessions = locationState.professions;
  const electronicId = locationState.electronicId;
  const globalUserState = useHookstate(globalUser);
  const navigate = useNavigate()
  const user = {
    ...createBlankUser(),
    phoneNumbers: electronicIDPhone ? [electronicIDPhone] : [],
    professions: apiProfessions ? apiProfessions : []
  };
  const unverified = apiProfessions.length === 0;
  const submitFn = async (user: UserPreferences) => {
    const response = await createUser(user, electronicId, locationState.certificationJwt)
    setUserJwt(response.jwt)
    if (unverified) {
      navigate('/verification-pending')
    } else {
      const username = parseUserJwt(response.jwt).username
      globalUserState.set(await getUser(username))
      const isDoctor = user.professions.map(p => p.type).includes('Doctor');
      navigate('/signup/bank', {state: {isDoctor, username: username}})
    }
  }
  const credentials = {
    header: 'heka-electronic-id',
    value: electronicId
  }
  return <UserInputPage user={user} submit={submitFn} credentials={credentials} isUpdate={false}/>
}

export function UpdateResumePage() {
  const username = useParams<string>().username;
  if (!username) throw new Error('username is undefined')
  const globalUserState = useHookstate(globalUser);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate()
  useEffect(() => {
    (async () => {
      const userResponse = await getUser(username);
      setUser(userResponse.user)
    })()
  }, [username]);
  if (!user) return <DefaultSpinner/>;
  const submitFn = async (user: UserPreferences) => {
    await updateUser(username, user)
    globalUserState.set(await getUser(username))
    navigate(`/users/${username}`)
  }
  const userJwt = localStorage.getItem('heka-user-jwt');
  if (!userJwt) throw new Error('No user jwt');
  const credentials = {
    header: 'heka-user-jwt',
    value: userJwt
  }
  return <UserInputPage user={{...user.preferences}} submit={submitFn} credentials={credentials} isUpdate={true}/>
}

function UserInputPage(props: {
  user: UserPreferences,
  submit: (user: UserPreferences) => Promise<void>,
  credentials: FileAuthCredentials,
  isUpdate: boolean
}) {
  const userState = useHookstate<UserPreferences>(cloneDeep(props.user));
  const submitError = useHookstate<string | null>(null);
  const [lightGray] = useToken('colors', ['gray.50']);
  const previousWorksChecked = useHookstate(userState.previousEmployments.length > 0);
  const {t} = useTranslation();
  const unverified = props.user.professions.length === 0;

  useEffect(() => {
    !previousWorksChecked.get() && userState.previousEmployments.set([])
  }, [previousWorksChecked]);

  useEffect(() => {
    if (userState.emailNotifications.get())
      userState.favoriteInstitutions.set([...institutionNames])
    else
      userState.favoriteInstitutions.set([])
  }, [userState.emailNotifications]);
  const agreedTerms = useHookstate(false);
  return <BasePage>
    <BorderBox css={css`display: flex;
      flex-direction: column;
      background-color: ${lightGray};
      gap: 8px;`}>

      <BorderBox css={css`background-color: white;
        display: flex;
        flex-direction: column;
        gap: 8px`}>
        {unverified && <Alert status="warning">
            <AlertIcon/>
            Takk fyrir að skrá þig! Ekki fundust upplýsingar um þig á starfsleyfaskrá embætti landlæknis.
            Fyrir nema sem ekki hafa tímabundið starfsleyfi, óskum við eftir staðfestingu á skólavist hér fyrir neðan.
            Við látum þig vita um leið og við höfum yfirfarið umsóknina þína. Heyrumst fljótlega!
        </Alert>}
        <InputForm autoFocus={userState.email.get().length === 0} label={'Netfang'} placeholder={'Netfang'} type={'email'} isRequired
          state={userState.email} validate={validateEmail}/>
        {/* PHONE NUMBER */}
        <FormControl isRequired>
          <FormLabel css={css`margin-right: 8px;`}>Símanúmer</FormLabel>
          <PhoneNumberInput phoneNumbers={userState.phoneNumbers}/>
        </FormControl>
      </BorderBox>
      <BorderBox css={css`background-color: white;
        display: flex;
        flex-direction: column;
        gap: 8px`}>
        <FormControl isRequired>
          <FormLabel>Heilbrigðisstétt(ir)</FormLabel>
          <ProfessionsList professions={userState.professions} unverified={unverified}/>
        </FormControl>
        { /* Student checkboxes */}
        {!!userState.professions.length && <div>
          <FormLabel>Ég er</FormLabel>
          <StudentCheckboxList professions={userState.professions} unverified={unverified}/>
        </div>}
        { /* A checkbox for every selected job type */}
        {userState.professions.some(p => p.isSpecialist.get()) && <div>
          <SpecializationList professions={userState.professions}/>
        </div>}
        {userState.professions.filter(p => getProfessionWorkerClass(p.get()) === 'Standard').map(p =>
          <OptionalInputCheckbox label={`Ég er í sérnámi sem ${getWorkerClassFormatted(p.get(), t).toLowerCase()}`}
            open={p.isInResidency}>
            <DateInputForm state={p.residencyDate} label={'Sérnám byrjað'} showYearDropdown/>
          </OptionalInputCheckbox>)}

        {/* PREVIOUS WORK */}
        {/*{!!userState.professions.length && <>*/}
        {/*  <OptionalInputCheckbox label={'Fyrri störf innan heilbrigðisþjónustu'}*/}
        {/*    open={previousWorksChecked}>*/}
        {/*    <PreviousWorks previousWorks={userState.previousEmployments}*/}
        {/*                   professions={userState.professions.get()}/>*/}
        {/*  </OptionalInputCheckbox>*/}
        {/*</>}*/}
        <FormControl>
          <FormLabel>Starfsreynsla á sviðum</FormLabel>
          <BorderBox>
            <SectionContainer>
              <HalfSection>
                <ExperienceSlider label={'Hjúkrunarheimili'} state={userState.experiences.nursingHomes}/>
                <ExperienceSlider label={'Heilsugæsla'} state={userState.experiences.clinics}/>
                <ExperienceSlider label={'Heimaþjónusta'} state={userState.experiences.homeService}/>
              </HalfSection>
              <HalfSection>
                <ExperienceSlider label={'Sjúkrahús'} state={userState.experiences.hospitals}/>
                <ExperienceSlider label={'Sérfræðimóttaka'} state={userState.experiences.specialistReceptions}/>
              </HalfSection>
            </SectionContainer>
          </BorderBox>
        </FormControl>
      </BorderBox>
      <BorderBox css={css`background-color: white;
        display: flex;
        flex-direction: column;
        gap: 8px`}>
        {/*<FileUploadButton type={'pdf'} label={'Starfsleyfi (PDF)'} state={userState.links.permit} credentials={props.credentials}/>*/}
        {userState.get().professions.filter(p => p.certificationId.length).map(p => <FormControl>
          <FormLabel>Leyfisnúmer ({t(p.type)})</FormLabel>
          <Input maxWidth={'350px'} type={'number'} value={p.certificationId} isDisabled/>
        </FormControl>)}
        {unverified && <FileUploadButton type={'image and pdf'} label={'Staðfesting á skólavist (PDF eða mynd)'} isRequired
          state={userState.links.enrollmentVerification} credentials={props.credentials}
        />}
        <FileUploadButton type={'pdf'} label={'Ferilskrá (PDF)'} state={userState.links.cv} credentials={props.credentials}
          subtext={'*Valkvætt'}/>
        <FileUploadButton type={'image'} label={'Mynd af mér'} state={userState.links.picture} credentials={props.credentials}
          subtext={'*Valkvætt'}/>
      </BorderBox>
      <BorderBox css={css`background-color: white; display: flex; flex-direction: column; gap: 8px`}>
        <OptionalInputCheckbox label={'Fá tilkynningar í tölvupósti'} open={userState.emailNotifications}
                               size={'lg'}>
          <Alert status={'info'}>
            <AlertIcon/>
            Þú færð tölvupóst þegar nýjar auglýsingar birtast.
          </Alert>
          <BorderBox>
            <Heading fontSize={'md'} css={css`margin-bottom: 8px`}>Ég vil fá tilkynningar frá</Heading>
            <FavoriteInstitutions favoriteInstitutions={userState.favoriteInstitutions}/>
          </BorderBox>
        </OptionalInputCheckbox>
        {!props.isUpdate && <Checkbox size={'lg'}
          isChecked={agreedTerms.get()}
          onChange={e => agreedTerms.set(e.target.checked)}
        >Ég hef lesið og kynnt mér <Link css={css`color: #596375;`} to={'/terms'} target={'_blank'}>skilmála Heka</Link> og hér með samþykki þá
        </Checkbox>}
      </BorderBox>
      {submitError.get() && <Alert status={'error'}>
        <AlertIcon/>
        {submitError.get()}
      </Alert>}
      <GoButton isDisabled={!agreedTerms.get() && !props.isUpdate} onClick={async () => {
        const user = userState.get()
        submitError.set(null)
        if (validateEmail(user.email) ||
          !user.phoneNumbers.length || user.phoneNumbers.some(p => validatePhone(p.phoneNumber)) ||
          !user.professions.length
        ) {
          submitError.set('Vinsamlegast fylltu út alla reiti')
          return
        }
        const validationErrors = validateUser(user, unverified);
        if (validationErrors.length > 0) {
          submitError.set(validationErrors.join('\n'));
          return
        }
        await props.submit(user)
      }} submitError={submitError}>Áfram</GoButton>
    </BorderBox>
  </BasePage>;
}

function FavoriteInstitutions(props: {
  favoriteInstitutions: State<InstitutionName[]>
}) {
  const favoriteInstitutions = useHookstate<InstitutionName[]>(props.favoriteInstitutions);
  // function to add or remove from favorite institutions depending on checked
  const addOrRemove = (institution: InstitutionName, checked: boolean) =>
      checked ? favoriteInstitutions.merge([institution])
          : favoriteInstitutions.set(favoriteInstitutions.get().filter(i => i !== institution))
  const isChecked = (institution: InstitutionName) => favoriteInstitutions.get().includes(institution);
  return <div css={css`display: flex;
            flex-direction: column;
            gap: 4px`}>
    {
      institutionNames.map(institution =>
          <Checkbox key={institution} icon={<StarIcon/>}
                    isChecked={isChecked(institution)}
                    onChange={(e) => addOrRemove(institution, e.target.checked)}>
            {institution}
          </Checkbox>)
    }
  </div>;
}

function ProfessionsList(props: {
    professions: State<Profession[]>;
    unverified: boolean
}) {
  const createBlankProfession = (p: TypeName) => ({
    type: p, specializations: [], isStudent: props.unverified, isSpecialist: false, isInResidency: false, certificationId: ""
  } as Profession);
  const {t} = useTranslation();
  const professions = useHookstate(props.professions)
  const options = WorkerClass.professionTypes.map(type => t(type));
  const selectedProfessionLabels = useHookstate<string[]>(
      professions.map(p => t(p.type.get()))
  )

  useEffect(() => {
    professions.set(selectedProfessionLabels.map(p => {
      const typeName = WorkerClass.icelandicToTypeName(p.get())
      const existing = professions.get().find(e => e.type == typeName)
      return existing ? {...existing, specializations: [...existing.specializations]} : createBlankProfession(typeName)
    }))
  }, [selectedProfessionLabels])

  return <CollectibleList options={options}
    selectedItems={selectedProfessionLabels}
    isDisabled={!props.unverified}
  />;
}

export const ExperienceSlider = (props: {
  label: string,
  className?: string,
  css?: SerializedStyles,
  state: State<number>,
  min?: number,
  max?: number,
}) => {
  const {
    min = 0,
    max = 10,
  } = props;
  const experience = useHookstate(props.state)
  return <div className={props.className} css={css`padding-left: 12px; padding-right: 12px`}>
    <FormLabel>{props.label}: {experience.get()}{experience.get() === max && '+'} ár</FormLabel>
    <Slider defaultValue={min} min={min} max={max} step={1} onChange={e => experience.set(e)}
            value={experience.get()}>
      <SliderTrack bg="blue.50">
        <Box position="relative" right={10}/>
        <SliderFilledTrack bg="blue.300"/>
      </SliderTrack>
      <SliderThumb boxSize={6}/>
    </Slider>
  </div>;
};

function SpecializationList(props: {
  professions: State<Profession[]>,
}) {
  const professions = useHookstate(props.professions);
  const hasSpecialization = (professionType: string) =>
      professions.get().some(p => p.type === professionType && p.specializations.length > 0);
  const checkedSpecializations = useHookstate<Record<WorkerClass.ProfessionType, boolean>>(
      initRecord(WorkerClass.professionTypes, false, false)
  );
  useEffect(() => {
    checkedSpecializations.set({
      'Doctor': hasSpecialization('Doctor'),
      'Nurse': hasSpecialization('Nurse'),
      'Midwife': hasSpecialization('Midwife'),
    })
  }, [professions]);

  return <>
    {professions.length === 0 && <Text color={'gray.700'} css={css`font-style: italic`}>Þú verður að velja heilbrigðisstétt til þess að velja sérgrein.</Text>}
    {professions.filter(p => p.isSpecialist.get()).map((profession, index) => {
      const registeredSpecializations = WorkerClass.getSpecializations(profession.type.get()).map(spec => (spec.icelandic));
      const professionsNotInList = profession.get().specializations
        .filter(p => !registeredSpecializations.includes(p));
      const specializations = [...professionsNotInList, ...registeredSpecializations];
      return <div key={profession.get().type} css={css`display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px`}>
        <div>
          <FormLabel>Sérgreinar
            sem {WorkerClass.typeNameTranslations[profession.type.get()].icelandic.toLowerCase()}</FormLabel>
          <CollectibleList
            options={specializations}
            selectedItems={profession.specializations}/>
        </div>
      </div>;
    })}
  </>;
}

function StudentCheckboxList(props: {
  professions: State<Profession[]>,
  unverified: boolean
}) {
  const professions = useHookstate(props.professions)
  const {t} = useTranslation()
  return <div css={css`display: flex; gap: 8px; flex-wrap: wrap; flex-direction: column`}>
    {professions.map((profession) => {
        const type = profession.type.get();
        const currentWorkerClass = getHighestWorkerClassForProfessions(professions.get(), type);
        return <RadioGroup isDisabled key={type} css={css`display: flex;
          gap: 8px`} value={currentWorkerClass} onChange={e => {
            switch (e) {
              case 'Standard':
                profession.isStudent.set(false);
                profession.specializations.set([]);
                profession.isSpecialist.set(false);
                break;
              case 'Specialist':
                profession.isStudent.set(false);
                profession.isSpecialist.set(true);
              break;
              case 'Student':
                profession.isStudent.set(true);
                profession.specializations.set([]);
                profession.isSpecialist.set(false);
              break;
            }
          }}>
          <Radio value={'Standard'} >{t(`${type}.Standard`)}</Radio>
          <Radio value={'Specialist'}>{t(`${type}.Specialist`)}</Radio>
          <Radio value={'Student'}>{t(`${type}.Student`)}</Radio>
        </RadioGroup>;
      }
      // <Checkbox key={profession.get().type} isChecked={profession.isStudent.get()}
        //           onChange={e => profession.isStudent.set(e.target.checked)}>
        //   Nemandi ({WorkerClass.getTypeNameTranslation(profession.type.get())})
        // </Checkbox>
    )}
  </div>;
}

// function PreviousWorks(props: {
//   previousWorks: State<PreviousEmployment[]>,
//   professions: Profession[]
// }) {
//   const {professions} = props;
//   const previousWorks = useHookstate(props.previousWorks);
//
//   const isStudentIn = (type: WorkerClass.ProfessionType) => professions.some(p => p.type === type && p.isStudent);
//
//   function createBlankPreviousEmployment(): PreviousEmployment {
//     return {
//       professionType: professions.at(0)?.type || 'Doctor',
//       workerClass: 'Standard',
//       department: '',
//       institution: '',
//       position: '',
//       specialization: '',
//       years: 0
//     };
//   }
//   useEffect(() => {
//     // Removes previous works if user deselects a profession
//     WorkerClass.professionTypes.filter(s => !professions.map(p => p.type).includes(s))
//         .map(type => previousWorks.find(pw => pw.professionType.get() === type))
//         .forEach(prevWork => prevWork?.set(createBlankPreviousEmployment()));
//   }, [professions]);
//
//   return <CollectibleComponent<PreviousEmployment>
//       display={(state) => <FormControl css={css`display: flex;
//             flex-direction: column;`}>
//         <div css={css`display: flex;
//               gap: 8px;
//               align-items: center;
//               flex-wrap: wrap;
//               margin-bottom: 8px`}>
//           <Select onChange={onSelect(state.professionType)} value={state.professionType.get()}>
//             {professions.map(profession => <option value={profession.type}>
//               {WorkerClass.getTypeNameTranslation(profession.type)}
//             </option>)}
//           </Select>
//           <Input placeholder={'Stofnun'} onChange={e => state.institution.set(e.target.value)}
//                  value={state.institution.get()}/>
//           {state.professionType.get() !== 'Doctor' &&
//               <Input placeholder={'Deild'} onChange={e => state.department.set(e.target.value)}
//                 value={state.department.get()}/>}
//           <Select onChange={onSelect(state.workerClass)}
//                       isDisabled={isStudentIn(state.professionType.get())}
//                       value={isStudentIn(state.professionType.get()) ? 'Student' : state.workerClass.get()}>
//                 {WorkerClass.datas[state.professionType.get()].achievements
//                     .map(achievement => <option value={achievement.english}>
//                   {achievement.icelandic}
//                 </option>)}
//           </Select>
//           {state.professionType.get() === 'Doctor' && <Select onChange={onSelect(state.specialization)} value={state.specialization.get()}>
//             {WorkerClass.datas.Doctor.specializations.map(spec => <option value={spec.icelandic}>{spec.icelandic}</option>)}
//           </Select>}
//           <ExperienceSlider label={'Starfstími'} state={state.years} css={css`width: 50%`}/>
//         </div>
//       </FormControl>} elements={previousWorks} construct={() => ({...createBlankPreviousEmployment()})}/>;
// }

function PhoneNumberInput(props: { phoneNumbers: State<PhoneNumber[]> }) {
  const PhoneNumberListItem = (props: { phone: State<PhoneNumber> }) => {
    const phone = useHookstate(props.phone);
    const error = useHookstate<string | null>(null);
    return <div css={css`display: flex;
          width: 100%`}>
      <div css={css`width: 100px`}>
        <Select value={phone.country.get()} borderBottomRightRadius={0} borderTopRightRadius={0}
          onChange={e => phone.country.set(e.target.value)}>
          {phoneCodes.countries.map(country =>
            <option key={country.name} value={country.name}>{country.code} {country.name}</option>)}
        </Select>
      </div>
      <FormControl isInvalid={error.get() !== null}>
        <InputGroup css={css`flex: 6;`}>
          <InputLeftElement><PhoneIcon color={'gray.600'}/></InputLeftElement>
          <Input borderBottomLeftRadius={0} borderTopLeftRadius={0} placeholder={'Símanúmer'} type={'tel'}
                 value={phone.phoneNumber.get()}
                 onChange={e => phone.phoneNumber.set(e.target.value)}
                 onBlur={() => error.set(validatePhone(phone.phoneNumber.get()))}
          />
        </InputGroup>
        {error.get() && <FormErrorMessage>{error.get()}</FormErrorMessage>}
      </FormControl>
    </div>
  }
  return <CollectibleComponent
    display={(phone: State<PhoneNumber>) => <PhoneNumberListItem phone={phone}/>} elements={props.phoneNumbers} construct={() => {
    return {country: 'Iceland', phoneNumber: ''} as PhoneNumber
  }}/>
}

const createBlankUser = (): UserPreferences => {
  return {
    email: '',
    phoneNumbers: [],
    professions: [],
    previousEmployments: [],
    links: {
      permit: undefined,
      cv: undefined,
      picture: undefined,
      enrollmentVerification: undefined,
    },
    experiences: {
      nursingHomes: 0,
      hospitals: 0,
      clinics: 0,
      specialistReceptions: 0,
      homeService: 0
    },
    favoriteInstitutions: [...institutionNames],
    emailNotifications: true,
  }
}

type ValidationError = string;

function validateUser(user: UserPreferences, unverified: boolean): ValidationError[] {
  const emailError = validateEmail(user.email);
  const emailErrors = emailError ? [emailError] : [];

  const phoneErrors = user.phoneNumbers
    .map(phone => validatePhone(phone.phoneNumber))
    .filter(error => error !== null) as ValidationError[];

  const professionErrors = user.professions.length === 0 ? ['Þú verður að velja að minnsta kosti einn starfsgrein'] : [];

  const residencyErrors = user.professions
    .filter(profession => profession.isInResidency && !profession.residencyDate)
    .map(profession => `Þú hefur ekki valið dagsetningu fyrir sérnám sem ${WorkerClass.getTypeNameTranslation(profession.type).toLowerCase()}`);

  const unverifiedErrors = unverified && !user.links.enrollmentVerification ? ['Staðfesting á skólavist vantar'] : [];

  return [...emailErrors, ...phoneErrors, ...professionErrors, ...residencyErrors, ...unverifiedErrors];
}

