import React from 'react';
import {
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import {BasePage} from "./BasePage";
import {BorderBox} from "./BorderBox";

export const VerificationPendingPage = () => {
  return (
    <BasePage>
      <VStack spacing={6}>
        <BorderBox>
          <Heading as="h1" size="xl" fontWeight="bold" textAlign="center" marginBottom={5}>
            Takk fyrir að skrá þig!
          </Heading>
          <Text mt={2}>
            Við erum næstum því tilbúin með þína skráningu.
          </Text>
          <Text mt={2}>Stjórnendur Heka munu innan skamms fara yfir aðganginn þinn.</Text>
          <Text mt={2}></Text>
          <Text mt={2}>Við látum þig svo strax vita þegar allt er orðið klárt. Þú færð tölvupóst þegar aðgangur þinn er samþykktur.</Text>
          <Text mt={2}>
            Ef þú hefur einhverjar spurningar getur þú sent okkur tölvupóst á <a href="mailto:heka@heka.is">heka@heka.is</a>. Heyrumst fljótlega!
          </Text>
        </BorderBox>
      </VStack>
    </BasePage>
  );
};
