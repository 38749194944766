import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {
  Badge,
  Button, Divider,
  Heading, Modal, ModalBody, ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {createClinic, getPositionsAndRequestsForUser, PositionAndRequest} from "../net-utils";
import {calculatePayForPosition, formatCurrency, formatDate, getUsername} from "../heka-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import {ClinicInputForm} from "./AdminPage";
import {State, useHookstate} from "@hookstate/core";
import {Position, PositionRequest} from "../types";
import {LabelInfo} from "./LabelInfo";
import {RiBillLine} from "react-icons/ri";
import {globalUser} from "../App";

const UserPositionRequestsPage = () => {
  const [positions, setPositions] = useState<PositionAndRequest[] | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      setPositions(await getPositionsAndRequestsForUser(getUsername()));
    })();
  }, []);
  if (!positions) {
    return <DefaultSpinner/>
  }
  return (
    <BasePage>
      <Heading fontSize={'2xl'}>Mínar umsóknir</Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Starfsstöð</Th>
              <Th>Stofnun</Th>
              <Th>Byrjun</Th>
              <Th>Lok</Th>
              <Th>Staða</Th>
            </Tr>
          </Thead>
          <Tbody>
            {positions.map(pos => {
              const position = pos.position;
              const request = pos.request;
              return <PositionRow key={pos.request._id} position={position} request={request}/>
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </BasePage>
  );
};

function getPositionStatus(position: Position, request: PositionRequest) {
  return position.isDeleted ? 'Stöðu eytt' :
    request.status === 'Rejected' ? 'Hafnað' :
      !position.isAccepted ? 'Í vinnslu' :
        position.acceptedBy === getUsername() ? 'Samþykkt' :
          'Ekki valinn';
}

function PositionRow(props: {
  position: Position,
  request: PositionRequest
}) {
  const {position, request} = props;
  const modalOpen = useHookstate(false);
  return <Tr css={css`cursor: pointer`} onClick={() => modalOpen.set(true)}>
    <Td>
      {position.clinicName}
    </Td>
    <Td>
      {position.institutionName}
    </Td>
    <Td>
      {formatDate(position.beginDate!!)}
    </Td>
    <Td>
      {formatDate(position.endDate!!)}
    </Td>
    <Td>
      { getPositionStatus(position, request)}
    </Td>
    <RequestViewModal open={modalOpen} request={request} position={position}/>
  </Tr>
}

function RequestViewModal(props: { open: State<boolean>, request: PositionRequest, position: Position }) {
  const user = useHookstate(globalUser).get()?.user
  const { open, request, position } = props;
  const navigate = useNavigate();
  const acceptedByUser = position.acceptedBy === getUsername();
  if (!user) {
    return null;
  }
  return <Modal isOpen={open.get()} onClose={() => open.set(false)}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Umsókn</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 8px; margin-bottom: 16px`}>
        <LabelInfo label={'Stofnun'} value={position.institutionName}/>
        <LabelInfo label={'Starfsstöð'} value={position.clinicName}/>
        <LabelInfo label={'Tímabil'} value={`${formatDate(position.beginDate!!)} - ${formatDate(position.endDate!!)}`}/>
        <LabelInfo label={'Greiðsla'} value={formatCurrency(calculatePayForPosition(position, user))}/>
        {!!request.request.message?.length && <LabelInfo label={'Skilaboð'} value={request.request.message}/>}
        <LabelInfo label={'Valdar vaktir'}>
          <div css={css`display: flex; gap: 4px`}>
            {request.request.shifts.map(shift => <Badge key={shift.toDateString()}>{formatDate(shift)}</Badge>)}
          </div>
        </LabelInfo>
        <Divider/>
        <LabelInfo label={'Staða'}>
          { getPositionStatus(position, request) }
        </LabelInfo>
        { request.status === 'Rejected' && request.rejectionMessage &&
            <LabelInfo label={'Ástæða höfnunar'} value={request.rejectionMessage}/> }
        <div css={css`display: flex; gap: 4px`}>
          <Button variant={'outline'} onClick={() => navigate(`/positions/${position._id}`)}>Skoða stöðu</Button>
          {position.hasContract && acceptedByUser &&
              <Button variant={'outline'} leftIcon={<RiBillLine/>}
                  onClick={() => navigate(`/contracts/${position.positionRequestId}/overview`)}>Skoða samning</Button>}
          {acceptedByUser &&
            <Button variant={'outline'} leftIcon={<RiBillLine/>} onClick={() => navigate(`/positions/${position._id}/invoice`)}>
              {position.invoiceSent ? 'Skoða reikning' : 'Senda reikning'}
            </Button>
          }
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
}

export default UserPositionRequestsPage;
