import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./resources/translations/en.json";
import is from "./resources/translations/is.json";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    is: is,
  },
  lng: "is",
});

// prevent number input from changing when scrolling
document.addEventListener("wheel", function (event) {
  const element = document.activeElement
  if (element && element instanceof HTMLInputElement) {
    if (element.type === "number") {
      element.blur()
    }
  }
});

// ['unload', 'beforeunload'].forEach(function (eventName) {
//   window.addEventListener(eventName, function () {
//     console.log('unload')
//   });
// });

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
