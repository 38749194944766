import {Tooltip} from "@chakra-ui/react";
import {css} from "@emotion/react";
import {InfoOutlineIcon} from "@chakra-ui/icons";
import React from "react";

export function QuestionMarkInfoIcon(props: { text: string }) {
  const {text} = props;
  return <Tooltip label={text} placement={'top'} closeOnClick={false}>
    <div css={css`display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--chakra-colors-gray-600);
      font-size: 20px`}>
      <InfoOutlineIcon/>
    </div>
  </Tooltip>
}