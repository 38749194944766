// ColorPicker.tsx
import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  label?: string;
}

const ColorPicker = ({ value, onChange, label }: ColorPickerProps) => {
  const colors = [
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "blue",
    "cyan",
    "purple",
    "pink",
  ];
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();

  const handleSelect = (color: string) => {
    onChange(color);
    setIsOpen(false);
  };

  return (
    <Box position="relative">
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuButton
          as={Button}
          variant="outline"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Badge colorScheme={value} p={2} borderRadius="md">
            {label?.length ? label : t(value.toLowerCase())}
          </Badge>
        </MenuButton>
        <MenuList maxH="200px" overflowY="auto">
          {colors.map((color, index) => (
            <MenuItem key={index} onClick={() => handleSelect(color)}>
              <Badge colorScheme={color} p={2} borderRadius="md">
                {t(color.toLowerCase())}
              </Badge>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        display={isOpen ? "block" : "none"}
        onClick={() => setIsOpen(false)}
      />
    </Box>
  );
};

export default ColorPicker;
