import {State, useHookstate} from "@hookstate/core";
import {Position, ShiftTypeName} from "../../types";
import React, {useEffect} from "react";
import {initRecord} from "../../heka-utils";
import {OptionalInputCheckbox} from "../OptionalInputCheckbox";
import {BorderBox} from "../BorderBox";
import {ExperienceSlider} from "../SignupResumePage";
import {css} from "@emotion/react";
import {InputForm} from "../InputForm";
import {isEqual} from "lodash";

export function ResidencyPaymentSupplements(props: {
  position: State<Position>
}) {
  const residencyState = useHookstate(props.position.residencySupplementPay);
  const shiftTypes = Object.keys(props.position.shiftTypePays.get()) as ShiftTypeName[]
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    residencyState.set(
      e.target.checked ? {
        years: 1,
        shiftTypePays: initRecord(shiftTypes, 0, false)
      } : undefined
    )
  };
  useEffect(() => {
    const actualResidencyState = residencyState.ornull;
    if (!actualResidencyState) return;
    const residencyPays = actualResidencyState.get().shiftTypePays
    const updatedResidencyState = shiftTypes.reduce((acc, shiftTypeName) => {
      if (shiftTypeName in residencyPays) {
        return {...acc, [shiftTypeName]: residencyPays[shiftTypeName]}
      }
      return {...acc, [shiftTypeName]: 0}
    }, {} as Partial<Record<ShiftTypeName, number>>);
    if (isEqual(residencyPays, updatedResidencyState)) return;
    actualResidencyState.shiftTypePays.set(updatedResidencyState)
  }, [props.position.shiftTypePays])
  return <OptionalInputCheckbox label={`Greiðsluálag fyrir lengra komna í sérnámi`} onChange={onCheck}
    isChecked={!!residencyState.get()}>
    {(() => {
      const residencySupplementPays = residencyState.ornull
      if (!residencySupplementPays) return null;
      return <BorderBox>
        <ExperienceSlider label={'Fjöldi ára í sérnámi'} state={residencySupplementPays.years} min={1}/>
        <div css={css`display: flex; flex-direction: column; gap: 8px`}>
          {(Object.keys(residencySupplementPays.shiftTypePays) as ShiftTypeName[]).map(shiftTypeName => {
            const shiftTypePay = residencySupplementPays.shiftTypePays[shiftTypeName];
            return <InputForm state={shiftTypePay} prefix={'kr.'}
              label={`Álag fyrir ${shiftTypeName}`}
              type={'number'}/>;
          })}
        </div>
      </BorderBox>
    })()}
  </OptionalInputCheckbox>
}