import {BasePage} from "./BasePage";
import React, {useEffect, useState} from "react";
import {BorderBox} from "./BorderBox";
import {Badge, Divider, Heading} from "@chakra-ui/react";
import {LabelInfo} from "./LabelInfo";
import {differenceInDays, format, subDays} from "date-fns";
import {DATE_FMT} from "../heka-utils";
import {css} from "@emotion/react";
import {SpecialistRequestList} from "./SpecialistRequestList";
import {Position, PositionRequest} from "../types";
import {useParams} from "react-router-dom";
import {getPositionById} from "../net-utils";
import {useTranslation} from "react-i18next";
import {DefaultSpinner} from "./DefaultSpinner";

export const PositionManagePage = () => {
  const params = useParams();
  const positionId = params.positionId as string | undefined
  if (!positionId) {
    throw new Error('Position id is undefined')
  }
  const [position, setPosition] = useState(null as Position | null);
  useEffect(() => {
    (async () => {
      setPosition(await getPositionById(positionId))
    })()
  }, []);
  if (!position) {
    return <DefaultSpinner/>
  }
  return <BasePage css={css`display: flex; flex-direction: column; gap: 8px`}>
    <ClinicInfo position={position}/>
    <SpecialistRequestList position={position}/>
  </BasePage>;
};

function ClinicInfo(props: {position: Position}) {
  const {t} = useTranslation();
  const position = props.position;
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 8px`}>
    <Heading fontSize={'2xl'}>{position.clinicName!!}</Heading>
    <LabelInfo label={'Tímabil'}>
      <Badge>{format(position.beginDate!!, DATE_FMT)}</Badge>
      -
      <Badge>{format(position.endDate!!, DATE_FMT)}</Badge>
      (Dagar í byrjun: {differenceInDays(position.beginDate!!, new Date())})
    </LabelInfo>
    <Divider/>
    <LabelInfo label={'Staða'}>{t(position.professionType)}</LabelInfo>
    <Divider/>
    <LabelInfo label={'Sérgrein'}>{t(position.specialization)}</LabelInfo>
    <a href={`/positions/${position._id}`} target={'_blank'}>Skoða stöðu</a>
  </BorderBox>
}