import {Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import React from "react";
import {State, useHookstate} from "@hookstate/core";

export const SearchInput = (props: {input: State<string>}) => {
  const state = useHookstate(props.input)
  return <InputGroup>
    <InputLeftElement
      pointerEvents="none"
      children={<SearchIcon/>}
    />
    <Input type={"text"} placeholder={"Leita"} value={state.get()} onChange={e => state.set(e.target.value)}></Input>
  </InputGroup>;
};