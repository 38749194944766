import React, {MutableRefObject, useRef} from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {Input, InputGroup, InputLeftElement, InputRightElement, useColorMode} from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import '../date-picker.css';
import {CalendarIcon, SmallCloseIcon} from "@chakra-ui/icons";
import {css} from "@emotion/react";

function DatePicker(props: ReactDatePickerProps) {
  const {
    isClearable,
    showPopperArrow = false,
    dateFormat = 'dd/MM/yyyy',
    ...rest
  } = props
  const isLight = useColorMode().colorMode==='light';//you can check what theme you are using right now however you want

  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={isLight?"light-theme":"dark-theme"}>
      <InputGroup>
        {!props.inline && <InputLeftElement children={<CalendarIcon color={'gray.500'}/>}/>}
        <ReactDatePicker
          isClearable={false}
          showPopperArrow={showPopperArrow}
          dateFormat={dateFormat}
          className="react-datapicker__input-text"//input is white by default and there is no already defined class for it so I created a new one
          {...rest}
          css={css`text-indent: 18px`}
        />
        {!props.inline && props.selected && props.onChange && (isClearable ?? true) &&
            <InputRightElement css={css`cursor: pointer`} onClick={() => props.onChange!!(null, undefined)}><SmallCloseIcon color={'gray.500'}/></InputRightElement>}
      </InputGroup>
    </div>
  );
}

export {DatePicker}