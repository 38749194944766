import React, {useEffect} from "react";
import {
  Button,
  Checkbox, CheckboxGroup,
  FormControl,
  FormErrorMessage, FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Radio, RadioGroup,
  Stack,
  Table,
  Tbody,
  Td, Th, Thead,
  Tr,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {SmallCloseIcon} from "@chakra-ui/icons";
import { TimeSelect } from "../TimeSelect";
import ColorPicker from "../ColorPicker";
import {Clinic, Institution} from "../../types";
import {DayOfWeek} from "../../heka-utils";
import {WorkerClass} from "../../worker-class";
import {css} from "@emotion/react";

export type ShiftTime = {
  id: string;
  day: DayOfWeek;
  startTime: string;
  endTime: string;
};

export type DynamicShiftType = {
  id: string;
  shiftName: string;
  shiftTimes: ShiftTime[];
  color: string;
  clinicIds: string[] | null;
  professionType: WorkerClass.ProfessionType;
};

const defaultShiftTime: ShiftTime = {
  id: uuidv4(),
  day: "Sunday",
  startTime: "08:00",
  endTime: "08:00",
};

export const ShiftCreationModal = ({isOpen, onClose, initialShift, onSave, institution, clinic}: {
  isOpen: boolean;
  onClose: () => void;
  initialShift?: DynamicShiftType;
  onSave: (shift: DynamicShiftType) => void;
  institution: Institution,
  clinic: Clinic
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
    setValue
  } = useForm<DynamicShiftType>({
    defaultValues: {
      id: "",
      shiftName: "",
      shiftTimes: [],
      color: "green",
      clinicIds: [clinic.clinicId!!],
      professionType: 'Doctor'
    }
  });
  const { fields: shiftTimes, append: appendShiftTime, remove: removeShiftTime } =
    useFieldArray({
      control,
      name: "shiftTimes",
    });

  useEffect(() => {
    if (initialShift && !_.isEqual(initialShift, getValues())) {
      reset(initialShift);
    }
  }, [initialShift]);

  const { t } = useTranslation();
  const clinics = institution.clinics;
  const onSubmit = (data: DynamicShiftType) => {
    if (data.shiftTimes.length === 0) {
      setError("shiftTimes", { type: "required", message: t("Please select at least 1 shift day")!! });
      return;
    }
    if (data.clinicIds?.length === 0) {
      setError("clinicIds", { type: "required", message: t("Please select at least 1 clinic")!! });
      return;
    }
    onSave({...data, id: data.id.length ? data.id : uuidv4()});
    reset();
    onClose();
  };

  const handleAddShiftTime = (day: DayOfWeek) => {
    const existingShiftTime = getValues().shiftTimes[shiftTimes.length - 1];
    const times = existingShiftTime ?
      {startTime: existingShiftTime.startTime, endTime: existingShiftTime.endTime} : {startTime: "08:00", endTime: "08:00"};
    appendShiftTime({ ...defaultShiftTime, id: uuidv4(), day, ...times });
  };

  const handleRemoveShiftTime = (id: string) => {
    removeShiftTime(shiftTimes.findIndex((shiftTime) => shiftTime.id === id));
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const toggleDay = (day: DayOfWeek) => {
    clearErrors("shiftTimes");
    const foundIndex = shiftTimes.findIndex((shiftTime) => shiftTime.day === day);
    if (foundIndex === -1) {
      handleAddShiftTime(day);
    } else {
      handleRemoveShiftTime(shiftTimes[foundIndex].id);
    }
  };

  console.log(getValues())

  const handleClose = () => {
    reset();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"56rem"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{t("Create Shift")}</ModalHeader>
          <ModalCloseButton onClick={handleClose}/>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.shiftName}>
                <Input
                  placeholder={t("Shift name") !!}
                  {...register("shiftName", {
                    required: t("This field must not be empty") !!,
                  })}
                />
                <FormErrorMessage>{errors.shiftName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.shiftTimes}>
                <Stack direction="row" flexWrap={"wrap"}>
                  {daysOfWeek.map((day, index) => (
                    <Checkbox
                      key={index}
                      isChecked={shiftTimes.some((shiftTime) => shiftTime.day === day)}
                      onChange={() => toggleDay(day as DayOfWeek)}
                    >
                      {t(_.startCase(day))}
                    </Checkbox>
                  ))}
                </Stack>
                <FormErrorMessage>{errors.shiftTimes?.message}</FormErrorMessage>
              </FormControl>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{t('Day of week')}</Th>
                    <Th>{t('Start time')}</Th>
                    <Th>{t('End time')}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {_.sortBy(shiftTimes, i => daysOfWeek.indexOf(i.day)).map((shiftTime, index) => (
                    <Tr key={shiftTime.id}>
                      <Td>{t(_.startCase(shiftTime.day))}</Td>
                      <Td>
                        <TimeSelect
                          {...register(`shiftTimes.${index}.startTime`)}
                          defaultValue={"08:00"} includeHalfHours={false}
                        />
                      </Td>
                      <Td>
                        <TimeSelect
                          {...register(`shiftTimes.${index}.endTime`)}
                          defaultValue={"08:00"} includeHalfHours={false}
                        />
                      </Td>
                      <Td>
                        <IconButton
                          variant={"ghost"}
                          aria-label="Remove shift time"
                          icon={<SmallCloseIcon/>}
                          onClick={() => handleRemoveShiftTime(shiftTime.id)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <FormControl>
                <FormLabel>Litur</FormLabel>
                <Controller defaultValue={'green'} name={'color'} control={control} render={({field}) =>
                  <ColorPicker label={watch().shiftName} value={field.value} onChange={field.onChange}/>}/>
              </FormControl>
              <FormControl isInvalid={!!errors.clinicIds}>
                <FormLabel>{t('Clinics')}</FormLabel>
                <Stack direction="row" flexWrap={"wrap"}>
                  <Checkbox onChange={(e) => {
                    if (e.target.checked) {
                      setValue('clinicIds', null)
                    } else {
                      setValue('clinicIds', [])
                    }
                  }} isChecked={watch().clinicIds === null}>
                    {t('All clinics')}
                  </Checkbox>
                  <Controller defaultValue={[]} name={'clinicIds'} control={control}
                    render={({field: {value, ...rest}}) =>
                      <CheckboxGroup value={value ?? []} {...rest}>
                        {clinics.map((clinic) => (
                          <Checkbox key={clinic.clinicId}
                            value={clinic.clinicId}
                            isDisabled={watch().clinicIds === null}
                            isChecked={watch().clinicIds?.includes(clinic.clinicId!!) ?? false}>
                            {clinic.name}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    }/>
                </Stack>
                <FormErrorMessage>{errors.clinicIds?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>
                  {t('Profession type')}
                </FormLabel>
                <Controller defaultValue={WorkerClass.professionTypes[0]} name={'professionType'} control={control}
                  render={({field}) => <RadioGroup {...field} css={css`display: flex; gap: 8px`}>
                    {WorkerClass.professionTypes.map((professionType) => <Radio value={professionType}>
                      {t(professionType)}
                    </Radio>)}
                  </RadioGroup>}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="pink" mr={3} type="submit">
              {t("Save")}
            </Button>
            <Button variant="ghost" onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
