import specializations from './resources/specializations.json'

const international = require('sort-international');

export namespace WorkerClass {
  export const professionTypes = ['Doctor', 'Nurse', 'Midwife'] as const;
  export const classes = ['Student', 'Standard', 'Specialist'] as const;
  export type ProfessionType = typeof professionTypes[number];
  export type ClassType = typeof classes[number];

  interface ValueSource {
    icelandic: string;
    english: string;
  }

  export const typeNameTranslations: Record<ProfessionType, ValueSource> = {
    Doctor: {
      english: 'Medical doctor',
      icelandic: 'Læknir'
    },
    Nurse: {
      english: 'Specialized nurse',
      icelandic: 'Hjúkrunarfræðingur'
    },
    Midwife: {
      english: 'Midwife',
      icelandic: 'Ljósmóðir'
    }
  }

  export const icelandicToTypeName = (icelandic: string): ProfessionType => {
    const value = (Object.keys(typeNameTranslations) as ProfessionType[])
        .find(key => typeNameTranslations[key].icelandic === icelandic);
    if (!value) {
      throw new Error(`No type name found for icelandic value ${icelandic}`);
    }
    return value
  }

  export function getTypeNameTranslation(type: ProfessionType) {
    return typeNameTranslations[type]['icelandic']
  }

  interface Data {
    achievements: readonly ValueSource[];
    specializations: readonly ValueSource[];
  }

  type Datas = Record<ProfessionType, Data>;

  export const datas: Datas = {
    'Doctor': {
      achievements: [
        { icelandic: 'Læknir', english: 'Standard' },
        { icelandic: 'Læknanemi með tímabundið lækningaleyfi', english: 'Student' },
        { icelandic: 'Sérfræðingur', english: 'Specialist' }

      ] as const,
      specializations: [...specializations.Doctor].sort(international('icelandic'))
    },
    'Nurse': {
      achievements: [
        { icelandic: 'Hjúkrunarfræðingur', english: 'Standard' },
        { icelandic: 'Hjúkrunarfræðinemi', english: 'Student' },
        { icelandic: 'Hjúkrunarfræðingur með sérfræðileyfi', english: 'Specialist' }
      ] as const,
      specializations: [...specializations.Nurse].sort(international('icelandic'))
    },
    'Midwife': {
      achievements: [
        { icelandic: 'Ljósmóðir', english: 'Standard' },
        { icelandic: 'Ljósmóðurnemi', english: 'Student' },
        { icelandic: 'Ljósmóðir með sérfræðileyfi', english: 'Specialist' }
      ] as const,
      specializations: [...specializations.Midwife].sort(international('icelandic'))
    }
  }

  export function getSpecializations(type: ProfessionType) {
    return datas[type].specializations
  }

}