import {Link, useParams} from 'react-router-dom';
import {BorderBox} from './BorderBox';
import {css, SerializedStyles} from '@emotion/react';
import {
  Alert, AlertIcon,
  Avatar,
  Badge,
  Button,
  Heading,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, position,
  Text
} from '@chakra-ui/react';
import {AddIcon, TimeIcon} from '@chakra-ui/icons';
import {BasePage} from './BasePage';
import React, {useEffect, useState} from 'react';
import {Institution, Position, User} from "../types";
import {DefaultSpinner} from "./DefaultSpinner";
import {
  createAdminInvite,
  createClinic,
  getEmployeesForInstitutionId,
  getInstitutionById,
  getPositionsForInstitution
} from "../net-utils";
import {State, useHookstate} from "@hookstate/core";
import {ClinicInputForm, createBlankClinic, UpdateInstitutionModal} from "./AdminPage";
import {useTranslation} from "react-i18next";
import {formatDate, getUserProfessionsFormatted} from "../heka-utils";
import {ClinicPosition} from "./ClinicPage";
import GrayText from "./GrayText";
import {AiOutlineUserAdd} from "react-icons/ai";
import {InputForm} from "./InputForm";
import {validateEmail} from "../validations";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import {BiCog} from "react-icons/bi";
import {isAfter} from "date-fns";
import {HiOutlineDocumentAdd} from "react-icons/hi";

export function InstitutionPage() {
  const params = useParams()
  const institutionId = params.institutionId
  if (!institutionId) {
    throw new Error('institutionId is required')
  }
  const [institution, setInstitution] = useState<Institution | null>(null)
  const refresh = async () => {
    const institution = await getInstitutionById(institutionId)
    setInstitution(institution)
  }
  useEffect(() => {
    (async () => {
      await refresh()
    })()
  }, [institutionId])

  const [positions, setPositions] = useState(null as Position[] | null);
  if (!institution) {
    return <DefaultSpinner/>
  }
  return <BasePage>
    <div css={css`display: flex;
      flex-direction: column;
      gap: 8px`}>
      <Heading fontSize={'3xl'} textAlign={'center'}>{institution.name} ({institution.nameAbbr})</Heading>
      <NewRequestsAlert positions={positions ?? []}/>
      <AdPanel institution={institution} refresh={refresh}/>
      <LocationOverview institution={institution}/>
      <SpecialistList institutionId={institutionId}/>
      <NewPositionsList institutionId={institutionId} positions={positions} setPositions={setPositions}/>
    </div>
  </BasePage>;
}

export function NewRequestsAlert(props: {positions: Position[]}) {
  const pendingPositions = props.positions.filter(p => (p.requestCount ?? 0) > 0 && !p.isAccepted && isAfter(p.endDate!, new Date()));
  if (!pendingPositions.length) {
    return null;
  }
  return <AlertDisplay status={'info'}>
    <Heading size={'sm'}>Nýjar umsóknir</Heading>
    <div css={css`display: flex; gap: 8px; flex-direction: column`}>
      {pendingPositions.map(p => <div key={p._id}>
        <Link to={`/positions/${p._id}/manage`}>
          <Text css={css`&:hover {text-decoration: underline}`}>{p.clinicName} {formatDate(p.beginDate!)} - {formatDate(p.endDate!)} ({p.requestCount}) - Yfirfara</Text>
        </Link>
      </div>)}

    </div>
  </AlertDisplay>;
}

function LocationItem(props: {
  locationName: string,
  numAds: number,
  numApplications: number,
  img?: string,
  clinicId: string,
  institutionId: string
}) {
  const pluralApplications = props.numApplications === 1 ? 'Umsókn' : 'Umsóknir';
  const pluralAds = props.numAds === 1 ? 'Auglýsing' : 'Auglýsingar';
  return <Link to={`/institutions/${props.institutionId}/clinics/${props.clinicId}`}>
      <BorderBox css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        max-width: 350px;
        &:hover [data-comp="name-header"] {text-decoration: underline}
      `}
    >
      <img css={css`width: 100%;
        height: 160px;
        background-color: #a0aec0; object-fit: cover; border-radius: 4px`} src={`${props.img}`}/>
      <Heading fontSize={'lg'} data-comp={'name-header'}>{props.locationName}</Heading>
      <div css={css`display: flex;
        gap: 4px; flex-wrap: wrap`}>
        {props.numAds ? <Badge colorScheme={'purple'}>{props.numAds} {pluralAds}</Badge> : <Badge colorScheme={'gray'}>0 auglýsingar</Badge>}
        {props.numApplications ? <Badge colorScheme={'green'}>{props.numApplications} {pluralApplications}</Badge> : <Badge colorScheme={'gray'}>0 umsóknir</Badge>}
      </div>
    </BorderBox>
  </Link>;
}

export function SpecialistListItem(props: { name: string, img?: string, specializations: string, username: string
  onClick?: () => void; css?: SerializedStyles; className?: string }) {
  const component = <BorderBox className={props.className}>
    <div css={css`display: flex; flex-direction: row; align-items: center; gap: 12px;`}>
      <Avatar src={props.img} name={props.name}/>
      <div css={css`display: flex; flex-direction: column;`}>
        <Heading size={'md'}>{props.name}</Heading>
        <Text css={css`color: darkgray; font-style: italic`}>{props.specializations}</Text>
      </div>
    </div>
  </BorderBox>;
  return props.onClick ? <div css={css`cursor: pointer;`} onClick={props.onClick}>{component}</div>
    : <Link to={`/users/${props.username}`}>{component}</Link>;
}

const SpecialistList = (props: {institutionId: string}) => {
  const [employees, setEmployees] = useState(null as User[] | null);
  const {t} = useTranslation();
  const {institutionId} = props
  useEffect(() => {
    (async () => {
      const employees = await getEmployeesForInstitutionId(institutionId)
      setEmployees(employees)
    })()
  }, [institutionId]);
  if (!employees) {
    return <DefaultSpinner/>
  }
  return <div>
    <Heading fontSize={'2xl'} css={css`margin-bottom: 6px;`}>Fyrri verktakar</Heading>
    <BorderBox css={css`max-height: 400px;
      overflow-y: scroll`}>
      <div css={css`display: flex;
        gap: 4px;
        flex-direction: column;`}>
        {employees.length === 0 ? (<GrayText>Engir fyrri verktakar</GrayText>) : employees.map((employee) =>
          <SpecialistListItem name={employee.electronicId.name}
            img={employee.preferences.links.picture}
            specializations={getUserProfessionsFormatted(employee, t)}
            username={employee.username}
            key={employee.username}
          />
        )}
      </div>
    </BorderBox>
  </div>;
};

function NewPositionsList(props: {institutionId: string, positions: Position[] | null, setPositions: (positions: Position[]) => void}) {
  const {institutionId, positions, setPositions} = props;
  const refresh = () => {
    getPositionsForInstitution(institutionId).then(setPositions)
  };
  useEffect(refresh, [institutionId]);
  if (!positions) {
    return <DefaultSpinner/>
  }
  return <div>
    <Heading fontSize={'2xl'} css={css`margin-bottom: 6px;`}>Nýjustu auglýsingar</Heading>
    <BorderBox css={css`max-height: 400px; overflow-y: scroll`}>
    <div css={css`display: flex;
      gap: 4px;
      flex-direction: column;`}>
      { !positions.length ? <GrayText>Engar auglýsingar</GrayText> :
        positions.map((position) => <ClinicPosition key={position._id} position={position} refresh={refresh} showClinicName={true}/>)
      }
    </div>
  </BorderBox></div>
}

function LocationOverview(props: {
  institution: Institution
}) {
  const institution = props.institution
  return <div>
    <Heading fontSize={'2xl'} css={css`margin-bottom: 8px`}>Starfsstöðvar</Heading>
    <BorderBox css={css`display: flex;
    flex-direction: column`}>
    <div css={css`
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      gap: 8px;
    `}>
      {
        !institution.clinics.length ? <GrayText>Engar starfsstöðvar</GrayText> : institution.clinics.map(clinic => {
          const clinicId = clinic.clinicId
          const institutionId = institution.institutionId
          if (!clinicId || !institutionId)
            throw new Error(`Clinic id for ${clinic.name} is null`)
          return <LocationItem
            locationName={clinic.name}
            numAds={clinic.openPositionCount ?? 0}
            numApplications={clinic.requestCount ?? 0}
            img={clinic.primaryImagePath}
            clinicId={clinicId}
            institutionId={institutionId}
            key={clinicId}
          />;
        })
      }
    </div>
  </BorderBox></div>;
}

function InviteAdminModal(props: {institutionId: string, open: State<boolean>}) {
  const {institutionId, open} = props
  const close = () => open.set(false)
  const email = useHookstate('')
  const submitError = useHookstate(null as string | null)
  return <Modal isOpen={open.get()} onClose={close}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Bjóða nýjum stjórnanda</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 8px; padding-bottom: 20px`}>
        <Alert status={'info'}>
          <AlertIcon/>
          Með því að bjóða nýjum stjórnenda fær hann réttindi til að færa inn auglýsingar, eyða auglýsingum og samþykkja umsóknir.<br/>
          Einstaklingur fær tölvupóst með tengli til að stofna aðgang og auðkennist með rafrænum skilríkjum.
        </Alert>
        <InputForm label={'Netfang'} placeholder={'Netfang'} type={'email'} validate={validateEmail} state={email}/>
        <AlertDisplay error={submitError}/>
        <GoButton onClick={async () => {
          await createAdminInvite(email.get(), institutionId)
          close()
        }} submitError={submitError}>
          Bjóða
        </GoButton>
      </ModalBody>
    </ModalContent>
  </Modal>
}

function AdPanel(props: {
  institution: Institution,
  refresh: () => void
}) {
  const createClinicModalOpen = useHookstate(false)
  const inviteModalOpen = useHookstate(false)
  const settingsModalOpen = useHookstate(false)
  return <BorderBox>
    <div css={css`display: flex;
          gap: 8px; flex-wrap: wrap;`}>
      {!!props.institution.clinics.length && <Link to={`/institutions/${props.institution.institutionId}/positions/create`} state={props.institution}>
        <Button colorScheme={'green'} leftIcon={<AddIcon/>}>Auglýsa stöðu</Button>
      </Link>}
      <Button leftIcon={<AddIcon/>} onClick={() => createClinicModalOpen.set(true)}>Bæta við starfsstöð</Button>
      <Link to={`/institutions/${props.institution.institutionId}/outsider-contract`}><Button leftIcon={<HiOutlineDocumentAdd/>}>Nýr samningur</Button></Link>
      <Button leftIcon={<AiOutlineUserAdd/>} onClick={() => inviteModalOpen.set(true)}>Bjóða nýjum stjórnanda</Button>
      <Button leftIcon={<BiCog/>} onClick={() => settingsModalOpen.set(true)}>Stillingar</Button>
    </div>
    <CreateClinicModal open={createClinicModalOpen} institutionId={props.institution.institutionId!!} refresh={props.refresh}/>
    <InviteAdminModal institutionId={props.institution.institutionId!!} open={inviteModalOpen}/>
    {settingsModalOpen.get() && <UpdateInstitutionModal open={settingsModalOpen} institution={props.institution}
        onSubmit={() => window.location.reload()} disallowChangeVisibility/>}
  </BorderBox>;
}

function CreateClinicModal(props: {institutionId: string, open: State<boolean>, refresh: () => void}) {
  const blankClinic = useHookstate(createBlankClinic())
  const submitError = useHookstate(null as string | null)
  return <Modal isOpen={props.open.get()} onClose={() => props.open.set(false)}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Bæta við starfsstöð</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 8px; margin-bottom: 16px`}>
        <ClinicInputForm clinic={blankClinic} institutionId={props.institutionId}/>
        <div>
          <AlertDisplay error={submitError}/>
          <GoButton colorScheme={'green'} onClick={async () => {
            await createClinic(props.institutionId, blankClinic.get())
            blankClinic.set(createBlankClinic())
            props.open.set(false)
            props.refresh()
          }} submitError={submitError}>
            Bæta við
          </GoButton>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
}
