import React, {useEffect, useState} from "react";
import {BasePage} from "./BasePage";
import {BorderBox} from "./BorderBox";
import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {formatCurrency, formatDate, getUsername, withCheckboxState} from "../heka-utils";
import {css} from "@emotion/react";
import {DateInputForm, InputForm} from "./InputForm";
import {State, useHookstate} from "@hookstate/core";
import {Link, useParams} from "react-router-dom";
import {HalfSection, SectionContainer} from "./SectionContainer";
import {HR} from "./HR";
import {
  createInvoice,
  getInstitutionById,
  getInvoiceTemplateForPositionId,
  getPositionById,
  getUserPaymentInfos,
  invalidateInvoice
} from "../net-utils";
import {Institution, PaymentInfo, Position} from "../types";
import {DefaultSpinner} from "./DefaultSpinner";
import {
  validateAccount,
  validateBankId,
  validateEmail,
  validateHB, validateIban,
  validateNotEmpty,
  validatePhone,
  validatePostalNumber,
  validateSSN, validateSwiftBic
} from "../validations";
import {ArrowForwardIcon, ChevronDownIcon, DeleteIcon} from "@chakra-ui/icons";
import AlertDisplay from "./AlertDisplay";
import {GoButton} from "./GoButton";

const UnitTable = (props: {
  paymentUnits: Invoices.PaymentUnit[],
  petrolPaid: boolean,
  petrolPrice?: number,
  travelDayPaid: boolean,
  travelDayPrice?: number,
}) => {
  const {paymentUnits} = props;
  const petrolUnit = props.petrolPaid && {
    name: 'Bensínkostnaður',
    amount: 1,
    unitPrice: props.petrolPrice ?? 0,
  };
  const travelDayUnit = props.travelDayPaid && {
    name: 'Ferðadagur',
    amount: 1,
    unitPrice: props.travelDayPrice ?? 0,
  }
  const paymentUnitsWithExtras = [...paymentUnits, petrolUnit, travelDayUnit]
    .filter(unit => unit && unit.amount !== 0) as Invoices.PaymentUnit[];
  const totalPayment = paymentUnitsWithExtras.reduce((acc, unit) => acc + unit.unitPrice * unit.amount, 0)
  return <TableContainer>
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th></Th>
          <Th>Fjöldi eininga</Th>
          <Th isNumeric>Einingaverð</Th>
          <Th isNumeric>Upphæð</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          paymentUnitsWithExtras.map((unit, i) => <Tr key={i}>
            <Td>{unit.name}</Td>
            <Td>{unit.amount}</Td>
            <Td isNumeric>{formatCurrency(unit.unitPrice)}</Td>
            <Td isNumeric>{formatCurrency(unit.unitPrice * unit.amount)}</Td>
          </Tr>)
        }
      </Tbody>
      <Tfoot>
        <Tr>
          <Td css={css`font-weight: bold`}>Samtalsgreiðsla</Td>
          <Td></Td>
          <Td></Td>
          <Td isNumeric css={css`font-weight: bold`}>{formatCurrency(totalPayment)}</Td>
        </Tr>
      </Tfoot>
    </Table>
  </TableContainer>;
};

export const InvoicePage = () => {
  const invoiceTemplate = useHookstate(null as Invoices.Invoice | null);
  const positionId = useParams().positionId ?? (() => {throw new Error("Missing positionId")})();
  const [position, setPosition] = useState(null as Position | null);
  const submitError = useHookstate(null as string | null);
  const institution = useHookstate(null as Institution | null);
  const refreshInvoice = async () => invoiceTemplate.set(await getInvoiceTemplateForPositionId(positionId));
  useEffect(() => {
    (async () => {
      await refreshInvoice();
    })();
    (async () => {
        const position = await getPositionById(positionId);
        setPosition(position);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const invoice = invoiceTemplate.get();
      if (invoice) {
        institution.set(await getInstitutionById(invoice.institutionId))
      }
    })();
  }, [invoiceTemplate])
  if (!invoiceTemplate.get() || !position) return <DefaultSpinner/>;
  const template = invoiceTemplate.ornull!!
  const sent = template.sent.get();
  const invalid = template.invalid.get();
  const domesticBankDetails = template.details.domesticBankDetails.ornull;
  const internationalBankDetails = template.details.internationalBankDetails.ornull;
  return <BasePage>
    <BorderBox css={css`display: flex;
      flex-direction: column;
      gap: 8px`}>
      <div>
        <Heading fontSize={'2xl'}>Rafrænn reikningur</Heading>
        <Heading fontSize={'xl'} fontWeight={'normal'}>Reikningsnr. {template.invoiceId.get() ?? template.contractId.get()}</Heading>
      </div>
      {!sent && <Alert status={'info'}>
        <AlertIcon/>
        <Text>
          Þessi reikningur hefur verið útbúinn fyrir afleysingu þína hjá <b>{position.institutionName!!}</b> fyrir
          tímabilið <b>{formatDate(position.beginDate!!)}</b> - <b>{formatDate(position.endDate!!)}</b>.
          Mikilvægt er að yfirfara upplýsingar sem koma hér fram
          áður en reikningur er sendur. Hægt er gera breytingar í öllum reitum. Reikningur er
          sendur á viðeigandi heilbrigðisstofnun og vistast sem PDF skjal á þínum síðum.
        </Text>
      </Alert>}
      <div>
        <Link target={'_blank'} to={`/positions/${positionId}`}>Skoða stöðu</Link>
      </div>
      {sent && !invalid && template.sentAt.get() && <Alert status={'success'}>
        <AlertIcon/>
        <Text>
          Þessi reikningur var sendur <b>{formatDate(template.sentAt.get()!!)}</b>
        </Text>
      </Alert>}
      <Heading fontSize={'xl'}>Seljandi</Heading>
      {!invalid && <SelectPaymentInfos invoiceState={template}/>}
      <SectionContainer>
        <HalfSection>
          <InputForm label={'Kennitala'} state={template.details.sellerDetails.ssn} validate={validateSSN}/>
          <InputForm label={'Nafn'} state={template.details.sellerDetails.name} validate={validateNotEmpty}/>
          <InputForm label={'Heimilisfang'} state={template.details.sellerDetails.address} validate={validateNotEmpty}/>
        </HalfSection>
        <HalfSection>
          <InputForm label={'Póstnúmer'} state={template.details.sellerDetails.postalCode} validate={validatePostalNumber}/>
          <InputForm label={'Sími'} state={template.details.sellerDetails.phone} validate={validatePhone}/>
          <InputForm label={'Netfang'} state={template.details.sellerDetails.email} validate={validateEmail}/>
        </HalfSection>
      </SectionContainer>
      <HR/>
      <Heading fontSize={'xl'}>Kaupandi</Heading>
      <div css={css`display: flex;
        gap: 8px`}>
        <HalfSection>
          <InputForm isDisabled label={'Kennitala'} state={template.details.buyerDetails.ssn} validate={validateSSN}/>
          <DateInputForm label={'Útgáfudagur reiknings'} state={template.details.buyerDetails.issueDate}/>
          <InputForm label={'Tengiliður'} state={template.details.buyerDetails.contact} validate={validateNotEmpty}/>
        </HalfSection>
        <HalfSection>
          <InputForm isDisabled label={'Nafn'} state={template.details.buyerDetails.name} validate={validateNotEmpty}/>
          <DateInputForm label={'Gjalddagi'} state={template.details.buyerDetails.dueDate}
            subtext={'Skv. fyrirmælum Fjársýslunnar er gjalddagi skráður 25 dögum eftir útgáfudag.'}/>
        </HalfSection>
      </div>
      <HR/>
      <Heading fontSize={'xl'}>Greiðsluupplýsingar</Heading>
      {domesticBankDetails ? <div css={css`display: flex;
        gap: 8px`}>
        <InputForm label={'Banki'} css={css`flex: 1`} state={domesticBankDetails.bankId}
          validate={validateBankId}/>
        <InputForm label={'Höfuðbók'} css={css`flex: 1`} state={domesticBankDetails.hb} validate={validateHB}/>
        <InputForm label={'Reikningsnúmer'} css={css`flex: 4`} state={domesticBankDetails.account}
          validate={validateAccount}/>
      </div> : internationalBankDetails ? <div css={css`display: flex; gap: 8px`}>
        <InputForm label={'IBAN'} css={css`flex: 1`} state={internationalBankDetails.iban}
          validate={validateIban}/>
        <InputForm label={'BIC'} css={css`flex: 1`} state={internationalBankDetails.bicCode}
          validate={validateSwiftBic}/>
      </div> : null}
      {/*<FormControl>*/}
      {/*  <FormLabel>Lýsing</FormLabel>*/}
      {/*  <Textarea value={template.details.description.get()} onChange={e => template.details.description.set(e.target.value)}>*/}
      {/*  </Textarea>*/}
      {/*</FormControl>*/}
      <HR/>
      {institution.get()?.petrolPayment && <>
          <Checkbox {...withCheckboxState(template.petrolPaid)}>Bensínkostnaður greiddur</Checkbox>
      </>}
      {position.travelDayCostPaid && <Checkbox {...withCheckboxState(template.travelDayPaid)}>
        Ferðadagur greiddur
      </Checkbox>}
      <HR/>
      <Heading fontSize={'xl'}>Greiðsla</Heading>
      <UnitTable paymentUnits={template.details.paymentUnits.get()}
        petrolPaid={template.petrolPaid.get()} petrolPrice={institution.get()?.petrolPayment}
        travelDayPaid={template.travelDayPaid.get()} travelDayPrice={position.travelDayCost}/>
      {submitError.get() && <AlertDisplay error={submitError}/>}
      <div css={css`display: flex; gap: 8px`}>
        <GoButton colorScheme={'green'} onClick={async () => {
          const errors = validateInvoice(template.get());
          if (errors.length) {
            submitError.set(errors.join(", "));
            return;
          }
          submitError.set(null);
          await createInvoice(template.get());
          await refreshInvoice();
        }} submitError={submitError} leftIcon={<ArrowForwardIcon/>}>
          {sent && !invalid ? 'Uppfæra reikning' : 'Senda reikning'}
        </GoButton>
        {sent && !invalid && <GoButton colorScheme={'red'} variant={'outline'} onClick={async () => {
          if (window.confirm("Ertu viss um að þú viljir afturkalla þennan reikning?")) {
            await invalidateInvoice(template.positionId.get())
            await refreshInvoice();
          }
        }} submitError={submitError} leftIcon={<DeleteIcon/>}>
          Afturkalla reikning
        </GoButton>}
      </div>
    </BorderBox>
  </BasePage>;
};

function SelectPaymentInfos(props: {
  invoiceState: State<Invoices.Invoice>
}) {
  const state = useHookstate(props.invoiceState);
  const [paymentInfos, setPaymentInfos] = useState(null as PaymentInfo[] | null);
  useEffect(() => {
    getUserPaymentInfos(getUsername()).then(setPaymentInfos)
  }, []);
  const domesticBankDetails = state.details.domesticBankDetails.ornull;
  const internationalBankDetails = state.details.internationalBankDetails.ornull;
  if (!paymentInfos) {
    return <DefaultSpinner/>;
  }
  if (!paymentInfos.length) return null;
  const findPaymentInfo = (paymentInfo: PaymentInfo) => paymentInfo.account === domesticBankDetails?.account.get()
    || paymentInfo.iban === internationalBankDetails?.iban.get();
  return <div><Menu>
    <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>{
      paymentInfos.find(findPaymentInfo)?.name ?? 'Velja seljanda'
    }</MenuButton>
    <MenuList>
      {paymentInfos.map(paymentInfo => <MenuItem onClick={() => {
        if (domesticBankDetails) {
          domesticBankDetails.set({
            account: paymentInfo.account!,
            bankId: paymentInfo.bankId!,
            hb: paymentInfo.hb!
          });
        } else if (internationalBankDetails) {
          internationalBankDetails.set({
            iban: paymentInfo.iban!,
            bicCode: paymentInfo.bicCode!
          });
        }
        state.details.sellerDetails.set({
          ...state.details.sellerDetails.get(),
          ssn: paymentInfo.ssn,
          name: paymentInfo.name,
          address: paymentInfo.homeAddress,
          postalCode: paymentInfo.postalNumber,
        })
      }}>
        {paymentInfo.name}
      </MenuItem>)}
    </MenuList>
  </Menu></div>
}

export namespace Invoices {
  interface SellerDetails {
    ssn: string;
    name: string;
    address: string;
    postalCode: string;
    phone: string;
    email: string;
  }

  interface BuyerDetails {
    ssn: string;
    name: string;
    contact: string;
    dueDate: Date;
    issueDate: Date;
  }

  interface DomesticBankDetails {
    bankId: string;
    hb: string;
    account: string;
  }

  interface InternationalBankDetails {
    iban: string;
    bicCode: string;
  }

  export interface PaymentUnit {
    name: string;
    amount: number;
    unitPrice: number;
  }

  interface InvoiceDetails {
    sellerDetails: SellerDetails;
    buyerDetails: BuyerDetails;
    domesticBankDetails?: DomesticBankDetails;
    internationalBankDetails?: InternationalBankDetails;
    paymentUnits: PaymentUnit[];
  }

  export interface Invoice {
    _id?: string;
    type: 'Invoice' | 'CreditNote'
    employeeUsername: string;
    institutionId: string;
    positionId: string;
    contractId: string;
    invoiceId: string;
    details: InvoiceDetails;
    sent: boolean;
    sentAt: Date | null;
    petrolPaid: boolean;
    travelDayPaid: boolean;
    invalid: boolean;
  }
}

function validateInvoice(invoice: Invoices.Invoice): string[] {
  const validations = [
    validateSSN(invoice.details.sellerDetails.ssn),
    validateNotEmpty(invoice.details.sellerDetails.name),
    validateNotEmpty(invoice.details.sellerDetails.address),
    validatePostalNumber(invoice.details.sellerDetails.postalCode),
    validatePhone(invoice.details.sellerDetails.phone),
    validateEmail(invoice.details.sellerDetails.email),
    validateSSN(invoice.details.buyerDetails.ssn),
    validateNotEmpty(invoice.details.buyerDetails.name),
    validateNotEmpty(invoice.details.buyerDetails.contact),
  ];
  if (invoice.details.domesticBankDetails) {
    validations.push(
      validateBankId(invoice.details.domesticBankDetails.bankId),
      validateHB(invoice.details.domesticBankDetails.hb),
      validateAccount(invoice.details.domesticBankDetails.account)
    );
  }
  if (invoice.details.internationalBankDetails) {
    validations.push(
      validateIban(invoice.details.internationalBankDetails.iban),
      validateSwiftBic(invoice.details.internationalBankDetails.bicCode)
    );
  }
  return validations.filter(validation => validation !== null) as string[];
}
