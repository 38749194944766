import {addDays, differenceInDays, differenceInYears, format} from "date-fns";
import {ChangeEvent, ChangeEventHandler, useEffect, useState} from 'react';
import {
  JwtData, PhoneNumber,
  Position,
  Profession, ResidencySupplementPay,
  Shift,
  ShiftPays,
  ShiftTypeName,
  ShiftTypeTime,
  User,
  WorkerClassPays
} from './types';
import {State} from '@hookstate/core';
import structuredClone from '@ungap/structured-clone';
import {WorkerClass} from "./worker-class";
import { partition, minBy, maxBy } from 'lodash';
import _ from "lodash";
import phoneCodes from './resources/phone-codes.json'

export const DATE_FMT = "dd/MM/yyyy"
export const DATE_FMT_FULL = "dd. MMMM yyyy"


export function getDaysInRange(beginDate: Date, endDate: Date) {
  const diff = differenceInDays(endDate, beginDate) + 1;
  return _.range(0, diff, 1).map(d => addDays(beginDate, d))
}

export function translateDateDayName(str: string) {
  switch (str) {
    case "Monday":
      return "Mánudagur"
    case "Tuesday":
      return "Þriðjudagur"
    case "Wednesday":
      return "Miðvikudagur"
    case "Thursday":
      return "Fimmtudagur"
    case "Friday":
      return "Föstudagur"
    case "Saturday":
      return "Laugardagur"
    case "Sunday":
      return "Sunnudagur"
  }
  return ""
}

function translateDateMonthName(month: string) {
  switch (month) {
    case "January": return "Janúar"
    case "February": return "Febrúar"
    case "March": return "Mars"
    case "April": return "Apríl"
    case "May": return "Maí"
    case "June": return "Júní"
    case "July": return "Júlí"
    case "August": return "Ágúst"
    case "September": return "September"
    case "October": return "Október"
    case "November": return "Nóvember"
    case "December": return "Desember"
  }
  return "";
}

function translateDateString(dateString: string): string {
  const months = {
    "January": "Janúar",
    "February": "Febrúar",
    "March": "Mars",
    "April": "Apríl",
    "May": "Maí",
    "June": "Júní",
    "July": "Júlí",
    "August": "Ágúst",
    "September": "September",
    "October": "Október",
    "November": "Nóvember",
    "December": "Desember"
  };

  return Object.entries(months).reduce((result, [englishMonth, icelandicMonth]) => {
    return result.replace(englishMonth, icelandicMonth);
  }, dateString);
}

export function formatDateFull(date: Date) {
  return translateDateString(format(date, DATE_FMT_FULL)).toLowerCase();
}

export function getDayNameShort(date: Date) {
  return translateDateDayName(date.toLocaleDateString("en-US", {weekday: 'long'})).substr(0, 3);
}

export function getYearsInResidency(user: User, professionType: WorkerClass.ProfessionType) {
  const userProfession = user.preferences.professions.filter(p => p.type === professionType)[0];
  if (!userProfession) return 0;
  const {residencyDate} = userProfession;
  if (!residencyDate) return 0;
  return differenceInYears(new Date(), residencyDate);
}

export function getSupplementalPayForShiftType(position: Position, shiftType: ShiftTypeName) {
  const {residencySupplementPay} = position;
  if (!residencySupplementPay) return 0;
  const {shiftTypePays} = residencySupplementPay;
  const pay = shiftTypePays[shiftType];
  return pay ?? 0;
}

export function getTotalSupplementalPay(position: Position) {
  const {residencySupplementPay} = position;
  if (!residencySupplementPay) return 0;
  const {shiftTypePays} = residencySupplementPay;
  const result = Object.entries(shiftTypePays).reduce((previousValue, [shiftType, pay]) => {
    const shiftCount = position.shifts.filter(s => s.types.includes(shiftType as ShiftTypeName)).length;
    return previousValue + (pay ?? 0) * shiftCount;
  }, 0);
  return !isNaN(result) ? result : 0;
}

export function getSupplementalPay(user: User, position: Position, shiftType: ShiftTypeName) {
  const {residencySupplementPay} = position;
  if (!residencySupplementPay) return 0;
  const yearsInResidency = getYearsInResidency(user, position.professionType);
  if (yearsInResidency < residencySupplementPay.years) return 0;
  return getSupplementalPayForShiftType(position, shiftType);
}

export function calculatePayForPositionAndClass(position: Position, workerClass: WorkerClass.ClassType, user?: User) {
  const shifts = position.shifts;
  const shiftTypePays = position.shiftTypePays;
  const getHighestPay = (pays: WorkerClassPays) => findAppropriatePayment(pays, workerClass)
  const result = Object.keys(shiftTypePays).reduce((previousValue, key) => {
    const shiftName = key as ShiftTypeName;
    const numShifts = shifts.filter(s => s.types.includes(shiftName)).length;
    const workerClassPays = shiftTypePays[shiftName]!!;
    const shiftPay = getHighestPay(workerClassPays);
    const supplement = user ? getSupplementalPay(user, position, shiftName) : 0
    return previousValue + (shiftPay + supplement) * numShifts
  }, 0);
  if (isNaN(result))
    return 0;
  return result
}

export function calculatePayForPosition(position: Position, user: User) {
  const {shifts, shiftTypePays, residencySupplementPay} = position;
  const workerClass = getHighestWorkerClass(user, position.professionType);
  return calculatePayForPositionAndClass(position, workerClass, user);
}

export function findAppropriatePayment(payments: WorkerClassPays, workerClass: WorkerClass.ClassType): number {
  const payment = payments[workerClass];
  if (payment) {
    return payment;
  }
  const availableRoles = Object.keys(payments) as WorkerClass.ClassType[];

  const [lowerRoles, higherRoles] = partition(
    availableRoles,
    role => WorkerClass.classes.indexOf(role) < WorkerClass.classes.indexOf(workerClass)
  );

  const closestHigherRole = minBy(higherRoles, role => WorkerClass.classes.indexOf(role));
  const closestLowerRole = maxBy(lowerRoles, role => WorkerClass.classes.indexOf(role));

  if (closestHigherRole) {
    return payments[closestHigherRole]!!;
  } else if (closestLowerRole) {
    return payments[closestLowerRole]!!;
  } else {
    throw new Error(`No payments found for any role in ${JSON.stringify(payments)}`);
  }
}

export function formatCurrency(n: number) {
  return `${n.toLocaleString("de-DE")} kr.`
}

function getWindowDimensions() {
  let { width, height } = window.screen;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function initRecord<KeyType extends readonly string[], ValueType>(keys: KeyType, value: ValueType, copy = true): Record<KeyType[number], ValueType> {
  let obj: any = {}
  keys.forEach(k => obj[k] = copy ? {...value} : value)
  return obj
}

export function getClockHours(includeHalfHours: boolean = true) {
  return _.range(0, 24).map(value => value < 10 ? "0" + value : String(value))
    .flatMap(value => includeHalfHours ? [value, value] : [value])
    .map((value, index) => includeHalfHours ? `${value}:${index % 2 === 0 ? '00' : '30'}` : `${value}:00`)
}

export function onSelect<T extends string>(state: State<T> | State<T | undefined>): ChangeEventHandler<HTMLSelectElement> {
  return (e => state.set(e.target.value as T))
}

export function onCheckbox(state: State<boolean>): ChangeEventHandler<HTMLInputElement> {
  return (e => state.set(e.target.checked))
}

export function withCheckboxState(state: State<boolean>) {
  return {
    onChange: (e: ChangeEvent<HTMLInputElement>) => state.set(e.target.checked),
    isChecked: state.get()
  }
}

// format 7 digit phone number to xxx-xxxx
export function formatPhone(phone: string) {
  return `${phone.substr(0, 3)}-${phone.substr(3)}`
}

// return colorscheme of teal, blue, pink, purple, cyan for a string deterministically
export function stringToColor(str: string) {
  const hash = str.split('').reduce((previousValue, currentValue) => {
    return previousValue + currentValue.charCodeAt(0);
  }, 0);
  const colors = ["teal", "blue", "pink", "purple", "cyan"];
  return colors[hash % colors.length];
}

export function formatDate(date: Date) {
  return format(date, DATE_FMT)
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export function parseUserJwt (token: string): JwtData {
  const parsed = parseJwt(token);
  return parsed as JwtData;
}

export interface InstitutionInviteJwtData {
  institutionId: string;
  username: string;
}

export function parseInstitutionInviteJwt (token: string): InstitutionInviteJwtData {
  const parsed = parseJwt(token);
  return parsed as InstitutionInviteJwtData;
}

const hekaUserJwt = "heka-user-jwt";

export function getUserJwt(): string | null {
  return localStorage.getItem(hekaUserJwt);
}

export function setUserJwt(jwt: string) {
  localStorage.setItem(hekaUserJwt, jwt);
}

export function removeUserJwt() {
  localStorage.removeItem(hekaUserJwt);
}

export function getUsername(): string {
  const userJwt = getUserJwt();
  if (!userJwt) {
    throw new Error("No user jwt");
  }
  return parseUserJwt(userJwt).username
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function distinct<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function cloneState<T>(state: State<T>): T {
  return structuredClone(state.get({noproxy: true}));
}

export function getProfessionWorkerClass(p: Profession) {
  return p.isStudent ? 'Student' : p.isSpecialist ? 'Specialist' : 'Standard';
}

export function getWorkerClassFormatted(p: Profession, translate: (key: string) => string) {
  const workerClass = getProfessionWorkerClass(p);
  return translate(`${p.type}.${workerClass}`);
}

export function getUserProfessionsFormatted(user: User, translate: (key: string) => string) {
  return user.preferences.professions.map(p => {
    return getWorkerClassFormatted(p, translate);
  }).join(", ")
}

export function translateFullCalendarMonth(t: (key: string) => string) {
  document.querySelectorAll(".fc-toolbar-title").forEach((e) => {
    e.innerHTML = e.innerHTML.replace(/(\w+)\s(\d+)/, (_, month, year) => {
      return `${t(month)} ${year}`;
    });
  })
}

export function getHighestWorkerClass(user: User, profession: WorkerClass.ProfessionType) {
  const professions = user.preferences.professions;
  return getHighestWorkerClassForProfessions(professions, profession);
}

export function getHighestWorkerClassForProfessions(professions: Profession[], profession: WorkerClass.ProfessionType) {
  const professionPreferences = professions.filter(p => p.type === profession);
  const workerClasses = professionPreferences.map(p => getProfessionWorkerClass(p));
  if (workerClasses.includes('Specialist')) {
    return 'Specialist';
  }
  if (workerClasses.includes('Student')) {
    return 'Student';
  }
  return 'Standard';
}

export function getHighestProfessionType(user: User): WorkerClass.ProfessionType {
  // Doctor -> top, Midwife -> 2nd, Nurse -> 3rd
  const professions = user.preferences.professions;
  const professionTypes = professions.map(p => p.type);
  if (professionTypes.includes('Doctor')) {
    return 'Doctor';
  }
  if (professionTypes.includes('Midwife')) {
    return 'Midwife';
  }
  if (professionTypes.includes('Nurse')) {
    return 'Nurse';
  }
  return 'Doctor';
}

export function phoneToString(phone: PhoneNumber): string {
  const phoneFormatted = formatPhone(phone.phoneNumber);
  if (phone.country === 'Iceland') {
    return phoneFormatted;
  }
  const countryCode = phoneCodes.countries.find(c => c.name === phone.country)?.code;
  return `${countryCode} ${phoneFormatted}`;
}

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;
export type DayOfWeek = typeof daysOfWeek[number];