import {BasePage} from "./BasePage";
import { Alert, AlertIcon, Breadcrumb, Button, Divider, FormControl, Heading, Icon, Spinner } from "@chakra-ui/react";
import {BorderBox} from "./BorderBox";
import {css} from "@emotion/react";
import {InputForm} from "./InputForm";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {HiDeviceMobile} from "react-icons/hi";
import {State, useHookstate} from "@hookstate/core";
import {
  getUser,
  ManualLoginRequest,
  ManualRegistrationRequest,
  processManualLogin,
  requestElectronicId, requestManualRegistration
} from "../net-utils";
import {ExistingUserSignIn, NewUserElectronicId, UserResponse} from "../types";
import {parseUserJwt, setUserJwt} from "../heka-utils";
import {globalUser} from "../App";
import { GoButton } from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import { validate7DigitPhone, validatePhone, validateSSN } from "../validations";

async function processExistingUser(data: ExistingUserSignIn, user: State<UserResponse | null>, navigate: NavigateFunction) {
  setUserJwt(data.jwt)
  const username = parseUserJwt(data.jwt).username
  user.set(await getUser(username))
  const redirectUrl = window.localStorage.getItem("redirectAfterLogin");
  if (redirectUrl) {
    navigate(redirectUrl as string)
    window.localStorage.removeItem("redirectAfterLogin")
    return
  }
  navigate(`/home`)
}

function processNewUser(navigate: NavigateFunction, data: NewUserElectronicId) {
  navigate('/signup/resume', {state: data});
}

export function SignupElectronicIDPage() {
  const user = useHookstate(globalUser);
  const navigate = useNavigate();
  async function submit(phoneNumber: string) {
    const response = await requestElectronicId(phoneNumber)
    if (response.status == 200) {
      const data = await response.json() as ExistingUserSignIn
      await processExistingUser(data, user, navigate);
    } else if (response.status == 201) {
      const data = await response.json() as NewUserElectronicId
      processNewUser(navigate, data);
    }
    else {
      throw new Error("Ekki tókst að senda auðkenningu, reyndu aftur síðar.");
    }
  }
  return <ElectronicIdForm submit={submit}/>
}

function ManualRegistrationForm() {
  const registerError = useHookstate(null as string | null);
  const loginError = useHookstate(null as string | null);
  const manualRegisterData = useHookstate<ManualRegistrationRequest>({
    ssn: '',
    phoneNumber: '',
    password: ''
  });
  const passwordAgain = useHookstate('');
  const manualLoginData = useHookstate<ManualLoginRequest>({
    ssn: '',
    password: ''
  });
  const user = useHookstate(globalUser);
  const navigate = useNavigate();

  function verifyForm<T extends object>(data: T) {
    if (Object.keys(data).some(k => data[k as keyof T] === '')) {
      return false;
    }
    return true;
  }

  return <div css={css`display: flex; flex-direction: column; gap: 16px`}>
    <Divider/>
    <FormControl css={css`display: flex;
      flex-direction: column;
      gap: 8px;
    `}>
      <Heading fontSize={'lg'}>Innskráning án rafræna skilríkja</Heading>
      <InputForm label={'Kennitala'} type={'number'} state={manualLoginData.ssn} validate={validateSSN}/>
      <InputForm label={'Aðgangsorð'} type={'password'} state={manualLoginData.password}/>
      <GoButton onClick={async () => {
        if (!verifyForm(manualLoginData.get())) {
          loginError.set('Vinsamlegast fylltu út alla reiti');
          return;
        }
        const jwt = await processManualLogin(manualLoginData.get());
        await processExistingUser(jwt, user, navigate);
      }} submitError={loginError}>
        Innskrá
      </GoButton>
      <AlertDisplay error={loginError}/>
    </FormControl>
    <Divider/>
    <FormControl css={css`display: flex;
      flex-direction: column;
      gap: 8px;
    `}>
      <Heading fontSize={'lg'}>Nýskráning án rafræna skilríkja</Heading>
      <InputForm label={'Kennitala'} type={'number'} state={manualRegisterData.ssn} validate={validateSSN}/>
      <InputForm label={'Símanúmer'} type={'number'} state={manualRegisterData.phoneNumber} validate={validatePhone}/>
      <InputForm label={'Aðgangsorð'} type={"password"} state={manualRegisterData.password}/>
      <InputForm label={'Aðgangsorð aftur'} type={"password"} state={passwordAgain}/>
      <GoButton onClick={async () => {
        if (!verifyForm(manualRegisterData.get())) {
          registerError.set('Vinsamlegast fylltu út alla reiti');
          return;
        }
        if (manualRegisterData.password.get() !== passwordAgain.get()) {
          registerError.set('Aðgangsorðin eru ekki eins');
          return;
        }
        const response = await requestManualRegistration(manualRegisterData.get());
        processNewUser(navigate, response)
      }} submitError={registerError}>
        Nýskrá
      </GoButton>
      <AlertDisplay error={registerError}/>
    </FormControl>
  </div>
}

export function ElectronicIdForm(props: {submit: (phoneNumber: string) => Promise<void>}) {
  const phoneNumber = useHookstate('');
  const isLoading = useHookstate(false);
  const error = useHookstate<string | null>(null);
  const [showManualRegistration, setShowManualRegistration] = useState(false);
  return <BasePage>
    <BorderBox css={css`max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 8px`}>
      <FormControl css={css`display: flex;
        flex-direction: column;
        gap: 8px`}>
        <InputForm label={'Rafræn skilríki'} placeholder={'Símanúmer'} state={phoneNumber} type={'tel'}
          validate={validate7DigitPhone}/>
        {isLoading.get() && 'Auðkenning send, kíktu á símann þinn.'}
        <div css={css`display: flex;
          gap: 4px;
          flex-wrap: wrap`}>
          <Button colorScheme={'blue'} leftIcon={
            isLoading.get() ? <Spinner/> : <Icon as={HiDeviceMobile}/>
          }
            isDisabled={isLoading.get() || validate7DigitPhone(phoneNumber.get()) !== null}
            onClick={
              () => {
                isLoading.set(true);
                error.set(null);
                (async () => {
                  try {
                    await props.submit(phoneNumber.get())
                    return;
                  } catch (e) {
                    console.error(e)
                  }
                  isLoading.set(false);
                  error.set("Ekki tókst að senda auðkenningu, reyndu aftur síðar.");
                })()
              }
            }>
            {isLoading.get() ? <>Augnablik</> : <>Skrá með rafrænum skilríkjum</>}
          </Button>
          <Button onClick={() => setShowManualRegistration(!showManualRegistration)}>
            Ég er ekki með rafræn skilríki
          </Button>
          {/*<Link to={'/signup/resume'}>*/}
          {/*  <Button>Ég hef ekki rafræn skilríki</Button>*/}
          {/*</Link>*/}
        </div>
      </FormControl>
      {showManualRegistration && <ManualRegistrationForm/>}
      {error.get() && <Alert status={'error'}>
          <AlertIcon/>
        {error.get()}
      </Alert>}
    </BorderBox>
  </BasePage>;
}