import {css, keyframes, SerializedStyles} from '@emotion/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {BasePage} from './BasePage';
import {BorderBox} from './BorderBox';
import {HalfSection, SectionContainer} from './SectionContainer';
import {FaFileSignature, FaLaptopMedical} from 'react-icons/fa';
import {GiClick} from 'react-icons/gi';
import {AiFillCalculator} from 'react-icons/ai';
import {getUserJwt, useWindowDimensions} from '../heka-utils';
import logoLandlaeknir from '../resources/logos/landlaeknir.jpg';
import logoHSU from '../resources/logos/hsu.jpg';
import logoHSN from '../resources/logos/hsn.png';
import logoHVE from '../resources/logos/hve.png';
import {Fade} from "react-slideshow-image";
import homepage1 from '../resources/screenshots/homepage1.png';
import homepage2 from '../resources/screenshots/homepage2.png';
import positionpage1 from '../resources/screenshots/positionpage1.png';
import positionpage2 from '../resources/screenshots/positionpage2.png';
import userpage1 from '../resources/screenshots/userpage1.png';
import userpage2 from '../resources/screenshots/userpage2.png';
import userpage3 from '../resources/screenshots/userpage3.png';
import "react-slideshow-image/dist/styles.css";
import {ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon, AtSignIcon, CheckIcon, WarningIcon} from "@chakra-ui/icons";
import {subscribeToNewsletter} from "../net-utils";
import {useHookstate} from "@hookstate/core";
import {ExternalError} from "../types";
import nurseIcon from '../resources/icons/icons8-protection-mask-90.png';
import midwifeIcon from '../resources/icons/icons8-nurse-90.png';
import doctorIcon from '../resources/icons/icons8-rod-of-asclepius-90.png';

const headerKeyframes = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
`;

function PromoSticker(props: { label: React.ReactNode; icon: React.ReactNode, children?: React.ReactNode }) {
  const dimensions = useWindowDimensions();
  const padding = dimensions.width < 600 ? css`padding-left: 24px;
    padding-right: 24px;` : css`padding: initial;`;
  return <HalfSection css={css`display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    min-width: 48%;
    ${padding}`}>
    <div css={css`height: 80px`}>
      {props.icon}
    </div>
    <Heading fontSize={'2xl'} css={css`text-align: center`}>
      {props.label}
    </Heading>
    <Text color={'gray.600'}>
      {props.children}
    </Text>
  </HalfSection>;
}

const backgroundBlur = (translateX: number, translateY: number, opacity = 0.4, aspectRatio = '2 / 1') => css`
  width: 600px;
  position: absolute;
  left: -10%;
  background: radial-gradient(circle at 50% 50%, var(--chakra-colors-pink-500), var(--chakra-colors-blue-200));
  z-index: -1;
  opacity: ${opacity};
  filter: blur(80px);
  aspect-ratio: ${aspectRatio};
  transform: translate(${translateX}%, ${translateY}%);
`;

function QnAItem(props: { question: string, answer: string }) {
  return <AccordionItem>
    <AccordionButton>
      <Box flex="1" textAlign="left">
        {props.question}
      </Box>
      <AccordionIcon/>
    </AccordionButton>
    <AccordionPanel pb={4}>
      {props.answer}
    </AccordionPanel>
  </AccordionItem>;
}

function QnASection(props: { className?: string, css?: SerializedStyles }) {
  return <div className={props.className} css={css`display: flex;
    flex-direction: column;
    gap: 24px;`}>
    <Heading fontSize={"4xl"} css={css`text-align: center;
      margin-top: 24px`}>Spurt og svarað</Heading>
    <Accordion allowMultiple>
      <QnAItem question={`Hvað er Heka?`}
               answer={`Heka tengir saman heilbrigðisstarfsfólk og stofnanir til afleysinga. Heilbrigðisstarfsfólk með gilt starfsleyfi stofnar aðgang og fær skýra yfirsýn og getur auðveldlega sótt um afleysingar. Kerfið er nýjung á Íslandi með áherslur á öryggi, skilvirkni og gagnsæi.`}/>
      <QnAItem question={`Kostar að nota Heka?`}
               answer={`Nei, það er enginn kostnaður fyrir þig sem heilbrigðisstarfsmann að nota Heka.`}/>
      <QnAItem question={`Er hægt að sækja um stöður um allt land?`}
               answer={`Eins og staðan er núna er Heka í samstarfi við Heilbrigðisstofnun Suðurlands og Heilbrigðisstofnun Norðurlands svo afleysingar einskorðast við þessar stofnanir. Tilkynning verður send út þegar stofnanir fleiri bætast við.`}/>
      <QnAItem question={`Get ég sótt um fleiri eina stöðu?`}
               answer={`Já, þú getur sótt um eins margar stöður og þú vilt en mikilvægt er að huga að því að tímabil umsókna skarist ekki svo þú ráðir þig ekki á tvo staði samtímis.`}/>
      <QnAItem question={`Hver velur úr umsóknum fyrir hverja afleysingu?`}
               answer={`Viðkomandi heilbrigðisstofnun ákveður alfarið hvaða umsókn er valin óháð Heka. Ákveðnir þættir í prófílnum þínum geta styrkt umsóknina eins og fyrri afleysingar.`}/>
      <QnAItem question={`Þarf ég að hlaða upp ferilskrá?`}
               answer={`Nei, það er ekki skylda og þú getur bætt henni við hvenær sem þér hentar.`}/>
      <QnAItem question={`Þarf ég að vera með íslenskt starfsleyfi?`}
               answer={`Já, til þess að fá að starfa á Íslandi í þeim heilbrigðisstörfum sem við auglýsum er það krafa.`}/>
      <QnAItem question={`Þarf ég að vera með rafræn skilríki?`}
               answer={`Til að tryggja að þínar upplýsingar verði geymdar á öruggan hátt og til að sannreyna að okkar notendur séu með starfsleyfi í gildi mælum við með að notendur noti rafræn skilríki. Ef þú ert ekki með rafræn skilríki, hafðu þá samband við okkur með því að senda okkur línu á heka@heka.is.`}/>
      <QnAItem question={`Þarf ég að skrá greiðsluupplýsingar?`}
               answer={`Það er ekki nauðsynlegt að skrá greiðsluupplýsingar og það er alltaf hægt að gera það seinna. Greiðsluupplýsingar nýtast við gerð rafrænna reikninga Heka sem spara þér tíma með því að rukka fyrir verktöku á einfaldan hátt. Allir rafrænir reikningar Heka vistast á “Þínu svæði” og er auðvelt að hlaða þeim niður fyrir bókhaldið.`}/>
      <QnAItem question={`Hvernig eru greiðsluupplýsingar mínar geymdar?`}
               answer={`Það skiptir Heka miklu máli að fylgja lögum sem lúta að vörslu persónugreinanlegra upplýsinga. Öll gögn eru geymd samkvæmt GDPR Evrópulöggjöf.`}/>
      <QnAItem question={`Má sækja um ef ég get bara tekið hluta úr tímabili?`}
               answer={`Þegar þú sækir um auglýsta stöðu er boðið upp á að velja hvaða daga af tímabilinu þú vilt sækja um. Ef þú getur ekki unnið allt tímabilið sem auglýst er geturðu valið þá daga sem henta þér. Það er svo undir heilbrigðisstofnunni komið hvort hún samþykki umsóknina. `}/>
      <QnAItem question={`Get ég hætt við afleysingu?`}
               answer={`Það er hægt að hætta við afleysingu með ákveðnum fyrirvara. Eftir þann fyrirvara þarf umsækjandi að hafa samband við heilbrigðisstofnun.`}/>
      <QnAItem question={`Hvernig virka rafrænir reikningar Heka?`}
               answer={`Þegar þú hefur lokið við afleysingu sem verktaki býður Heka upp á möguleika að senda rafrænan reikning beint á viðeigandi heilbrigðisstofnun. Valmöguleiki opnast á “Þinni síðu”. Allar upplýsingar fyllast sjálfkrafa inn og það eina sem þú þarft að gera er að yfirfara og ýta á “Senda”. Allir rafrænir reikningar sendast í gegnum örugga skeytamiðlun.`}/>
      <QnAItem question={`Hverjir standa á bakvið Heka?`}
               answer={`Stofnendur Heka eru Arnar Jan Jónsson, læknir; Kristján Ari Úlfarsson, verkfræðingur og Kormákur Breki Gunnlaugsson, tölvunarfræðingur.`}/>

    </Accordion>
    <p css={css`text-align: center`}>
      Höfum við svarað öllum þínum spurningum?<br/>
      Ef þér finnst vanta frekari svör viljum við endilega heyra í þér. Sendu okkur póst á <a href="mailto:heka@heka.is">heka@heka.is</a>.
    </p>
  </div>;
}

function PromoStickers() {
  return <SectionContainer css={css`margin-top: 36px;
    margin-bottom: 36px;
    flex-wrap: wrap`}>
    <PromoSticker icon={<Icon as={FaLaptopMedical} boxSize={20} color={"pink.800"}/>} label={"Notendavænt yfirlit"}>
      Auglýsingar fyrir lausar stöður veita m.a. upplýsingar um tímabil, hæfniskröfur og greiðslur. Hægt er að sía
      auglýsingar eftir þínum þörfum.
    </PromoSticker>
    <PromoSticker icon={<Icon as={GiClick} boxSize={20} color={"blue.800"}/>} label={"Einfalt að sækja um"}>
      Það tekur innan við mínútu að sækja um auglýsta stöðu. Þú færð tilkynningu eftir að umsókn hefur verið samþykkt.
    </PromoSticker>
    <PromoSticker icon={<Icon as={FaFileSignature} boxSize={20} color={"green.800"}/>} label={"Rafrænir samningar"}>
      Undirritun samnings milli umsækjanda og atvinnuveitanda er gerð með rafrænum hætti eftir samþykki umsóknar.
    </PromoSticker>
    <PromoSticker icon={<Icon as={AiFillCalculator} boxSize={20} color={"red.800"}/>}
                  label={"Sjálfvirk gerð reikninga"}>
      Reikningar skapast sjálfkrafa eftir að afleysingu lýkur. Hægt er að senda reikning rafrænt á atvinnuveitanda til
      að einfalda greiðslu.
    </PromoSticker>
  </SectionContainer>;
}

function AffiliateLogos() {
  const {width} = useWindowDimensions()
  const mobile = width < 725;
  const wrap = mobile ? "wrap" : "nowrap";
  const imgWidth = mobile ? `img {width: 50%}` : ``;
  return <div css={css`margin-top: 24px;
    margin-bottom: 24px`}>
    <Heading css={css`margin-bottom: 12px`} fontSize={"4xl"} textAlign={"center"}>Samstarfsaðilar</Heading>
    <div css={css`height: 120px;
      display: flex;
      gap: 12px;
      justify-content: space-evenly; flex-wrap: ${wrap}; ${imgWidth}`}>
      <img src={logoLandlaeknir} alt="Embætti landlæknis"/>
      <img src={logoHSU} alt="Heilbrigðisstofnun Suðurlands"/>
      <img src={logoHSN} alt="Heilbrigðisstofnun Norðurlands" css={css`padding: 30px;`}/>
      <img src={logoHVE} alt="Heilbrigðisstofnun Vesturlands" css={css`padding: 15px`}/>
    </div>
  </div>;
}

function Screenshots() {
  return <div css={css`margin-bottom: 24px;
    margin-top: 24px`}>
    <Fade nextArrow={<ArrowRightIcon/>} prevArrow={<ArrowLeftIcon/>} duration={4000}>
      <Image className={'each-fade'} src={homepage1} alt="Skjáskot"/>
      <Image className={'each-fade'} src={homepage2} alt="Skjáskot"/>
      <Image className={'each-fade'} src={positionpage1} alt="Skjáskot"/>
      <Image className={'each-fade'} src={positionpage2} alt="Skjáskot"/>
      <Image className={'each-fade'} src={userpage1} alt="Skjáskot"/>
      <Image className={'each-fade'} src={userpage2} alt="Skjáskot"/>
      <Image className={'each-fade'} src={userpage3} alt="Skjáskot"/>
    </Fade>
  </div>;
}

function SignupButtons() {
  return <BorderBox css={css`margin: 0 auto;
    max-width: 400px;
    margin-bottom: 36px;
    background-color: white; `}>
    <VStack padding={4} width={"100%"}>
      <Link css={css`width: 100%`} to={"/signup"}>
        <Button css={css`width: 100%`}>Stofna aðgang</Button>
      </Link>
      <Link css={css`width: 100%`} to={"/signup"}>
        <Button colorScheme={"pink"} css={css`width: 100%`}>Skrá inn</Button>
      </Link>
    </VStack>
  </BorderBox>;
}

function EmailSubscribe() {
  const email = useHookstate("")
  const sending = useHookstate(false)
  const sent = useHookstate(false)
  const error = useHookstate(false)
  const errorMessage = useHookstate("")
  return <BorderBox css={css`margin: 0 auto;
    max-width: 400px;
    margin-bottom: 14px;
    background-color: white; `}>
    <FormControl isInvalid={
      error.get()
    } css={css`display: flex; flex-direction: column; gap: 8px`}>
      <InputGroup>
        <InputLeftElement>{
          sending.get() ? <Spinner color={'gray.400'}/> : <AtSignIcon color={'gray.400'}/>
        }</InputLeftElement>
        <Input placeholder={"Netfang"} type={"email"} onChange={
          (e) => email.set(e.target.value)
        } isDisabled={
          sending.get()
        } isInvalid={error.get()}/>
      </InputGroup>
      <Button colorScheme={!sent.get() ? "pink" : !error.get() ? "green" : "red"} css={css`width: 100%`}
              rightIcon={!sent.get() ? <ArrowForwardIcon/> :
                !error.get() ? <CheckIcon/> : <WarningIcon/>}
              isDisabled={
                sending.get()
              }
              onClick={() =>
                (async () => {
                  sending.set(true)
                  error.set(false)
                  sent.set(false)
                  errorMessage.set("")
                  const defaultErrorMessage = "Ekki tókst að skrá netfang";
                  try {
                    const response = await subscribeToNewsletter(email.get())
                    if (!response.ok) {
                      error.set(true);
                      const errorJson = await response.json() as ExternalError
                      if (errorJson.error == "Email already subscribed") {
                        errorMessage.set("Þetta netfang er þegar skráð")
                      } else {
                        errorMessage.set(errorJson.error ?? defaultErrorMessage)
                      }
                    } else {
                    }
                  } catch (e) {
                    error.set(true);
                    errorMessage.set(defaultErrorMessage)
                  }
                  sending.set(false)
                  sent.set(true);
                })()}
      >Skrá mig á póstlista</Button>
      {errorMessage.get().length !== 0 && <FormErrorMessage>{errorMessage.get()}</FormErrorMessage>}
      {!error.get() && sent.get() && <FormHelperText>
        Takk fyrir að skrá þig á póstlista. Við munum senda þér tölvupóst þegar þjónustan er í boði.
      </FormHelperText>}
    </FormControl>
  </BorderBox>;
}

function WorkerTypeIcon(props: { label: string, icon: string, width?: string }) {
  const width = props.width ?? "40px"
  return <div css={css`display: flex; flex-direction: column; align-items: center; gap: 4px; width: 120px`}>
    <img src={props.icon} alt={props.label} css={css`opacity: 0.5; width: ${width}`}/>
    <Text color={"gray.600"}>{props.label}</Text>
  </div>;
}

function WorkerTypeIcons() {
  return <div css={css`margin: 0 auto; display: flex; flex-direction: column; gap: 12px; margin-bottom: 12px;
                       overflow-y: hidden`}>
    <Heading size={"md"} color={'gray.600'} textAlign={'center'}>Fyrir</Heading>
    <div css={css`display: flex;
      gap: 12px; flex-wrap: wrap; justify-content: space-evenly`}>
      <WorkerTypeIcon icon={doctorIcon} label={'Lækna'}/>
      <WorkerTypeIcon icon={nurseIcon} label={'Hjúkrunarfræðinga'}/>
      <WorkerTypeIcon icon={midwifeIcon} label={'Ljósmæður'}/>
    </div>
  </div>;
}

function BackgroundFilter() {
  return <div css={css`position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 800px;
      overflow: hidden;
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      z-index: -1;
    `}>
    <div css={backgroundBlur(25, -60)}></div>
    <div css={backgroundBlur(180, 0, 0.2, "1 / 1")}></div>
  </div>;
}

function Slogans() {
  const dimensions = useWindowDimensions();
  const fontSize = dimensions.width < 600 ? '2em' : '5xl';
  return <div css={css`text-align: center;
      margin-bottom: 24px`}>
    <Heading
      css={css`animation: 1000ms ease 0s 1 normal backwards running ${headerKeyframes};
          margin-bottom: 24px;
          margin-top: 52px`}
      fontSize={fontSize}>
      Heilbrigðisstörf um land allt
    </Heading>
    <Heading fontSize={"3xl"}
             css={css`font-weight: normal;
                 animation: 1200ms ease 400ms 1 normal backwards running ${headerKeyframes};`} color={"gray.600"}>
      Við einföldum afleysingar fyrir þig. <br/>
      Tryggðu framtíðina, ferðastu um landið og auðgaðu nýjar reynslur.
    </Heading>
  </div>;
}

export function WelcomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    const jwt = getUserJwt();
    if (jwt) {
      navigate('/home')
    }
  }, [])
  return <BasePage css={css`display: flex;
    flex-direction: column;
    gap: 8px`}>
    <BackgroundFilter/>
    <Slogans/>
    <SignupButtons/>
    <WorkerTypeIcons/>
    <Divider/>
    <Screenshots/>
    <Divider/>
    <PromoStickers/>
    <Divider/>
    <QnASection css={css`margin-bottom: 36px;`}/>
    <Divider/>
    <AffiliateLogos/>
  </BasePage>;
}