import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {Badge, Button, Checkbox, Heading, Menu, MenuButton, Select} from '@chakra-ui/react';
import mapIceland from '../resources/map-iceland-cropped-no-dots.svg';
import MapMarkers from './MapMarkers';
import React, {useEffect, useState} from 'react';
import {BasePage} from './BasePage';
import {SearchInput} from './SearchInput';
import {ChevronDownIcon, SmallCloseIcon} from '@chakra-ui/icons';
import {DatePicker} from './DatePicker';
import {Link} from 'react-router-dom';
import {State, useHookstate} from '@hookstate/core';
import {WorkerClass} from '../worker-class';
import {LabelInfo} from './LabelInfo';
import {SelectableMenuList} from './SelectableMenuList';
import {
  calculatePayForPosition,
  DATE_FMT,
  distinct,
  formatCurrency,
  formatDate,
  onSelect,
  useWindowDimensions
} from '../heka-utils';
import {format} from 'date-fns';
import {HType, Institution, Position, Profession, User} from '../types';
import {FavoriteIcon} from './FavoriteIcon';
import {getInstitutions, getPositions} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from "react-i18next";
import {globalUser} from "../App";
import {globalFavoritePositions} from "./NavigationBar";
import {QuestionMarkInfoIcon} from "./QuestionMarkInfoIcon";
import {cloneDeep, isEqual} from "lodash";
import {deepEqual} from "assert";

function OpenPositionEntry(props: { position: Position, isFavorite: boolean }) {
  const {t} = useTranslation();
  const user = useHookstate(globalUser).get()?.user
  const position = props.position;
  const order = ["Student", "Standard", "Specialist"]
  const requirements = [...position.workerClassRequirements]
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .map(r => `${position.professionType}.${r}`)
    .map(r => t(r))
    .join(", ");
  if (!user) return <DefaultSpinner/>
  const pay = calculatePayForPosition(position, user)
  return <BorderBox css={css`display: flex;
    gap: 4px;
    flex-direction: column;
    width: auto;
    grid-column: span 1;
    grid-row: span 1;`}>
    <div css={css`display: flex;`}>
      <Heading size={'sm'} css={css`margin-right: 5px;`}>{position.institutionName}</Heading>
      <Heading size={'sm'} css={css`font-weight: normal;
        font-style: italic;
        color: #636363`}>{position.clinicName}</Heading>
      {/*<Icon as={FaBriefcaseMedical} css={css`margin-left: auto`} color={'gray.400'}/>*/}
      <FavoriteIcon positionId={position._id!!} isEnabled={props.isFavorite}/>
    </div>
    <div>
      <LabelInfo label={"Sérgrein"} value={position.specialization}/>
      <LabelInfo label={"Hæfniskröfur"} value={requirements}/>
      {!props.position.isCollectiveAgreementBased && <LabelInfo label={"Greiðsla"} value={formatCurrency(pay)}/>}
    </div>
    <LabelInfo label={"Tímabil"}>
      <Badge>{format(position.beginDate!!, DATE_FMT)}</Badge>
      <span> - </span>
      <Badge>{format(position.endDate!!, DATE_FMT)}</Badge>
    </LabelInfo>
    <div>
      {position.htype == 'H1' ? <Badge colorScheme={'purple'}>H1</Badge> :
        position.htype == 'H2' ? <Badge colorScheme={'teal'}>H2</Badge>
        : <Badge colorScheme={'blue'}>H3+</Badge>}
    </div>
    <div css={css`margin-top: auto`}>
      <Link to={`/positions/${position._id!!}`}>
        <Button colorScheme={'green'}>Skoða</Button>
      </Link>
    </div>
  </BorderBox>;
}

const UrgencyMap = (props: {filterOptions: State<FilterOptions>}) => {
  return <div css={css`width: 45%;
      margin: 0 auto;
      position: relative;`}>
    <img src={mapIceland}/>
    <MapMarkers filterOptions={props.filterOptions}/>
  </div>;
};

export function HomePage() {
  const user = useHookstate(globalUser).get()?.user
  if (!user) {
    return <DefaultSpinner/>
  }
  return <HomePagePositionList user={user}/>
}

export function HomePagePositionList({user}: { user: User }) {
  const searchInput = useHookstate('')
  const [positions, setPositions] = useState(null as Position[] | null);
  const filterOptions = useHookstate<FilterOptions>(
    {...cloneDeep(defaultFilterOptions), professionTypes: user.preferences.professions.map(p => p.type)}
  );
  useEffect(() => {
    (async () => {
      setPositions(await getPositions(filterOptions.get()))
    })();
  }, [filterOptions]);
  const [institutions, setInstitutions] = useState(null as Institution[] | null);
  useEffect(() => {
    (async () => {
      setInstitutions(await getInstitutions())
    })();
  }, []);
  const favoritePositions = useHookstate(globalFavoritePositions).get()
  if (!institutions || !user || !filterOptions) {
    return <DefaultSpinner/>
  }
  const clinicAndInstitutions: ClinicAndInstitutions = {
    institutionIds: distinct(institutions.map(i => i.institutionId!!)).filter(e => e.trim().length),
    institutionNames: distinct(institutions.map(p => p.name)).filter(e => e.trim().length),
    clinicIds: distinct(institutions.flatMap(p => p.clinics.map(c => c.clinicId!!))).filter(e => e.trim().length),
    clinicNames: distinct(institutions.flatMap(p => p.clinics.map(c => c.name))).filter(e => e.trim().length)
  }

  return <BasePage>
    <div css={css`display: flex;
      flex-direction: column;
      gap: 8px`}>
      <SearchInput input={searchInput}/>
      <BorderBox css={css`display: flex;
        flex-direction: column`}>
        <UrgencyMap filterOptions={filterOptions}/>
        <FilterBadges filterOptions={filterOptions} clinicAndInstitutions={clinicAndInstitutions}/>
        <div css={css`display: flex;
          gap: 4px;
          margin: 16px auto;
          flex-wrap: wrap`}>
          <Badge colorScheme={'green'}>Lítil eftirspurn</Badge>
          <Badge colorScheme={'orange'}>Mikil eftirspurn</Badge>
          <Badge colorScheme={'red'}>Gífurleg eftirspurn</Badge>
        </div>
        <FilterOptionsSelect filterOptions={filterOptions} clinicAndInstitutions={clinicAndInstitutions}/>
        <PositionPanels positions={positions} favoritePositionIds={
          new Set<string>(favoritePositions?.map(p => p.positionId) ?? [])
        } searchInput={searchInput.get()}/>
      </BorderBox>
    </div>
  </BasePage>;
}

interface ClinicAndInstitutions {
  clinicIds: string[];
  clinicNames: string[];
  institutionIds: string[];
  institutionNames: string[];
}

function FilterBadges(props: { filterOptions: State<FilterOptions>, clinicAndInstitutions: ClinicAndInstitutions }) {
  const {filterOptions, clinicAndInstitutions} = props;
  const {clinicIds, clinicNames, institutionIds, institutionNames} = clinicAndInstitutions;
  const {t} = useTranslation();
  function RemovableBadge(props: { children: React.ReactNode, onRemove: () => void }) {
    return <div><Badge><>{props.children}<SmallCloseIcon css={css`cursor: pointer`} onClick={props.onRemove}/></>
    </Badge></div>
  }
  function DateBadge(props: { date: State<Date | null>, title: string }) {
    return <RemovableBadge onRemove={() => props.date.set(null)}>{props.title}: {formatDate(props.date.get()!!)}</RemovableBadge>
  }
  function IdBadges(props: { ids: State<string[]>, names: string[], title: string, allIds: string[] }) {
    return <>
      {props.ids.get().map((id, index) => {
        const name = props.names[props.allIds.indexOf(id)];
        return <RemovableBadge key={id} onRemove={() => props.ids.set(props.ids.get().filter(i => i != id))}>
          {name}
        </RemovableBadge>;
      })}
    </>
  }
  function HTypeBadges(props: { htypes: State<HType[]> }) {
    const htypes: HType[] = ['H1', 'H2', 'H3'];
    return <>{htypes.map(htype => {
      return props.htypes.get().includes(htype) && <RemovableBadge onRemove={() => filterOptions.htypes.set(
        filterOptions.htypes.map(e => e.get()).filter(e => e !== htype)
      )}>{htype}</RemovableBadge>
    })}</>
  }
  function WorkerClassBadges(props: { workerClasses: State<string[]> }) {
    return <>{props.workerClasses.get().map(workerClass =>
      <RemovableBadge onRemove={() => props.workerClasses.set(props.workerClasses.get().filter(wc => wc !== workerClass))}>
        {t(workerClass)}
      </RemovableBadge>)}
    </>
  }
  return <div css={css`display: flex; gap: 4px; flex-wrap: wrap`}>
    {filterOptions.beginDate.get() && <DateBadge date={filterOptions.beginDate} title={'Frá'}/>}
    {filterOptions.endDate.get() && <DateBadge date={filterOptions.endDate} title={'Til'}/>}
    {filterOptions.institutionIds.get().length > 0 &&
    <IdBadges ids={filterOptions.institutionIds} names={institutionNames} allIds={institutionIds} title={'Stofnun'}/>}
    {filterOptions.clinicIds.get().length > 0 &&
    <IdBadges ids={filterOptions.clinicIds} names={clinicNames} allIds={clinicIds} title={'Starfsstöð'}/>}
    {filterOptions.htypes.get().length !== 3 && <HTypeBadges htypes={filterOptions.htypes}/>}
    <WorkerClassBadges workerClasses={filterOptions.workerClassRequirements}/>
  </div>
}

function FilterOptionsSelect(props: { filterOptions: State<FilterOptions>, clinicAndInstitutions: ClinicAndInstitutions }) {
  const {t} = useTranslation();
  const filterOptions = useHookstate(props.filterOptions);
  const user = useHookstate(globalUser).get()?.user
  if (!user) {
    return <DefaultSpinner/>
  }

  function onHTypeChange(e: React.ChangeEvent<HTMLInputElement>, hType: HType) {
    return e.target.checked ? filterOptions.htypes.merge([hType]) :
      filterOptions.htypes.set(
        filterOptions.htypes.map(e => e.get()).filter(e => e !== hType)
      );
  }

  const workerClassRequirementIds = getAvailableWorkerClassRequirements(user)
  const workerClassRequirementNames = workerClassRequirementIds.map(e => t(e))

  const {institutionIds, institutionNames, clinicIds, clinicNames} = props.clinicAndInstitutions

  return <div id={'position-filters'} css={css`margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;`}>
    <Heading size={'sm'}>Tímabil</Heading>
    <div css={css`display: flex;
      gap: 4px`}>
      <DatePicker onChange={filterOptions.beginDate.set} selected={filterOptions.beginDate.get()}
        placeholderText={'Byrjun'}
        minDate={new Date()}/>
      <DatePicker onChange={filterOptions.endDate.set} selected={filterOptions.endDate.get()} placeholderText={'Lok'}
        minDate={filterOptions.beginDate.get() ?? new Date()}/>
    </div>
    <div css={css`display: flex;
      gap: 4px;
      flex-wrap: wrap;
      z-index: 2`}>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
          Heilbrigðisumdæmi
        </MenuButton>
        <SelectableMenuList items={institutionNames} selected={filterOptions.institutionIds}
          mapFn={name => institutionIds[institutionNames.indexOf(name)]}/>
      </Menu>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
          Heilsugæsla
        </MenuButton>
        <SelectableMenuList items={clinicNames} selected={filterOptions.clinicIds}
          mapFn={name => clinicIds[clinicNames.indexOf(name)]}/>
      </Menu>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
          Hæfniskröfur
        </MenuButton>
        <SelectableMenuList items={workerClassRequirementNames}
          selected={filterOptions.workerClassRequirements}
          mapFn={name => workerClassRequirementIds[workerClassRequirementNames.indexOf(name)]}/>
      </Menu>
    </div>
    <div css={css`display: flex;
      gap: 4px;
      flex-wrap: wrap;
      z-index: 1`}>
      <Select onChange={onSelect(filterOptions.sortBy)} value={filterOptions.sortBy.get()} width={250}>
        <option value={'Newest'}>Nýjustu</option>
        <option value={'Oldest'}>Elstu</option>
      </Select>
    </div>
    <div css={css`display: flex;
      gap: 8px`}>
      <Checkbox size={'lg'} onChange={e => onHTypeChange(e, 'H1')} isChecked={
        filterOptions.htypes.get().includes('H1')
      }>
        H1 stöður
      </Checkbox>
      <Checkbox size={'lg'} onChange={e => onHTypeChange(e, 'H2')} isChecked={
        filterOptions.htypes.get().includes('H2')
      }>
        H2 stöður
      </Checkbox>
      <Checkbox size={'lg'} onChange={e => onHTypeChange(e, 'H3')} isChecked={
        filterOptions.htypes.get().includes('H3')
      }>
        H3 stöður
      </Checkbox>
      <HTypeInformational/>
    </div>

  </div>
}

export const HTypeInformational = () => <QuestionMarkInfoIcon text={'H1: Einn læknir á starfsstöð á hverjum tíma. ' +
  'H2: Tveir eða fleiri læknar á starfsstöð á hverjum tíma. ' +
  'H3+: Þrír eða fleiri læknar á starfsstöð á hverjum tíma.'}/>

function getAvailableWorkerClassRequirements(user: User) {
  const preferences = user.preferences;
  // combine every preferences.professions.type with classes = [Student, Standard, Specialist] in form of `${type}.${class}`
  return preferences.professions.map(p => p.type)
    .flatMap(type => WorkerClass.classes.map(c => `${type}.${c}`));
}

function PositionPanels(props: { positions: Position[] | null, favoritePositionIds: Set<string>, searchInput: string }) {
  const {positions} = props;
  const {width} = useWindowDimensions();
  const {t} = useTranslation();

  if (!positions) {
    return <DefaultSpinner/>
  }

  const styles = width > 540 ? css`display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 8px` : css`display: flex;
    flex-direction: column;
    gap: 8px`;
  if (positions.length === 0) {
    return <div>
      Engar stöður fundust fyrir þessa leit. Prufaðu að breyta síunnarstillingum.
    </div>
  }
  return <div css={styles}>
    {positions
      // .filter(e => !filterOptions.institutionIds.length || filterOptions.institutionIds
      //   .includes(e.institution))
      // .filter(e => !filterOptions.clinicIds.length || filterOptions.clinicIds
      //   .map(e => e)
      //   .includes(e.clinic))
      // .filter(e => !filterOptions.workerClassRequirements.length || filterOptions.workerClassRequirements
      //   .map(e => e)
      //   .includes(e.achievement))
      // .filter(e => !filterOptions.htypes.length || filterOptions.htypes
      //   .map(e => e)
      //   .includes(e.hType))
      .filter(pos => {
        if (props.searchInput.length === 0) {
          return true;
        }
        const searchInput = props.searchInput.toLowerCase();
        
        return pos.clinicName!!.toLowerCase().includes(searchInput) || pos.institutionName!!.toLowerCase().includes(searchInput)
         || pos.specialization!!.toLowerCase().includes(searchInput);
      })
      .map((position) => {
        const isFavorite = props.favoritePositionIds.has(position._id!!)
        return <OpenPositionEntry key={position._id} position={position} isFavorite={isFavorite}/>;
      })}
  </div>
}

export interface FilterOptions {
  institutionIds: string[];
  clinicIds: string[];
  workerClassRequirements: string[];
  htypes: HType[];
  beginDate: Date | null;
  endDate: Date | null;
  sortBy: 'Newest' | 'Oldest' | 'HighestSalary' | 'LowestSalary';
  professionTypes: WorkerClass.ProfessionType[];
}

const defaultFilterOptions: FilterOptions = {
  institutionIds: [],
  clinicIds: [],
  workerClassRequirements: [],
  htypes: ['H1', 'H2', 'H3'],
  beginDate: null,
  endDate: null,
  sortBy: 'Newest',
  professionTypes: []
}