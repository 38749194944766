import {State, useHookstate} from "@hookstate/core";
import {MenuItem, MenuList} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";
import React from "react";

export const SelectableMenuList = (props: {
  items: string[],
  selected: State<string[]>,
  mapFn?: (s: string) => string,
  translateFn?: (s: string) => string,
}) => {
  const selected = useHookstate(props.selected);
  const mapFn = props.mapFn ?? (s => s);
  const translateFn = props.translateFn ?? (s => s);
  const addOrRemoveFromSelected = (item: string) => {
    const itemMapped = mapFn(item);
    if (selected.value.includes(itemMapped)) {
      selected.set(selected.value.filter(i => i !== itemMapped));
    } else {
      selected.merge([itemMapped]);
    }
  }
  const isSelected = (item: string) => {
    return selected.value.includes(mapFn(item));
  }
  return <MenuList>
    {props.items.map((item, index) => <MenuItem value={item} key={item}
      icon={<CheckIcon visibility={isSelected(item) ? 'visible' : 'hidden'}/>}
      onClick={() => addOrRemoveFromSelected(item)}>
      {translateFn(item)}
    </MenuItem>)}
  </MenuList>;
};