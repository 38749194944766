import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {Badge, Heading, Icon} from "@chakra-ui/react";
import {BorderBox} from "./BorderBox";
import {NotificationMessage} from "../types";
import {formatDate, getUsername} from '../heka-utils';
import {getNotificationMessages} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {processNotificationItems} from "./NavigationBar";
import {css} from "@emotion/react";
import {useNavigate} from "react-router-dom";

const NotificationsPage = () => {
  const [notificationMessages, setNotificationMessages] = useState([] as NotificationMessage[] | null);
  const navigate = useNavigate()
  useEffect(() => {
    (async () => {
      setNotificationMessages(await getNotificationMessages(getUsername(), 100));
    })();
  }, []);
  if (!notificationMessages) {
    return <DefaultSpinner/>;
  }
  const notificationItems = processNotificationItems(notificationMessages);
  return (
    <BasePage>
      <Heading fontSize={'2xl'}>Mínar tilkynningar</Heading>
      <div css={css`display: flex; flex-direction: column; gap: 4px;`}>
        {notificationItems.map((item, index) => {
          return <BorderBox key={index} css={css`display: flex; gap: 8px; align-items: center; cursor: pointer`}
            onClick={() => navigate(item.link)}>
            <Icon as={item.icon} color={'gray.600'} boxSize={6}/>
            <div>
              <Badge>{formatDate(item.date)}</Badge>
            </div>
            {item.content}
          </BorderBox>;
        })}
      </div>
    </BasePage>
  );
};

export default NotificationsPage;
