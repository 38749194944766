import React, { useEffect } from 'react'
import {css} from "@emotion/react";
import {Tooltip, useToken} from "@chakra-ui/react";
import {ClinicMapMarker} from "../types";
import {getClinicMapMarkers} from "../net-utils";
import {State} from "@hookstate/core";
import {FilterOptions} from "./HomePage";

function MapMarkers(props: {filterOptions: State<FilterOptions>}) {
  const [markers, setMarkers] = React.useState<ClinicMapMarker[]>([])
  useEffect(() => {
    (async () => {
      setMarkers(await getClinicMapMarkers())
    })()
  }, []);
  const [green, orange, red] = useToken('colors', ['green.300', 'orange.300', 'red.300']);
  const ellipseSize = 11
  const getRandColor = (numPositions: number) => {
    if (numPositions === 0) return green
    if (numPositions >= 5) return red
    return orange
  }
  function onMarkerClick(marker: ClinicMapMarker) {
    const clinicId = marker.data.clinicId
    if (props.filterOptions.clinicIds.get().includes(clinicId)) {
      props.filterOptions.clinicIds.set(
        props.filterOptions.clinicIds.get().filter(id => id !== clinicId)
      )
    } else {
      props.filterOptions.clinicIds.set(
        [...props.filterOptions.clinicIds.get(), clinicId]
      )
    }
  }
  const getOpacity = (marker: ClinicMapMarker) => {
    if (props.filterOptions.clinicIds.get().includes(marker.data.clinicId) || props.filterOptions.clinicIds.length === 0) {
      return 1
    }
    return 0.6
  }
  return <svg viewBox="0 0 772 525" xmlns="http://www.w3.org/2000/svg" css={css`position: absolute; width: 100%; 
      top: 0; display: block;`}>
    <g className='layer'>
      {markers.map(marker => <Tooltip key={marker.data.clinicId} label={marker.data.clinicName} aria-label={marker.data.clinicName}>
        <ellipse cx={marker.data.coordinates.x} cy={marker.data.coordinates.y} rx={ellipseSize} ry={ellipseSize} fill={getRandColor(marker.positionCount)}
          onClick={() => onMarkerClick(marker)} css={css`cursor: pointer`} opacity={getOpacity(marker)}/>
      </Tooltip>)}
    {/*  <Tooltip label={'Þorlákshöfn'}><ellipse cx="223" cy="449.5" fill={getRandColor()} id="p_Þorlákshöfn" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Hveragerði'}><ellipse cx="251" cy="420" fill={getRandColor()} id="p_Hveragerði" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Selfoss'}><ellipse cx="274" cy="433" fill={getRandColor()} id="p_Selfoss" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Laugarás'}><ellipse cx="324" cy="396" fill={getRandColor()} id="p_Laugarás" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Hella'}><ellipse cx="320" cy="451" fill={getRandColor()} id="p_Hella" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Hvolsvöllur'}><ellipse cx="341" cy="468" fill={getRandColor()} id="p_Hvolsvöllur" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Vestmannaeyjar'}><ellipse cx="289" cy="510" fill={getRandColor()} id="p_Vestmannaeyjar" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Vík í Mýrdal'}><ellipse cx="424" cy="511" fill={getRandColor()} id="p_Vík í Mýrdal" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Kirkjubæjarklaustur'}><ellipse cx="453" cy="455" fill={getRandColor()} id="p_Kirkjubæjarklaustur" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Höfn í Hornafirði'}><ellipse cx="640.5" cy="383" fill={getRandColor()} id="p_Höfn í Hornafirði" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Blönduós'}><ellipse cx="293.5" cy="170" fill={getRandColor()} id="p_Blönduós" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Sauðárkrókur'}><ellipse cx="343.5" cy="143" fill={getRandColor()} id="p_Sauðárkrókur" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Siglufjörður'}><ellipse cx="394.5" cy="72" fill={getRandColor()} id="p_Siglufjörður" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Ólafsfjörður'}><ellipse cx="416.5" cy="87" fill={getRandColor()} id="p_Ólafsfjörður" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Dalvík'}><ellipse cx="428.5" cy="109" fill={getRandColor()} id="p_Dalvík" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Akureyri'}><ellipse cx="452.5" cy="159" fill={getRandColor()} id="p_Akureyri" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Húsavík'}><ellipse cx="499.5" cy="97" fill={getRandColor()} id="p_Húsavík" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Kópasker'}><ellipse cx="566.5" cy="56" fill={getRandColor()} id="p_Kópasker" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Raufarhöfn'}><ellipse cx="600.5" cy="20" fill={getRandColor()} id="p_Raufarhöfn" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    {/*  <Tooltip label={'Þórshöfn'}><ellipse cx="642.5" cy="70" fill={getRandColor()} id="p_Þórshöfn" rx={ellipseSize} ry={ellipseSize}/></Tooltip>*/}
    </g>
  </svg>
}

export default MapMarkers;