import {State, useHookstate} from "@hookstate/core";
import React, {useEffect} from "react";
import {css} from "@emotion/react";
import {Button, CloseButton} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import {HR} from "./HR";

export function CollectibleComponent<T>(props: { display: (value: State<T>, index: number) => React.ReactNode,
  elements: State<T[]>, construct: () => T, addMoreText?: string, lineSeparator?: boolean,
  disableAddMore?: (value: T[]) => boolean
}) {
  const addMoreText = props.addMoreText || 'Bæta við fleiri'
  const elements = useHookstate(props.elements)
  useEffect(() => {
    if (elements.length === 0) {
      elements.merge([props.construct()])
    }
  }, [elements])
  return <div css={css`display: flex; flex-direction: column; gap: 8px`}>
    {elements.map((option, index) => <React.Fragment key={index}>
      <div css={css`display: flex;
        gap: 4px;
        align-items: center`}
        key={`${index}-${option.get()}`}>
        {props.display(option, index)}
        {index !== 0 && <CloseButton onClick={() => {
          const filter = elements.get().filter((_, i) => i !== index).map(elem => {
            const isElementPrimitive = typeof elem === 'string' || typeof elem === 'number' || typeof elem === 'boolean'
            return isElementPrimitive ? elem : {...elem}
          });
          elements.set(filter);
        }}/>}
      </div>
      {props.lineSeparator && index !== elements.length - 1 && <HR/>}
    </React.Fragment>)}
    <div css={css`margin-top: 8px`}>
      <Button size={'sm'} onClick={() => elements.merge([props.construct()])} leftIcon={<AddIcon/>}
        isDisabled={props.disableAddMore?.(elements.get()) ?? false}>
        {addMoreText}
      </Button>
    </div>
  </div>
}